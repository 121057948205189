import logo from './logo.svg';
import './App.css';
import AuthorizationPage from './Authorization/Authorization.js';
import HomePage from './home page/home page.js';
import Header from './header/header.js';
import Map from './Map/MapWrapping.js';


import cleaningStorageOfTables from './Components/tableComponent/cleaningStorageOfTables.js';


import SortComponent from './Components/sortComponent.js';

//Dashboard
import WeHelp_DashboardPage from './Dashboard/WeHelp/WeHelp.js';

import UserSettingsPage from './userSettings/userSettings.js';

import { useSelector, useDispatch } from 'react-redux';
import { useInsertionEffect, useRef, useState } from 'react';

window.onerror = function() {
  const dispatch = useDispatch();
  dispatch({type: 'CHANGE_PAGE_NAME', pageName: 'homePage' });

  alert('Bir hata oluştu. Lütfen siyah arka plan penceresini kapatıp tekrar deneyin. Bu işe yaramazsa İnternet bağlantınızı kontrol etmeyi ve sayfanın tamamını yenilemeyi deneyin.');
};



function App() {
  sessionStorage.setItem('attention',  'this storage is reserved for links to other tabs');
  const dispatch = useDispatch();
  let mainRef = useRef(null);
  let currentAppPageRef = useRef(0);
  const [flagState, setflagState] = useState(false);
  let nameOfPage = useSelector((state) => state.changingPageName.pageName);

  console.log('app page');
  console.log(nameOfPage);
  
  useInsertionEffect(() => {
    console.log(sessionStorage.getItem('pageName'));
    console.log('Element inserted into the DOM');
    currentAppPageRef.current = nameOfPage;
    if (sessionStorage.getItem('pageName') != null) {
      mainRef.current = sessionStorage.getItem('pageName');
      console.log('app useInsertionEffect mainRef.current', mainRef.current);
      sessionStorage.removeItem('pageName');
      dispatch({type: 'GET_TOKEN', token: sessionStorage.getItem('token') });
      sessionStorage.removeItem('token');
      setflagState(true);
    }
  }, []); 

  if (mainRef.current != null) {
    nameOfPage = mainRef.current;
    //dispatch({type: 'CHANGE_PAGE_NAME', pageName: mainRef.current });
  }

  if (currentAppPageRef.current != nameOfPage) {
    cleaningStorageOfTables();
    console.log('app cleaningStorageOfTables');
  }
  
  let appPage;

switch (nameOfPage){
  case 'authorizationPage':
    appPage = <AuthorizationPage />;
  break;
  case 'homePage':
    appPage = <HomePage />;
  break;
  case 'userSettings':
    appPage = <UserSettingsPage />;
  break;
  case '4873':
    appPage = <WeHelp_DashboardPage />;
  break;
  case '96_harita':
    appPage = <Map id={nameOfPage} />;
  break;
  default:
    appPage = <SortComponent id={nameOfPage} />;
  break;
}

  return (
    <div className="App">
      <Header />
      {mainRef.current != null && <div style={{height: '5vh'}}></div> }
      {appPage}
    </div>
  );
}

export default App;
