class StoreRequest {
  static store = {
    
  };
}

export default StoreRequest;


/*


dinamikRapor:
- DinamikRaporTable
- preDinamikRapor
- DinamikRapor_AltForms
*/

