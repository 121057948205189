import * as d3 from "d3";
import { useState, useRef, useSyncExternalStore} from 'react';

function datesMap(arr, widthSvg, lengthAxisY) {
  let step = Math.round(arr.length/(widthSvg/21));
  if (step == 0) {
    step = 1;
  }
  let arr1 = [];
  let j = 0;
  for (let i = 0; i < arr.length; i += step) {
    arr1.push(
      <text className='subscriptionOfDate_WeHelpDiagramLine2'
      //x={j * 22  - 70}
      x={j * 22  - lengthAxisY/5}
      y={lengthAxisY + 35}
      >
        {arr[i]}
    </text>);
    j++;
  }

  return arr1;
}


function WeHelpDiagramSmoothLine({ data, dates, name, color}) {

const refLine = useRef(0);
const refmaxY = useRef(Math.max(...data));
const [mouseXCoord, setmouseXCoord] = useState(-1);

let maxY = refmaxY.current;

const screenWidth = useSyncExternalStore(function(onChange){
  window.addEventListener("resize", onChange);

  return () => window.removeEventListener("resize", onChange);
}, 
function () {
  refLine.current = 0;
  return window.innerWidth;
});

const widthSvg = screenWidth/100 * 90;
const lengthAxisY = Math.round(widthSvg/5); //указывает высоту поля графика
//const refDates = useRef(datesMap(dates, widthSvg, lengthAxisY));



const getY = d3.scaleLinear()
  //.domain([0, Math.max(...coords) + 3])
  .domain([0, maxY + 9])
  .range([lengthAxisY,0]);


const getX = d3.scaleLinear()
  .domain([0, data.length - 1])
  .range([0, widthSvg]);

const getYAxis = ref => {
  const yAxis = d3.axisLeft(getY)
    .tickSize(-widthSvg) // ширина горизонтальных линий на графике
    .tickPadding(7);
  d3.select(ref).call(yAxis);
};

const curveFunc = d3.line()
.x(function(d) { return getX(d.x) })
.y(function(d) { return getY(d.y) })
//.curve(d3.curveStep);
.curve(d3.curveMonotoneX);


function x_params (objX) {
  let objX1 = [];
  for (let i = 0; i < objX.length; i++) {
    objX1.push({y: objX[i], x: i});
  }
  return objX1;
}

if (typeof refLine.current == "number") {
  if (refLine.current < 3) {
    refLine.current++;
  } else {
    refLine.current = <path className={`line0`} style={{fill: 'none'}} strokeWidth={3} stroke={color} d={curveFunc (x_params (data))}></path>;
  }
}


let circleX; 
if (mouseXCoord > -1) {
  //let variableCircleX = data[Math.round(getX.invert(mouseXCoord))];
  let variableCircleX = Math.round(getX.invert(mouseXCoord));
  circleX = <><circle
    className={`points0`}
    cx={getX(variableCircleX)}
    //cx={mouseXCoord}
    //cy={getY(data[Math.round(getX.invert(mouseXCoord))])}
    cy={getY(data[variableCircleX])}
    r={7}
    fill={color}
    stroke="white"
    strokeWidth={2}
  />
  <text className='subscriptionOfPoint_WeHelpDiagramLine2'
    x={getX(variableCircleX) - 1}
    y={getY(data[variableCircleX]) - 15}>
      {data[variableCircleX]}
  </text>
  <text className='subscriptionOfPoint_WeHelpDiagramLine2'
    x={widthSvg/2}
    y={ - 15}>
      {dates[variableCircleX]}
  </text>
  </>
 //console.log(getX(mouseXCoord));
}

console.log('WeHelpDiagramSmoothLine data', dates);
/*
const svg = <svg width={widthSvg} height={lengthAxisY} style={{borderRight: '1px solid #b3b3b3'}} className="WeHelpDiagramLine2"
   onMouseMove={function(event) {
    //setmouseXCoord(d3.pointer(event)[0]);
   }}
   onMouseOut={function() {
    setmouseXCoord(-1);
   }}>
<g className='YAxis' ref={getYAxis} strokeWidth='0.3'/>
<text className='subscriptionOfGrafik_WeHelpDiagramLine2'
  x={0}
  y={-15}>
    {name}
</text>
<path className={`line0`} style={{fill: 'none'}} strokeWidth={3} stroke={color} d={curveFunc (x_params (data))}></path>
{circleX}
{datesMap(dates, widthSvg, lengthAxisY)}
</svg>;
*/

const svg = <svg width={widthSvg} height={lengthAxisY} style={{borderRight: '1px solid #b3b3b3'}} className="WeHelpDiagramLine2"
  onMouseMove={function(event) {
  setmouseXCoord(d3.pointer(event)[0]);
  }}
  onMouseOut={function() {
  setmouseXCoord(-1);
  }}>
  <text className='subscriptionOfGrafik_WeHelpDiagramLine2'
    x={0}
    y={-15}>
      {name}
  </text>
  <g className='YAxis' ref={getYAxis} strokeWidth='0.3'/>
  <path className={`line0`} style={{fill: 'none'}} strokeWidth={3} stroke={color} d={curveFunc (x_params (data))}></path>
  {circleX}
  {dates.map(function(item, index) {

    return <text className='subscriptionOfDate_WeHelpDiagramLine2'
    //x={getX(index) - 50}
    x={widthSvg/dates.length * index}
    y={ lengthAxisY + 15}>
      {item}
    </text>;

  })}
</svg>;
return <>
  {svg}
</>;
}

export default WeHelpDiagramSmoothLine;


/*
{typeof refLine.current == "number"? <path className={`line0`} style={{fill: 'none'}} strokeWidth={3} stroke={color} d={curveFunc (x_params (data))}></path> : refLine.current}

Проблема оказалась в бесконечном цикле в функции datesMap из за того, что при малой длине массива
в переменную step записывался 0, и цикл становился бесконечным. 
*/

/*
Теперь нужно:
1. сделать так, чтобы точка не повторяла движения мыши, а становилась на линии в местах 
значений. 
Как определить эти места?

2. нужно установить подписи под значениями таблицы. 
*/
