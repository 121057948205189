
import './home page.css';
import './homePageAdaptive.css';
import { useDispatch, useSelector } from 'react-redux';

function HomePage() {
const dispatch = useDispatch();
const token = useSelector((state) => state.tokenOfSession.token);

if (token == null) {
  dispatch({type: 'CHANGE_PAGE_NAME', pageName: 'authorizationPage' });
}


const homepage = <div className='homepage'>
  <div className='home_page_table1'>
    <div className='home_page_table1_header'>Card title</div>
    <div className='home_page_table1_text'>
      Some quick example text to build on the card title and make up the bulk 
      of the card's content.
    </div>
    <div className='home_page_table1_links'>
      <a href='#'>Card link</a>
      <a href='#'>Another link</a>
    </div>
  </div>
  <div className='home_page_table2'>
    <div className='home_page_table2_header'>Card title</div>
      <div className='home_page_table2_text'>
        Some quick example text to build on the card title and make up the bulk 
        of the card's content.
      </div>
      <div className='home_page_table2_links'>
        <a href='#'>Card link</a>
        <a href='#'>Another link</a>
      </div>
  </div>

  <div className='home_page_table3'>
    <div className='home_page_table3_header'>Featured</div>
    <div className='home_page_table3_text'>Special title treatment</div>
    <div>With supporting text below as a natural lead-in to additional content.</div>
    <button>Go somewhere</button>
  </div>
  <div className='home_page_table4'>
    <div className='home_page_table4_header'>Featured</div>
    <div className='home_page_table4_text'>Special title treatment</div>
    <div className='home_page_table4_text'>With supporting text below as a natural lead-in to additional content.</div>
    <button>Go somewhere</button>
  </div>
</div>
  
  return (
    homepage

  );
}

export default HomePage;
