import * as d3 from "d3";
import { useState } from 'react';
import WeHelpDiagramLine2 from './WeHelpDiagramLine2';

const color_1 = '#34C6CD';
const color_2 = '#FF3500';
const color_3 = '#B1F100';
const color_4 = '#FFF500';
const color_5 = '#7908AA';
const color_6 = '#FF7340';
const color_7 = '#FFAB00';
const color_8 = '#009E66';
const color_9 = '#AA66CC';

const colorArr = [color_1, color_2, color_3, color_5, color_6, color_7, color_8, color_9, color_4];


function SortDiagramLine({signature, data}) {
console.time('Время выполнения SortDiagramLine');
const [mouseXCoord, setmouseXCoord] = useState(-1);
const [fixFlag, setfixFlag] = useState(false);

let diagramLines = [];

for (let i = 0; i < data.length; i++) {
  diagramLines.push(
  <div className='diagramWrapping_for_multipleLine' style={{height: 'auto', paddingBottom: '70px'}}>
    <WeHelpDiagramLine2 mouseXCoord={mouseXCoord} setmouseXCoord={setmouseXCoord} 
    fixFlag={fixFlag} setfixFlag={setfixFlag} 
    data={data[i]["data"]} dates={signature} name={data[i]["name"]} color={colorArr[i]} />
  </div>
  );
}

console.timeEnd('Время выполнения SortDiagramLine');
console.log('SortDiagramLine diagramLine', diagramLines);
return <>
  {diagramLines}
</>;
}

export default SortDiagramLine;
