import { useRef, useState, useEffect} from 'react';
import './Form.css';

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeTr from 'air-datepicker/locale/tr';

//import StoreCalendar from './classStoreCalendar';

function selectionDate (propTimepicker, dataOfTemplate) {
  let selectedDateTimeVariable;
  if (!propTimepicker) {
  //selectedDateTime = FormTableRepository.formInputsState[name].split('.').reverse().join('-');
    let v = dataOfTemplate;
    selectedDateTimeVariable = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1];
  } else {
    let v = dataOfTemplate;
    console.log('calendar selectionDate v', v);
    selectedDateTimeVariable = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1] + ' ' + v[11] + v[12] + v[13] + v[14] + v[15];
  }
  return selectedDateTimeVariable;
}

function checkAndChangeDate (event, refDate, index, type, setflag, dataOfTemplate, setdataOfTemplate) {
  if(event.target.value.length == 16 && type == "TARIH_SAAT") {
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.' && event.target.value[10] == ' ' && event.target.value[13] == ':';
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let hourValue = String(event.target.value[11]) + String(event.target.value[12]);
    let minuteValue = String(event.target.value[14]) + String(event.target.value[15]);
    let checkTheNaN = dataValue + monthValue + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9] + hourValue + minuteValue;
    checkTheNaN = Number.isNaN(Number(checkTheNaN));

    if(checkTheNaN || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 ||
    Number(hourValue) > 23 || Number(hourValue) < 0 ||
    Number(minuteValue) > 59 || Number(minuteValue) < 0 || !chekingPointsFlag) {
      if (dataOfTemplate[index + 1] == '') {
        event.target.value = '';
        setflag(false);
        return;
      } else {
        event.target.value = '';
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
        setflag(false);
        return;
      }

    }
  } else if (event.target.value.length == 10 && type == "TARIH") {
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.';
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let checkTheNaN = dataValue + monthValue + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9];
    console.log('calendar input onBlur checkTheNaN', checkTheNaN);

    checkTheNaN = Number.isNaN(Number(checkTheNaN));
    console.log('calendar input onBlur checkTheNaN', checkTheNaN);
    if(checkTheNaN || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 ||
    !chekingPointsFlag) {
      if (dataOfTemplate[index + 1] == '') {
        event.target.value = '';
        setflag(false);
        return;
      } else {
        event.target.value = '';
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
        setflag(false);
        return;
      }
    }
  } else {
    console.log('calendar input event', event.target.value);

    event.target.value = '';
    setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
    setflag(false);
    return;
    
  }
  console.log('calendar input event', event.target.value);
  let variableSelectionDate = selectionDate (type != 'TARIH' ? true : false, event.target.value);

  if (refDate.current.viewDate.getFullYear() != (event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9])) {
    refDate.current.setViewDate(variableSelectionDate);
  }
  refDate.current.selectDate(variableSelectionDate, {updateTime: true, silent: true});
  setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), event.target.value, ...dataOfTemplate.slice(index + 2)]);
  setflag(true);

}

function Calendar({name, setdataOfTemplate, index, type, rangeData, dataOfTemplate}) {

const [flag, setflag] = useState(false);
const inputDate = useRef();
const refDate = useRef();

console.log('calendar dataOfTemplate', dataOfTemplate);

let buttonClose = {
  content: 'Kapalı',
  onClick: function (dp) {
    dp.hide();
  }
}

useEffect(() => {
  refDate.current = new AirDatepicker(inputDate.current, {
    locale: localeTr,
    timeFormat: 'HH:mm',
    keyboardNav: false,
    range: rangeData,
    multipleDatesSeparator: ' - ',
    timepicker: type != 'TARIH' && true,
    firstDay: 0,
    showEvent: "focus",
    buttons: ['clear', buttonClose],
    isMobile: false,
    onSelect: function ({date, formattedDate, datepicker}) {
      if (formattedDate != undefined) {
        //setdataOfTemplate([...(StoreCalendar.state).slice(0, index + 1), formattedDate, ...StoreCalendar.state.slice(index + 2)]);
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), formattedDate, ...dataOfTemplate.slice(index + 2)]);
      } else {
        //setdataOfTemplate([...(StoreCalendar.state).slice(0, index + 1), '', ...StoreCalendar.state.slice(index + 2)]);
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
      }
      if (formattedDate?.length != 0 && formattedDate?.length != undefined) {
        setflag(true);
      } else {
        setflag(false);
      }
      
    },
  });
}, []);



let inputDateTag =  <input ref={inputDate} placeholder={`${name}`} className={`${flag == false ? 'inactiveinput' : 'activeinput'}`} 

  onChange={function(event) {
    //console.log('calendar input event', event.target.value);
    //inputValueRef.current = event.nativeEvent.data;
    //inputValueRef.current = event.target.value;
    //setflag(!flag);
    console.log('calendar input event.nativeEvent.data', event.nativeEvent.data);
    if (event.nativeEvent.data != null) {
      if (type == "TARIH_SAAT") {
        switch (event.target.value.length) {
          case 2: 
            event.target.value+= '.';
          break;
          case 5: 
            event.target.value+= '.';
          break;
          case 10: 
            event.target.value+= ' ';
          break;
          case 13: 
            event.target.value+= ':';
          break;
        }
      } else {
        switch (event.target.value.length) {
          case 2: 
            event.target.value+= '.';
          break;
          case 5: 
            event.target.value+= '.';
          break;
        }
      }
    }

  }}  

  onBlur={function(event) {
    if (refDate.current != undefined && event.target.value != dataOfTemplate[index + 1]) {
      checkAndChangeDate (event, refDate, index, type, setflag, dataOfTemplate, setdataOfTemplate);
    }
  }}

  onKeyDown={function(event) {
    console.log('calendar input event', event.target.value, dataOfTemplate[index + 1]);
      //if (refDate.current != undefined && !(event.target.value === '' && StoreCalendar.state[index + 1] === '')) {
      if (refDate.current != undefined && event.target.value != dataOfTemplate[index + 1] && event.keyCode == 13) {
      
        checkAndChangeDate (event, refDate, index, type, setflag, dataOfTemplate, setdataOfTemplate);
      }

  }}

  maxLength={type == "TARIH_SAAT" ? 16 : 10}
  />;

  return (
    inputDateTag

  );
}

export default Calendar;

/*
Как определить когда данные из input удаляются, а когда добавляются? 
Нужно стравнить длину значения в input до и после события. 
*/

/*
Нужно унифицировать календарь, сделать так, чтобы его можно было подставить в любой компонент. 

Нужно переписать код так, чтобы не было необходимости использования дополнительных элементов. 
В календаре хранилище используется по той причине, что стейт, который передается через пропсы не 
изменяется. Поэтому используется хранилище, которое изменяется вместе с изменением стейта. 
Но почему? Разве при обновлении родительского стейта в дочерний элемент не должен ложиться обновленный 
стейт? 
Нужно проверить. 
Теперь нет необходимости использовать хранилище для календаря, можно использовать просто стейт. 


*/
