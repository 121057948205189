import './WeHelp.css';
import * as d3 from "d3";
import { useState } from 'react';


function WeHelpDiagram({data, signature, nameColumnGroup, maxY}) {
console.log("WeHelpDiagramMultiply data", data, signature, nameColumnGroup, maxY);
console.log("WeHelpDiagramMultiply nameColumnGroup", nameColumnGroup);

const lengthAxisY = 500; //указывает высоту поля графика
const widthSvg = 1600;
const width_G_Svg = widthSvg / data.length;
const rect_G_Width = 95 / data.length;
const columnGap_G = 55 / data.length;
const signatureGap = widthSvg / signature.length;
const nameColumnGroupGap = widthSvg / nameColumnGroup.length;

const [heightY, setheightY] = useState(0);
if (heightY == 0) {
  setheightY(maxY + 9);
}

const getYRect = d3.scaleLinear()
  //.domain([0, 22])
  .domain([0, heightY]) //maxY указывает максимальное значение для оси Y
  .range([lengthAxisY,0]);

const getYAxis = ref => {
  const yAxis = d3.axisLeft(getYRect)
    .tickSize(-widthSvg) // ширина горизонтальных линий на графике
  d3.select(ref).call(yAxis);
};

const buttonZoomPlus = <div className='buttonZoomPlus' onClick={function () {
  if (heightY > Math.floor(maxY / 7)) {
    setheightY(heightY - Math.floor(maxY / 7))
  }
}}>+</div>;
const buttonZoomMinus = <div className='buttonZoomMinus' onClick={function () {
  if (heightY < maxY) {
    setheightY(heightY + Math.floor(maxY / 7))
  }
}}>&mdash;</div>;

 
const svg = <svg width={widthSvg} height={lengthAxisY + 10} style={{borderRight: '1px solid blue'}}>
  <g className='YAxis' ref={getYAxis} stroke-width='0.3'/>
  {signature.map( function(item, index) {
      return <circle className={`circleRect${index}`}
      key={index}
      cx={`${(index * signatureGap) + (signatureGap / 2)}`}
      cy={lengthAxisY + 52}
      r={10}
      fill= {`${item.color}`}
    />
    })}

  { nameColumnGroup.map( function(item, index) {// здесь названия колонок разбиваются на части, т.к. мало места в таблице
    //console.log(item, item.length);
    //if (item.length < 18) {
      if (!/[\/-\s]/.test(item.trim())) {
      //console.log('test' ,item, /[\/-\s]/.test(item.trim()));
      return <text height={17}
      className='columnGroupText'
      fill="black"
      x={`${(index * nameColumnGroupGap + 5) + (nameColumnGroupGap / 2) - 5}`}
      y={lengthAxisY + 27}
      textAnchor="middle">
      {item}
      </text>
    } else {
      console.log('item', item.replace(/(.)(?=[\/-])/g, '$1 '));
      let arr = item.replace(/(.)(?=[\/-])/g, '$1 ').split(' ');
      return <> <text height={15} width={35}
      className='columnGroupText'
      fill="black"
      x={`${(index * nameColumnGroupGap + 5) + (nameColumnGroupGap / 2) - 5}`}
      y={lengthAxisY + 25}
      textAnchor="middle">
      {arr[0] + ' ' + arr[1]}
      </text>
      <text height={15} width={35}
      className='columnGroupText'
      fill="black"
      x={`${(index * nameColumnGroupGap + 5) + (nameColumnGroupGap / 2) - 5}`}
      y={lengthAxisY + 37}
      textAnchor="middle">
      {arr.map( function(item1, index1) {
        if (index1 >= 2) {
          return <>{item1 + ' '}</>
        }
      })}
      </text>
      </> 
    }})}

  {data.map(function(item, index0) {
      return <>
    {
      item.map( function (item, index) {
        return <> 
        <rect className={`rect${index}`} fill= {`${item.color}`} 
        x={`${20 + (index * rect_G_Width) + (index * columnGap_G) + (index0 * width_G_Svg)}`} y={getYRect(item.value)} 
        width={rect_G_Width} height={lengthAxisY - getYRect(item.value)} 
        onClick={event => console.log(event.target.className)}></rect>
        
        <text
        fill="black"
        x={`${20 + (index * rect_G_Width) + (index * columnGap_G) + (index0 * width_G_Svg) + (rect_G_Width / 2)}`}
        y={getYRect(item.value) - 8 }
        textAnchor="middle">
        {item.value}
        </text>

        </>
    
      })}
      </>
    })
  }

  {signature.map( function(item, index) {
    return <text
      className='columnText'
      fill="black"
      x={`${(index * signatureGap) + (signatureGap / 2)}`}
      y={lengthAxisY + 79}
      textAnchor="middle">
      {item.nameColumn}
      </text>
    })
  }
  
</svg>;

return <>
  {svg}
  {buttonZoomPlus}
  {buttonZoomMinus}
</>;
}

export default WeHelpDiagram;

/*
в nameColumnGroup не добавлять больше 3 слов,
между словами не использовать символов кроме пробела, / и -

*/