import { useEffect, useState, useInsertionEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import StoreRequest from '../formComponent/classStoreRequestForm';
import StoreRequestTypeComponent from '../Components/classRequestLimiter';
import StoreSearchEngine from './classStoreSearchEngine';

import adress from '../adress.js'

let path =   <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>

function headerDropDownList({setarrOfTopMenu}) {

const token = useSelector((state) => state.tokenOfSession.token);
const dispatch = useDispatch();
let flagOfMagnifyingGlass = useRef(false); 
const [rerenderState, setrerenderState] = useState([0]);

let searchInput = useRef(''); 
let templateForPopUp = searchInput.current;
//const regexpForPopUp = new RegExp(`${templateForPopUp.replace(/\|/g, '\\|')}`, 'i');
const regexpForPopUp = new RegExp(`${templateForPopUp.replace(/[-\/\\^$.*+?()[\]{}|]/g, '\\$&')}`, 'i');
console.log('headerDropDownList regexpForPopUp', regexpForPopUp);

console.log('headerDropDownList flagOfCarRequest', StoreSearchEngine.flagOfCarRequest);
/*
useEffect(() => {
  if (StoreSearchEngine.flagOfCarRequest == false) {
    fetch(`https://creaapi.com/${adress[0]}/CrealogTakipOrtamDenetimi.svc/GetGuncelTakip/?sessionId=${token}&araclarId=0`)
    .then(response => response.json())
    .then(function (json) {
      console.log('headerDropDownList fetch', json);
      for (let elem of json.GetGuncelTakipResult.GUNCEL_TAKIP) {
        console.log('headerDropDownList elem.PLAKA', elem.PLAKA);
        StoreSearchEngine.menuList.push({name: elem.PLAKA, sort: 'car'});
      }
      console.log('headerDropDownList elem.PLAKA breake');
  
      StoreSearchEngine.flagOfCarRequest = true;
      setrerenderState([0]);
    });
  }
}, []);
*/
let presenceOFMap = useRef(0); 

if (StoreSearchEngine.flagOfCarRequest == false && StoreSearchEngine.menuList.length != 0 && StoreSearchEngine.wait_flagOfCarRequest == false) {
  console.log('headerDropDownList elem.name == Haritalar', StoreSearchEngine.menuList);

  for (let elem of StoreSearchEngine.menuList) {
    console.log('headerDropDownList elem.name == Haritalar', elem.name == 'Haritalar');

    if (elem.name == 'Haritalar' ) {
      presenceOFMap.current = elem.ID;
      break;
    }
  }
  /*
  В этот момент в StoreSearchEngine.menuList данные еще не успели записаться, 
  поэтому хранилище это пустой массив. 
  */

  if (presenceOFMap.current != 0) {
    StoreSearchEngine.wait_flagOfCarRequest = true;
    fetch(`https://creaapi.com/${adress[0]}/CrealogTakipOrtamDenetimi.svc/GetGuncelTakip/?sessionId=${token}&araclarId=0`)
    .then(response => response.json())
    .then(function (json) {
      console.log('headerDropDownList fetch', json);
      console.log('headerDropDownList menuList', StoreSearchEngine.menuList);
  
      //for (let elem of json.GetGuncelTakipResult.GUNCEL_TAKIP) {
      for (let i = 0; i < json.GetGuncelTakipResult.GUNCEL_TAKIP.length; i++) {
        console.log('headerDropDownList elem.PLAKA', json.GetGuncelTakipResult.GUNCEL_TAKIP[i].PLAKA);
        StoreSearchEngine.menuList.push({name: json.GetGuncelTakipResult.GUNCEL_TAKIP[i].PLAKA, sort: 'car', index: i});
      }
      console.log('headerDropDownList elem.PLAKA breake');
  
      StoreSearchEngine.flagOfCarRequest = true;
      StoreSearchEngine.wait_flagOfCarRequest = false;
      setrerenderState([0]);
    }).catch(
      error => {
        StoreSearchEngine.wait_flagOfCarRequest = false;
      }
    );
  } else {
    StoreSearchEngine.flagOfCarRequest = true;
  }

  
  

}

console.log('headerDropDownList presenceOFMap', presenceOFMap.current);
console.log('headerDropDownList StoreSearchEngine.menuList', StoreSearchEngine.menuList);

let popUpWindow = <div className='popUpWindow_headerDropDownList'>
  {StoreSearchEngine.menuList.map(function (item) {
    if (regexpForPopUp.test(item.name)) {
      if (item.sort != 'car') {
        return <div onClick={function () {
          StoreRequestTypeComponent.pageType = item.sort;
          searchInput.current = '';

          dispatch({type: 'CHANGE_PAGE_NAME', pageName: `${item.ID}` });
          setarrOfTopMenu([String(item.ID), item.name, item.sort]);
        }}> {item.name} </div>;
      } else {
        return <div onClick={function () {
          StoreSearchEngine.flagOfMap = item.name;
          //StoreSearchEngine.indexOfCar = item.index;
          searchInput.current = '';

          dispatch({type: 'CHANGE_PAGE_NAME', pageName: `${presenceOFMap.current}` });
          setarrOfTopMenu([String(presenceOFMap.current), "Haritalar", "FORM_STATIK"]);
        }}> {item.name} </div>;
      }

    }
  })}
</div>

let searchInputWrapping = <div className='headerSearchInputWrapping'>
  <svg className="headerMagnifyingGlass" viewBox="0 0 32 32" onClick={function () {
    flagOfMagnifyingGlass.current = !flagOfMagnifyingGlass.current;
    searchInput.current = '';

    setrerenderState([0]);
  }}> {path} </svg>
  <div className='inputWrapping_headerSearchInputWrapping' 
  style={{width: flagOfMagnifyingGlass.current ? '200px' : '0px'}}>
    <input value={searchInput.current} 
    onChange={function (event) {
      searchInput.current = event.target.value;
      console.log('headerDropDownList searchInput.current', searchInput.current);
      setrerenderState([0]);
    }} />
  </div>
  {searchInput.current != '' && popUpWindow}
</div>

return <>
  {searchInputWrapping}
  
</>;
}

export default headerDropDownList;


//string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

//<path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>

//$ ^ . * + ? \ / {} [] () |
