import { useMemo, useState, useRef } from 'react';



function RerenderingTableFunction({id}) {
  let previousIndexRange = useRef([0, 0]);
  let windowWithLineNumbersIndicators = useRef(0);
  console.log('RerenderingTableFunction thisTableId', id);

  if (windowWithLineNumbersIndicators.current == 0 || windowWithLineNumbersIndicators.current === undefined) {
    let thisTable = document.getElementsByClassName(`${id} MuiBox-root`);
    console.log('RerenderingTableFunction thisTableId', id);
    console.log('RerenderingTableFunction thisTable', thisTable);
    windowWithLineNumbersIndicators.current = thisTable?.[0]?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`);
  }
  console.log('RerenderingTableFunction windowWithLineNumbersIndicators.current?.[0]?.textContent', windowWithLineNumbersIndicators.current?.[0]?.textContent);

    return;
}
 
export default RerenderingTableFunction;


