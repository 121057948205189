class TableStack {
  static lastLines = {};
  static childListArr = [];
  static currentId = 0;

  static lastLocalUpdate = 0;
  static lastCompletelyUpdate = 0;
}

export default TableStack;

/*
currentId нужен для отлова изменения страницы, значимый код в компоненте preTableWrapping 16 - 21.
Если приравнять к нулю, то при обновлении будет несколько изменений, самой значимое - это очистка
childListArr, то есть вся ветвь таблиц удалится, кроме главной таблицы.


*/

/*

нужно сделать так, чтобы при обновлении таблица открывалась на той же странице, что и раньше. 
Как это сделать? 
1. нужно сделать хранилище для номера последней страницы и количества строк на странице. 
2. нужно в самой таблице находить эти данные. 
3. нужно как то синхронизировать попадение этих данных в хранилище исключительно при обновлении 
таблицы. 
Как определить какая таблица должна обновляться? 
При общем обновлении данные можно брать только из родительской таблицы, 
а вот при обновлении конкретной таблицы? могут ли данные примениться не к той таблице?

при обновлении таблицы записываем данные в хранилище, потом данные берутся из хранилища и ставятся 
в таблицу, после чего удаляются. 
Это будет работать с одиночным обновлением, но не с обновлением всей таблицы. 
Для обновления всей таблицы нужно брать номер данные именно с родительской таблицы. 

Как получить доступ к данным из компонента buttons? 
Данные можно записывать в хранилище, а потом в компоненте buttons они перезаписыватся 
в другую переменную данного хранилища. 
Однако в это хранилище будут смотреть все таблицы, как их тогда разделить и сказать 
buttons компоненту из переменной для какой таблицы нужно брать данные? 

Нет не просто хороших решений, а вообще идей как это сделать. 
При реализации решения с хранилищем не удастся решить вопрос о том, какую таблицу выбрать для 
перезаписи. Если искать по id, то в компоненты buttons может попадать id для группы таблиц, а не 
для одной. 
Если бы при нажатии на кнопку обновить можно было взять и записать данные из таблицы ниже...

Итак, с помощью метода findDOMNode удалось найти последнюю страницу таблицы! 

Как теперь записать эти данные, чтобы нужная таблица при монтировании их 
получала? 

Можно сделать два разных свойства, одно для локального обновления, которое будет применяться 
к любой таблице, которая обновляется, а также второе свойство, которое будет использовать только 
главная таблица ао время обновления всех компонентов. 

Хорошо, локальное обновление юудет применяться к перемонтируемой таблице. Однако здесь есть 
таблицы, которые содержат в себе другие таблицы. Что делать с ними? Они скорее всего 
при следующем монтировании будут возвращаться к первой таблице. 
По счастью, порядок таблиц не меняется.

*/


