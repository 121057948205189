
const defaultValue = {token: 0}
const tokenOfSession = function (state=defaultValue, action) {
  switch (action.type){
    case 'GET_TOKEN':
      return {token: action.token};
    default:
      return state;
  }
}

export default tokenOfSession;
