import './WeHelpForForm.css';
import * as d3 from "d3";
import { useState, useSyncExternalStore } from 'react';

/*
const data=[
  {displayElement: "Google Chrome", rate: 42.52},
  {displayElement: "Firefox", rate: 16.23},
  {displayElement: "Opera", rate: 12.6},
  {displayElement: "Yandex Browser", rate: 9.12},
  {displayElement: "Internet Explorer", rate: 10.56},
  {displayElement: "Другие", rate: 8.97}
];
*/

const color_1 = '#34C6CD';
const color_2 = '#FF3500';
const color_3 = '#B1F100';
const color_4 = '#FFF500';
const color_5 = '#7908AA';
const color_6 = '#FF7340';
const color_7 = '#FFAB00';
const color_8 = '#009E66';
const color_9 = '#AA66CC';

const colorArr = [color_1, color_2,  color_8, color_9, color_5, color_6, color_7,  color_3, color_4];


function WeHelpDiagramPie({data, maxY, onePercent}) {



const screenWidth = useSyncExternalStore(function(onChange){
  window.addEventListener("resize", onChange);

  return () => window.removeEventListener("resize", onChange);
}, 
function () {
  return window.innerWidth;
});

const [elementIndexNumber_to_changeItsStyle, setelementIndexNumber_to_changeItsStyle] = useState(-1);

const widthSvg = screenWidth/100 * 80;
//const maxY = 20;
//const widthSvg = 1200;
const lengthAxisY = Math.round(widthSvg/3); //указывает высоту поля графика
const marginLeft_signature = widthSvg/(data.length + 1);




// задаем радиус
let radius = Math.round(widthSvg/7);

let arc = d3.arc()
  .outerRadius(radius)
  .innerRadius(0);

/*
let pie = d3.layout.pie()
  .sort(null)
  .value(function(d) { return d.rate; });
*/

let pie = d3.pie()
  .sort(null)
  .value(function(d) { return d.rate; });



let arcs = pie(data);
console.log('WeHelpDiagramPie arcs', arcs);


const svg = <svg width={widthSvg} height={lengthAxisY}>
  {data.map(function (item, index) {
    let opacityVariable = 1;
    if (elementIndexNumber_to_changeItsStyle != -1) {
      if (elementIndexNumber_to_changeItsStyle != index) {
        opacityVariable = 0.1;
      }
    }
    return <> 
    <circle className="circleRect WeHelpDiagramPie"
      key={index}
      cx={`${marginLeft_signature * (index + 1) }`}
      cy={lengthAxisY + 20}
      r={10}//1/160 widthSvg
      fill= {`${colorArr[index]}`}
      style={{opacity: opacityVariable}}
      onMouseOver={() => setelementIndexNumber_to_changeItsStyle(index)}
      onMouseOut={() => setelementIndexNumber_to_changeItsStyle(-1)}
    />
    <text
      className='columnText WeHelpDiagramPie'
      fill="black"
      style={{opacity: opacityVariable}}

      //x={`${(index * signatureGap) + (signatureGap / 2)}`}
      x={`${marginLeft_signature * (index + 1) }`}
      y={lengthAxisY + 53}
      textAnchor="middle">
      {item.displayElement}
    </text>
    <text
      className='columnText WeHelpDiagramPie'
      fill="black"
      style={{opacity: opacityVariable}}

      //x={`${(index * signatureGap) + (signatureGap / 2)}`}
      x={`${marginLeft_signature * (index + 1) }`}
      y={lengthAxisY + 70}
      textAnchor="middle">
      {(item.rate / onePercent).toFixed(2)}%
    </text>
    </>
  })}
  <g className='WeHelpDiagramPie'
  style={{transform: `translateX(${widthSvg/2}px) translateY(${lengthAxisY/2}px)`}} 
  onMouseOut={() => setelementIndexNumber_to_changeItsStyle(-1)} >
  {arcs.map(function (item, index) {
    let opacityVariable = 1;
    if (elementIndexNumber_to_changeItsStyle != -1) {
      if (elementIndexNumber_to_changeItsStyle != index) {
        opacityVariable = 0.1;
      }
    }
    return <>
      <path d={arc(item)} fill={colorArr[index]} style={{opacity: opacityVariable}} onMouseOver={() => setelementIndexNumber_to_changeItsStyle(index)}></path>
    </>
  })}
    {arcs.map(function (item, index) {
    let opacityVariable = 1;
    if (elementIndexNumber_to_changeItsStyle != -1) {
      if (elementIndexNumber_to_changeItsStyle != index) {
        opacityVariable = 0.1;
      }
    }
    let textCoordinates = arc.centroid(item);
    return <>
      <text style={{transform: `translateX(${textCoordinates[0] - 10}px) translateY(${textCoordinates[1]}px)`, opacity: opacityVariable}}> {item.data.displayElement} </text>
    </>
  })}
  </g>
  
</svg>;

return <>
  {svg}

</>;
}

export default WeHelpDiagramPie;

/*
В d3.js есть следующие элементы:

arc (функция)
radialArea(функция)




d3.arc(): Создает новый генератор дуги.
d3.pie(): Создает новый генератор пирога (позволяет вычислять угловые значения для секторов).
d3.arc().innerRadius(): Устанавливает внутренний радиус дуги (если равен 0, создается круговая диаграмма, если больше 0, создается кольцевая диаграмма).
d3.arc().outerRadius(): Устанавливает внешний радиус дуги.
d3.arc().startAngle(): Устанавливает начальный угол дуги в радианах.
d3.arc().endAngle(): Устанавливает конечный угол дуги в радианах.
d3.arc().padAngle(): Устанавливает угол заполнения между секторами.
d3.pie().sort(): Устанавливает функцию сортировки для секторов.
d3.select(): Выбирает первый элемент, соответствующий указанному селектору.
d3.selectAll(): Выбирает все элементы, соответствующие указанному селектору.

*/

/*
Как сделать сегмент круговой диаграммы:
1. создаем тег path
2. в атриибут d вставляем результат функции arc(), а в параметры кладем 
нужный элемент массива, который получится в результате функции pie

В общем: 
1. функция pie преобразует данные в нужный вид
2. функция arc преобразует нужный элемент из массива уже преобразованных данных в путь для 
элементов svg
3. тег path отрисует сегмент диаграммы по данному пути. 

Чтобы отрисовался весь круг, нужно все сегменты нарисовать внутри тега g,
тогда они все автоматически станут на место
*/

/*
удалось с помощью state заставить элементы становиться прозрачными, 
однако как это теперь отменить?
можно отмену повесить на событие onMouseOut,
однако если курсор будет переходить между двумя сегментами, то будет лишняя перерисовка.
Нужно как то сделать, чтобы при убирании курсора от любого элемента во вне графика, 
выполнялась функция отмены выделения, а при переходе мыши между секторами - нет.
Может повесить функцию на тег g?


Сейчас осталось 2 задачи: 
1. Нужно сделать текст контрастным либо с помощью обводки, либо с помощью проверки на темноту цвета.
Обводка не сильно помогает. 

2. нужно еще окошко с процентами
*/
