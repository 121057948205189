import './WeHelp.css';
import * as d3 from "d3";
import { useState } from 'react';

function WeHelpDiagram({data, maxY}) {
console.log('WeHelpDiagram data', data, maxY)
const lengthAxisY = 500;
const widthSvg = 1600;
const rectWidth = 95;
const columnGap = 55;
const marginLeft = (widthSvg - ((rectWidth + columnGap) * data.length)) / 2;

const [heightY, setheightY] = useState(0);
if (heightY == 0) {
  setheightY(maxY + 9);
}

const getYRect = d3.scaleLinear()
  .domain([0, heightY])
  .range([lengthAxisY,0]);

const getYAxis = ref => {
  const yAxis = d3.axisLeft(getYRect)
    .tickSize(-widthSvg) // ширина горизонтальных линий на графике
  d3.select(ref).call(yAxis);
};

const buttonZoomPlus = <div className='buttonZoomPlus' onClick={function () {
  if (heightY > Math.floor(maxY / 7)) {
    setheightY(heightY - Math.floor(maxY / 7))
  }
}}>+</div>;
const buttonZoomMinus = <div className='buttonZoomMinus' onClick={function () {
  if (heightY < maxY) {
    setheightY(heightY + Math.floor(maxY / 7))
  }
}}>&mdash;</div>;

const svg = <svg width={widthSvg} height={lengthAxisY + 10} style={{borderRight: '1px solid blue'}}>
<g className='YAxis' ref={getYAxis} stroke-width='0.3'/>
{
  data.map( function (item, index) {
    return <> 
    <circle className="circleRect"
      key={index}
      cx={`${60 + (index * rectWidth) + (index * columnGap) + marginLeft}`}
      cy={lengthAxisY + 20}
      r={10}
      fill= {`${item.color}`}
    />

    <rect className={`rect${index}`} fill= {`${item.color}`} 
    x={`${20 + (index * rectWidth) + (index * columnGap) + marginLeft}`} y={getYRect(item.value)} 
    width={rectWidth} height={lengthAxisY - getYRect(item.value)} 
    onClick={event => console.log(event.target.className)}></rect>
    
    <text
    fill="black"
    x={`${67 + (index * (rectWidth)) + (index * columnGap) + marginLeft}`}
    y={getYRect(item.value) - 8}
    textAnchor="middle">
    {item.value}
    </text>

    <text
    className='columnText'
    fill="black"
    x={`${60 + (index * (rectWidth)) + (index * columnGap) + marginLeft}`}
    y={lengthAxisY + 45}
    textAnchor="middle">
    {item.nameColumn}
    </text>
    </>

  })
}

</svg>;

return <>
  {svg}
  {buttonZoomPlus}
  {buttonZoomMinus}
</>;
}

export default WeHelpDiagram;
