
import React, {Component} from 'react'

import { findDOMNode } from 'react-dom';

import './Table.css';
import TableStack from './classTableStack';
import ChangeRepository from './classChangeRepository';
import StoreRequest from './classStoreRequest';
import StoreTables from './classStoreTables';
import saveSelectedRowsBetweenMounts from './saveSelectedRowsBetweenMounts';
import IndexedDB from './IndexedDB';

import adress from '../../adress.js'


function fetchFunctionMain (token, id, setChangeStateOfPreTablePageWrapping, lineId, justWatch) {
  let id_for_indexedDB = `${lineId}_${id}`
  //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=0&formId=${id}&Id=${lineId}`)
  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=0&formId=${id}&Id=${lineId}`)
  .then(response => response.json())
  .then(function (json) {
    console.log('json', json);
    ChangeRepository.add.indexForTheGroupOfDependentFields = {
      count: 0,
    };
    let count = 0;
    for (let i = 0; i < json.GetFormKolonlarResult.length; i++) {
      if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] !== undefined) {
        ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP].push({
          GRUP: json.GetFormKolonlarResult[i].GRUP,
          GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
          ID: json.GetFormKolonlarResult[i].ID,
          KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
          VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
          LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
          KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
          BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
          KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
          KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
          BAGLI_KOLON_DEGER: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER,
          BAGLI_KOLON_DEGER_ESITSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESITSE,
          BAGLI_KOLON_DEGER_ESIT_DEGILSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESIT_DEGILSE,
          BAGLI_KOLON_ID: json.GetFormKolonlarResult[i].BAGLI_KOLON_ID,
          BAGLI_KOLON_MASTER: json.GetFormKolonlarResult[i].BAGLI_KOLON_MASTER,


          flagMainTable: true,
          parentId: '0',
          parentLine: '0',
        });
        if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
          ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
        }
        count++;
      } else if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] === undefined) {
        ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] = [{
          GRUP: json.GetFormKolonlarResult[i].GRUP,
          GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
          ID: json.GetFormKolonlarResult[i].ID,
          KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
          VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
          LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
          KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
          BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
          KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
          KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
          BAGLI_KOLON_DEGER: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER,
          BAGLI_KOLON_DEGER_ESITSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESITSE,
          BAGLI_KOLON_DEGER_ESIT_DEGILSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESIT_DEGILSE,
          BAGLI_KOLON_ID: json.GetFormKolonlarResult[i].BAGLI_KOLON_ID,
          BAGLI_KOLON_MASTER: json.GetFormKolonlarResult[i].BAGLI_KOLON_MASTER,

          flagMainTable: true,
          parentId: '0',
          parentLine: '0',
        }];
        if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
          ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
        }
        count++;
        
      }
      if (json.GetFormKolonlarResult[i].BAGLI_KOLON_ID != 0 && json.GetFormKolonlarResult[i].BAGLI_KOLON_ID != null) {
        if (ChangeRepository.add.indexForTheGroupOfDependentFields[json.GetFormKolonlarResult[i].BAGLI_KOLON_ID] === undefined) {
          ChangeRepository.add.indexForTheGroupOfDependentFields[json.GetFormKolonlarResult[i].BAGLI_KOLON_ID] = {
            [String(json.GetFormKolonlarResult[i].GRUP) + "_" + String(ChangeRepository.add?.[json.GetFormKolonlarResult[i].GRUP]?.length - 1)]: true,
          };
          ChangeRepository.add.indexForTheGroupOfDependentFields.count++;
        } else {
          ChangeRepository.add.indexForTheGroupOfDependentFields[json.GetFormKolonlarResult[i].BAGLI_KOLON_ID][String(json.GetFormKolonlarResult[i].GRUP) + "_" + String(ChangeRepository.add?.[json.GetFormKolonlarResult[i].GRUP]?.length - 1)] = true;
        }
        
      } 
    } 
    ChangeRepository.add.count = count;
    ChangeRepository.add.justWatch = justWatch;
    console.log('buttons fetchFunctionMain ChangeRepository.add', ChangeRepository.add);
    setChangeStateOfPreTablePageWrapping([0]);
  }).then(() => IndexedDB.putValue(id, lineId, id_for_indexedDB))
}

function fetchFunctionChild (token, numberTable, id, setChangeStateOfPreTablePageWrapping, lineId, justWatch) {
  console.log('fetchFunctionChild id', id);

  let parentData = id.split('_');
  let id_for_indexedDB = `${lineId}_${numberTable}_${id}`;
  ChangeRepository.add.indexForTheGroupOfDependentFields = {
    count: 0,
  };
  console.log('fetchFunctionChild StoreRequest.store[id][numberTable]', StoreRequest.store[id]);
  //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=${parentData[1]}&formId=${StoreRequest.store[id][numberTable].FORM_ID}&Id=${lineId}`)
  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=${parentData[1]}&formId=${StoreRequest.store[id][numberTable].FORM_ID}&Id=${lineId}`)
  .then(response => response.json())
  .then(function (json) {
    let count = 0;
    for (let i = 0; i < json.GetFormKolonlarResult.length; i++) {
      if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] !== undefined) {
        ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP].push({
          GRUP: json.GetFormKolonlarResult[i].GRUP,
          GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
          ID: json.GetFormKolonlarResult[i].ID,
          KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
          VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
          LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
          KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
          BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
          KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
          KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
          BAGLI_KOLON_DEGER: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER,
          BAGLI_KOLON_DEGER_ESITSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESITSE,
          BAGLI_KOLON_DEGER_ESIT_DEGILSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESIT_DEGILSE,
          BAGLI_KOLON_ID: json.GetFormKolonlarResult[i].BAGLI_KOLON_ID,
          BAGLI_KOLON_MASTER: json.GetFormKolonlarResult[i].BAGLI_KOLON_MASTER,

          flagMainTable: false,
          parentId: parentData[0],
          parentLine: parentData[1],
        });
        if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
          ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
        }
        count++;
      } else if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] === undefined) {
        ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] = [{
          GRUP: json.GetFormKolonlarResult[i].GRUP,
          GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
          ID: json.GetFormKolonlarResult[i].ID,
          KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
          VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
          LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
          KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
          BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
          KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
          KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
          BAGLI_KOLON_DEGER: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER,
          BAGLI_KOLON_DEGER_ESITSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESITSE,
          BAGLI_KOLON_DEGER_ESIT_DEGILSE: json.GetFormKolonlarResult[i].BAGLI_KOLON_DEGER_ESIT_DEGILSE,
          BAGLI_KOLON_ID: json.GetFormKolonlarResult[i].BAGLI_KOLON_ID,
          BAGLI_KOLON_MASTER: json.GetFormKolonlarResult[i].BAGLI_KOLON_MASTER,
          
          flagMainTable: false,
          parentId: parentData[0],
          parentLine: parentData[1],
        }];
        if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
          ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
        }
        count++;
      }
      if (json.GetFormKolonlarResult[i].BAGLI_KOLON_ID != 0 && json.GetFormKolonlarResult[i].BAGLI_KOLON_ID != null) {
        if (ChangeRepository.add.indexForTheGroupOfDependentFields[json.GetFormKolonlarResult[i].BAGLI_KOLON_ID] === undefined) {
          ChangeRepository.add.indexForTheGroupOfDependentFields[json.GetFormKolonlarResult[i].BAGLI_KOLON_ID] = {
            [String(json.GetFormKolonlarResult[i].GRUP) + "_" + String(ChangeRepository.add?.[json.GetFormKolonlarResult[i].GRUP]?.length - 1)]: true,
          };
          ChangeRepository.add.indexForTheGroupOfDependentFields.count++;
        } else {
          ChangeRepository.add.indexForTheGroupOfDependentFields[json.GetFormKolonlarResult[i].BAGLI_KOLON_ID][String(json.GetFormKolonlarResult[i].GRUP) + "_" + String(ChangeRepository.add?.[json.GetFormKolonlarResult[i].GRUP]?.length - 1)] = true;
        }
        
      } 
    }
    ChangeRepository.add.count = count;
    ChangeRepository.add.justWatch = justWatch;
    console.log('ChangeRepository.add', ChangeRepository.add);
    
    setChangeStateOfPreTablePageWrapping([0]);
  }).then(() => IndexedDB.putValue(id, lineId, id_for_indexedDB))
}

//this.setState
function changeMainTable (ChangeRepository, token, id, setChangeStateOfPreTablePageWrapping, renderThisComponent, lineId = 0, justWatch = false) {
  if (!!window.indexedDB) { 
    let id_for_indexedDB = `${lineId}_${id}`;
    console.log('buttons changeMainTable ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB]', ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB]);
    //if (ChangeRepository.dataBase == 0 || ChangeRepository.dataBase == undefined) {
    if (ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB] == undefined || ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB] == false) {
      IndexedDB.openIndexedDB(() => fetchFunctionMain (token, id, setChangeStateOfPreTablePageWrapping, lineId, justWatch));
    } else {
      IndexedDB.getValue(id, lineId, justWatch, id_for_indexedDB, setChangeStateOfPreTablePageWrapping);
      console.log('buttons changeMainTable ChangeRepository.add', ChangeRepository.add);
    }
  } else {
    //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=0&formId=${id}&Id=${lineId}`)
    fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=0&formId=${id}&Id=${lineId}`)
    .then(response => response.json())
    .then(function (json) {
      console.log('json', json);
      
      let count = 0;
      for (let i = 0; i < json.GetFormKolonlarResult.length; i++) {
        if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] !== undefined) {
          ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP].push({
            GRUP: json.GetFormKolonlarResult[i].GRUP,
            GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
            ID: json.GetFormKolonlarResult[i].ID,
            KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
            VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
            LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
            KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
            BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
            KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
            KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
            flagMainTable: true,
            parentId: '0',
            parentLine: '0',
          });
          if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
            ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
          }
          count++;
        } else if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] === undefined) {
          ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] = [{
            GRUP: json.GetFormKolonlarResult[i].GRUP,
            GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
            ID: json.GetFormKolonlarResult[i].ID,
            KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
            VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
            LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
            KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
            BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
            KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
            KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
            flagMainTable: true,
            parentId: '0',
            parentLine: '0',
          }];
          if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
            ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
          }
          count++;
        }
      }
      ChangeRepository.add.count = count;
      ChangeRepository.add.justWatch = justWatch;
      setChangeStateOfPreTablePageWrapping([0]);
    })
  }

}

function changeChildTable (ChangeRepository, token, numberTable, id, setChangeStateOfPreTablePageWrapping, lineId = 0, justWatch = false) {
  
  if (!!window.indexedDB) {
    let id_for_indexedDB = `${lineId}_${numberTable}_${id}`;
    console.log('buttons changeMainTable ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB]', ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB]);
    if (ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB] == undefined || ChangeRepository.checkingDatabaseValue[id]?.[id_for_indexedDB] == false) {
      console.log('buttons changeMainTable id', id);
      IndexedDB.openIndexedDB(() => fetchFunctionChild (token, numberTable, id, setChangeStateOfPreTablePageWrapping, lineId, justWatch));
    } else {
      IndexedDB.getValue(id, lineId, justWatch, id_for_indexedDB, setChangeStateOfPreTablePageWrapping);
      console.log('buttons changeMainTable ChangeRepository.add', ChangeRepository.add);
  
    }
  } else {
    console.log('changeChildTable id', id);
    let parentData = id.split('_');
    console.log('lineId', lineId);
    //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=${parentData[1]}&formId=${StoreRequest.store[id][numberTable].FORM_ID}&Id=${lineId}`)
    fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetFormKolonlar/?sessionId=${token}&masterFormKayitId=${parentData[1]}&formId=${StoreRequest.store[id][numberTable].FORM_ID}&Id=${lineId}`)
      .then(response => response.json())
      .then(function (json) {
        console.log('json', json);
        
        console.log('parentData', parentData);
        let count = 0;
        for (let i = 0; i < json.GetFormKolonlarResult.length; i++) {
          if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] !== undefined) {
            ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP].push({
              GRUP: json.GetFormKolonlarResult[i].GRUP,
              GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
              ID: json.GetFormKolonlarResult[i].ID,
              KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
              VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
              LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
              KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
              BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
              KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
              KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
              flagMainTable: false,
              parentId: parentData[0],
              parentLine: parentData[1],
            });
            if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
              ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
            }
            count++;
          } else if (ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] === undefined) {
            ChangeRepository.add[json.GetFormKolonlarResult[i].GRUP] = [{
              GRUP: json.GetFormKolonlarResult[i].GRUP,
              GORUNEN_ADI: json.GetFormKolonlarResult[i].GORUNEN_ADI,
              ID: json.GetFormKolonlarResult[i].ID,
              KOLON_TIPI: json.GetFormKolonlarResult[i].KOLON_TIPI,
              VIEW_DATA: json.GetFormKolonlarResult[i].VIEW_DATA ?? 0,
              LS_FORM_RAPOR_MASTER_ID: json.GetFormKolonlarResult[i].LS_FORM_RAPOR_MASTER_ID,
              KOLON_ADI: json.GetFormKolonlarResult[i].KOLON_ADI,
              BOS_OLABILIRMI: json.GetFormKolonlarResult[i].BOS_OLABILIRMI,
              KOLON_KITLI: json.GetFormKolonlarResult[i].KOLON_KITLI,
              KOLON_DEGER: json.GetFormKolonlarResult[i].KOLON_DEGER,
              flagMainTable: false,
              parentId: parentData[0],
              parentLine: parentData[1],
            }];
            if (json.GetFormKolonlarResult[i].GORUNEN_ADI == "ID") {
              ChangeRepository.add.ID = json.GetFormKolonlarResult[i].KOLON_DEGER ?? '0';
            }
            count++;
          }
        }
        ChangeRepository.add.count = count;
        ChangeRepository.add.justWatch = justWatch;
        console.log('ChangeRepository.add', ChangeRepository.add);
        
        setChangeStateOfPreTablePageWrapping([0]);
    }); 
  } 
}

function updateTable (setdatapreTablePage, id, setChangeStateOfPreTablePageWrapping, completely = false) {

  if (completely) {
    for (let key in StoreRequest.store) {
      StoreRequest.store[key] = 'generalUpdate';
      TableStack.currentId = 0;
      TableStack.childListArr.length = 0;
      TableStack.lastLines = {};
      ChangeRepository.checkingDatabaseValue = {};
      StoreTables.tables = {};
    }
  } else {
    StoreRequest.store[id] = 0;
    if (ChangeRepository.checkingDatabaseValue[id] != undefined) {
      ChangeRepository.checkingDatabaseValue[id] = {};
    }
    StoreTables.tables[id] = 0;
    saveSelectedRowsBetweenMounts();
  }

  console.log('updateTable id', id);

  setChangeStateOfPreTablePageWrapping([0]);
  setdatapreTablePage(0);
}

const dateForDelete = new Date();


class Buttons extends React.Component {
             
  constructor(props) {
      super(props);
      this.state = {
        availabilityOfMeaning: 0,
        dialogWindow: false,
        renderThisComponent: [0],
      };
      this.ref = { table: 0, str: 0, currentId: 0, flagOfChangeId: 0, referensOfSiblingTable: 0,};
      this.listenFunc = this.listenFunc.bind(this);
      this.addLine = this.addLine.bind(this);
      this.changeLine = this.changeLine.bind(this);
      this.viewParametersOfLine = this.viewParametersOfLine.bind(this);
      this.opendeleteModalWindow = this.opendeleteModalWindow.bind(this);
      this.closedeleteModalWindow = this.closedeleteModalWindow.bind(this);
      this.deleteLine = this.deleteLine.bind(this);
      this.updateThisTable = this.updateThisTable.bind(this);
      this.updateAllTables = this.updateAllTables.bind(this);
      this.closeThisTable = this.closeThisTable.bind(this);
      this.renderThisComponent = this.renderThisComponent.bind(this);
      this.deleteIndexedDB = this.deleteIndexedDB.bind(this);

  }

  listenFunc (event) {
    console.log('event.detail.id', event.detail.id);
    console.log('this.props.id', this.props.id);

    console.log('event.detail.id == this.props.id', event.detail.id == this.props.id);
    if (event.detail.id == this.props.id) {
      console.log('this.props.id', this.props.id);
      console.log('event.detail.id', event.detail.id);
      this.setState(function() {
        return {
          availabilityOfMeaning: TableStack.lastLines[this.props.id],
        };
      });
      console.log(this.state.availabilityOfMeaning);
    }
  }
 
  componentDidMount() {

    //this.ref.referensOfSiblingTable = findDOMNode(this).nextElementSibling?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`)?.[0]?.innerText;
    if (findDOMNode(this).nextElementSibling.localName == 'div') {
      //this.ref.referensOfSiblingTable = findDOMNode(this).nextElementSibling?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`)?.[0];
      this.ref.referensOfSiblingTable = 1;

    }

    console.log('componentDidMount node', findDOMNode(this).nextElementSibling);

    document.addEventListener('changedLineOfTable', this.listenFunc);
    console.log('компонент был смонтирован');
    console.log('componentDidMount this.props.id', this.props.id);
    if (this.ref.flagOfChangeId == 0 ) {this.ref.flagOfChangeId = this.props.id;}
    
    if (!this.props.id.includes('_')) {
      this.ref.table = document.getElementsByClassName(`${this.props.id} MuiBox-root`);
      console.log('Buttons componentDidMount this.props.id', this.props.id);
      console.log('Buttons componentDidMount this.ref.table', this.ref.table);

    } else if (this.props.serverIdForLine != 0) {
      this.ref.table = document.getElementsByClassName(`${this.props.serverIdForLine} MuiBox-root`);
      this.ref.currentId = this.props.serverIdForLine;
    }

    //table = document.getElementsByClassName(`${this.props.id} MuiBox-root`);
    console.log('componentDidMount tableButtons', this.ref.table);
    console.log('Buttons TableStack.lastLines[this.props.id]', TableStack.lastLines[this.props.id]);

    if( TableStack.lastLines[this.props.id] != 0 && TableStack.lastLines[this.props.id] != undefined && this.ref.table.length != 0 && this.ref.table != 0) {
      console.log('Buttons TableStack.lastLines[this.props.id]', TableStack.lastLines[this.props.id]);
      console.log('Buttons this.ref.table', this.ref.table);
      this.ref.str = this.ref.table[0].getElementsByTagName('tr');
      for (let i = 1; i < this.ref.str.length; i++) {
        if (this.ref.str[i].childNodes[1].innerHTML == TableStack.lastLines[this.props.id]) {
          console.log('Buttons str cicle', this.ref.str[i].childNodes[1].innerHTML);
          this.ref.str = this.ref.str[i];
          if (StoreRequest.colorArray[this.props.id] !== undefined || StoreRequest.colorArray[this.props.serverIdForLine] !== undefined) {
            //this.ref.str.style.backgroundColor = null;
            //this.ref.str.style.backgroundColor = '#c8c7cc';
            this.ref.str.setAttribute('style', `background-color: #c8c7cc !important;`);
          } else {
            this.ref.str.classList.add('highlightedLine');
          }

        }
      }
    }

    /*
    функционал выделения последней нажатой строки даже после перемонтирования уомпонента 
    реализовать очень сложно. Можно поискать в таблице все элементы с тегом tr, отыскать у них 
    2 элемент, потом итерировать все эти элементы в цикле, и если текст в элементе совпадает 
    с номером нажатой таблицы, добавить стиль родительскому элементу. 
    */

    /*
    Здесь проблема из за разных id. id одинаково для главных страниц,
    а вот для дочерних страниц оно разное. Здесь нужно использовать id, 
    которое присылается с сервера.

    Проблема в том, что пересылаются 2 таблицы в одном ответе с сервера,
    а это значит, что id таблицы нельзя взять напрямую из за архитектуры компонентов.
    Id спрятано внутри многомерного объекта и указать на id можно только при наличии 
    ответа на запрос. В данном случае ответ на запрос всегда приходит позже чем 
    отрисовывается данный компонент.

    Данная проблема решается если ввести условия для отрисовки компонента. 
    Однако появляется другая проблема - выводится один компонент таблицы и один компонент кнопок, 
    но таблиц 2, а переменные для выделения строк одни на эти 2 таблицы.

    Может добавить еще компонент? Но компонен кнопок уже настроен для рендеринга при нажатии 
    на строку таблицы, будет довольно сложно сделать еще один такой компонент
    */
  }

  componentWillUnmount() {
    document.removeEventListener('changedLineOfTable', this.listenFunc);
    console.log('компонент был размонтирован');
  }
  componentDidUpdate(){
    /*
    if(this.ref.referensOfSiblingTable == 0) {
      this.ref.referensOfSiblingTable = findDOMNode(this).nextElementSibling?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`)?.[0];
    }
    */


    if (this.ref.table == 0 || this.ref.table.length == 0) {
      console.log('Buttons componentDidUpdate проверка условия с ненайденным компонентом this.ref.table', this.props.id);
      if (this.props.id.includes('_')) {
        this.ref.table = document.getElementsByClassName(`${this.props.serverIdForLine} MuiBox-root`);
      } else {
        this.ref.table = document.getElementsByClassName(`${this.props.id} MuiBox-root`);
      }
      console.log('buttons this.props.serverIdForLine', this.props.serverIdForLine);
    }

    if (this.props.id.includes('_') && this.ref.currentId != this.props.serverIdForLine) {
      console.log('this.ref.currentId != this.props.id', this.ref.currentId != this.props.serverIdForLine);
      this.ref.currentId = this.props.serverIdForLine;
      if (this.ref.str != 0) {
        if (StoreRequest.colorArray[this.props.id] !== undefined) {
          this.ref.str.style.backgroundColor = this.ref.str.lastChild?.textContent;
          this.ref.str.classList?.remove('highlightedLine');
        } else {
          this.ref.str.classList?.remove('highlightedLine');
        }
        //this.ref.str.classList?.remove('highlightedLine');


        this.ref.str = 0;
        this.ref.table = 0;
      }
      console.log('this.ref.str', typeof this.ref.str, this.ref.str);
    }


    console.log('componentDidUpdate this.ref.table', this.ref.table);
    console.log('Buttons componentDidUpdate this.ref.table != 0', this.ref.table != 0);
    console.log('Buttons componentDidUpdate this.ref.table.length != 0', this.ref.table.length != 0);
    console.log('Buttons componentDidUpdate this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode != undefined', this.ref.table[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode != undefined);
    console.log('componentDidUpdate this.ref.table.length != 0 && this.ref.table[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode', this.ref.table.length != 0 && this.ref.table[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode);


    if(this.ref.table != 0 && this.ref.table.length != 0 && this.ref.table[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode != undefined) {
      if (this.ref.str != 0) {
        console.log('Buttons componentDidUpdate this.ref.str', this.ref.str);
        //this.ref.str.classList?.remove('highlightedLine');
        //console.log('Buttons componentDidUpdate this.ref.str.lastChild.textContent', this.ref.str.lastChild.textContent);
        if (StoreRequest.colorArray[this.props.id] !== undefined || StoreRequest.colorArray[this.props.serverIdForLine] !== undefined) {
          if (this.ref.str != undefined && this.ref.str.style != undefined) {
            this.ref.str.setAttribute('style', `background-color: ${StoreRequest.colorArray[this.props.id]?.[this.ref.str.children[1].textContent] ?? StoreRequest.colorArray[this.props.serverIdForLine][this.ref.str.children[1].textContent]} !important;`);
            this.ref.str.classList?.remove('highlightedLine');

          }
        } else {
          this.ref.str.classList?.remove('highlightedLine');
        }
      }
      this.ref.str = this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)?.[0]?.parentNode;

      //this.ref.str.classList.add('highlightedLine');
      if (StoreRequest.colorArray[this.props.id] !== undefined || StoreRequest.colorArray[this.props.serverIdForLine] !== undefined) {
        //this.ref.str.style.backgroundColor = null;
        //this.ref.str.style.backgroundColor = '#c8c7cc';
        this.ref.str.setAttribute('style', `background-color: #c8c7cc !important;`);
      } else {
        this.ref.str.classList.add('highlightedLine');
      }

      console.log('Buttons str', this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)?.[0]?.innerHTML);
      console.log('Buttons str tr', this.ref.table[0].getElementsByTagName('tr'));


    }

    if(this.ref.table != 0 && this.ref.table.length != 0 && this.ref.table[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode == undefined) {
      this.ref.str = this.ref.table[0].getElementsByTagName('tr');
      for (let i = 1; i < this.ref.str.length; i++) {
        if (this.ref.str[i]?.childNodes[1]?.innerHTML == TableStack.lastLines[this.props.id]) {
          console.log('Buttons str cicle', this.ref.str[i].childNodes[1].innerHTML);
          this.ref.str = this.ref.str[i];
          //this.ref.str.classList.add('highlightedLine');
          if (StoreRequest.colorArray[this.props.id] !== undefined || StoreRequest.colorArray[this.props.serverIdForLine] !== undefined) {
            //this.ref.str.style.backgroundColor = null;
            //this.ref.str.style.backgroundColor = '#c8c7cc';
            this.ref.str.setAttribute('style', `background-color: #c8c7cc !important;`);
          } else {
            this.ref.str.classList.add('highlightedLine');
          }
        }
      }
    }

/*
if (this.ref.table != 0 && this.ref.table.length != 0) {

  if(this.ref.table[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode != undefined) {
    if (this.ref.str != 0 && this.ref.str != undefined) {
      this.ref.str.classList.remove('highlightedLine');
    }
    this.ref.str = this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)?.[0]?.parentNode;
  
    this.ref.str.classList.add('highlightedLine');
  
    console.log('Buttons str', this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)?.[0]?.innerHTML);
    console.log('Buttons str tr', this.ref.table[0].getElementsByTagName('tr'));
  
  
  } else {
    this.ref.str = this.ref.table[0].getElementsByTagName('tr');
    for (let i = 1; i < this.ref.str.length; i++) {
      if (this.ref.str[i].childNodes[1].innerHTML == TableStack.lastLines[this.props.id]) {
        console.log('Buttons str cicle', this.ref.str[i].childNodes[1].innerHTML);
        this.ref.str = this.ref.str[i];
        this.ref.str.classList.add('highlightedLine');
      }
    }
  }
}
*/

    console.log('componentDidUpdate StoreRequest', StoreRequest);
    console.log('componentDidUpdate this.ref.table', this.ref.table);
    console.log('componentDidUpdate this.ref.str', this.ref.str);
    console.log('componentDidUpdate compare', this.ref.table != 0 && this.ref.table.length != 0 && this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode != undefined);
    console.log('componentDidUpdate compare this.ref.table != 0', this.ref.table != 0);
    console.log('componentDidUpdate compare this.ref.table.length != 0', this.ref.table.length != 0);
    console.log('componentDidUpdate compare this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode != undefined', this.ref.table?.[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)?.[0]?.parentNode != undefined);
    console.log('componentDidUpdate compare this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode', this.ref.table?.[0]?.getElementsByClassName(`${TableStack.lastLines[this.props.id]}`));
    console.log('componentDidUpdate TableStack.lastLines[this.props.id]', TableStack.lastLines[this.props.id]);



    /*
    .getElementsBy... возвращает коллекцию, а значит чтобы искать 
    в каком то конкретном элементе нужно использовать индекс,
    чтобы указывать не на коллекцию, а на элемент.

    Проблема со стилями в библиотеке, создатели таблицы установили значение !important 
    для фона строки.

    Проблема при первом рендеринге

    Проблема с выделением строки остается нерешенной.
    Удается отследить изменение id таблицы, но попытка убрать класс у 
    выделеного элемента выдает ошибку. это удалось решить после проверки значения на 0.

    Проблема с выделением некоторых строк. 
    В this.ref.table иногда ничего не записывается.

    Иногда неправильно срабатывает сравнение. 
    this.ref.table[0].getElementsByClassName(`${TableStack.lastLines[this.props.id]}`)[0]?.parentNode != undefined
    выдает false. Выдает потому что в str 0.

    Проблема с выделением строк после обновления таблицы.

    Была проблема с добавлением ячейке класса с номером строки.
    Эта проблема решена использованием другого метода. 
    */
    
    console.log('Buttons componentDidUpdate this.ref.flagOfChangeId, this.props.id', this.ref.flagOfChangeId, this.props.id);
    if(this.ref.flagOfChangeId != this.props.id) {
      this.ref.flagOfChangeId = this.props.id;
      this.ref.str.classList?.remove('highlightedLine');
      console.log('Buttons componentDidUpdate this.ref.flagOfChangeId != this.props.id');
      let lines = this.ref.table?.[0]?.getElementsByTagName('tr');
      console.log('Buttons componentDidUpdate lines ', lines);
      if (lines !== undefined) {
        for (let elem of lines) {
          elem.style.backgroundColor = null;
          elem.style.backgroundColor = '#fff';
          //elem.setAttribute('style', `background-color: #fff !important;`);
          elem.classList?.remove('highlightedLine');
        }
      }
      TableStack.lastLines[this.props.id] = 0;
      this.setState(function() {
        console.log('Buttons componentDidUpdate state changed', TableStack.lastLines[this.props.id]);
        return {
          renderThisComponent: [0],
        };
      });
    }
  }

  addLine() {
    if (ChangeRepository.add.count === undefined && !(StoreRequest.buttonsForm[this.props.id]?.YENI_KAYIT === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.YENI_KAYIT === false)) {
      if (this.props.id.includes('_')) {
        changeChildTable (ChangeRepository, this.props.token, this.props.numberTable, this.props.id, this.props.setChangeStateOfPreTablePageWrapping);
      } else {
        changeMainTable (ChangeRepository, this.props.token, this.props.id, this.props.setChangeStateOfPreTablePageWrapping, this.renderThisComponent);
      }
    }  
  }

  changeLine() {
    if (TableStack.lastLines[this.props.id] != 0 && !(StoreRequest.buttonsForm[this.props.id]?.GUNCELLE === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.GUNCELLE === false)) {
      if (this.props.id.includes('_')) {
        changeChildTable (ChangeRepository, this.props.token, this.props.numberTable, this.props.id, this.props.setChangeStateOfPreTablePageWrapping, TableStack.lastLines[this.props.id]);
      } else {
        changeMainTable (ChangeRepository, this.props.token, this.props.id, this.props.setChangeStateOfPreTablePageWrapping, this.renderThisComponent, TableStack.lastLines[this.props.id]);
      }
      console.log(TableStack.lastLines[this.props.id]);
    }
  }

  viewParametersOfLine() {
    if (TableStack.lastLines[this.props.id] != 0) {
      if (this.props.id.includes('_')) {
        changeChildTable (ChangeRepository, this.props.token, this.props.numberTable, this.props.id, this.props.setChangeStateOfPreTablePageWrapping, TableStack.lastLines[this.props.id], true);
      } else {
        changeMainTable (ChangeRepository, this.props.token, this.props.id, this.props.setChangeStateOfPreTablePageWrapping, this.renderThisComponent, TableStack.lastLines[this.props.id], true);
      }
    }
  }

  opendeleteModalWindow() {
    if (TableStack.lastLines[this.props.id] != 0 && !(StoreRequest.buttonsForm[this.props.id]?.SIL === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.SIL === false)) {
      this.setState(function() {
        return {
          dialogWindow: true,
        }; 
      })
    }
  }

  closedeleteModalWindow() {
    this.setState(function() {
      return {
        dialogWindow: false,
      }; 
    })
  }

  deleteLine() {
    console.log('TableStack.lastLines[this.props.id] != 0', TableStack.lastLines[this.props.id] != 0);
    console.log(StoreRequest.buttonsForm[this.props.id]?.SIL === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.SIL === false);
    

    if (TableStack.lastLines[this.props.id] != 0 && !(StoreRequest.buttonsForm[this.props.id]?.SIL === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.SIL === false)) {
      let str;
      if (this.props.id.includes('_')) {
        str = `${this.props.token}*${StoreRequest.store[this.props.id][this.props.numberTable].FORM_ID}*ID;${TableStack.lastLines[this.props.id]}|IPTAL_TARIHI;${dateForDelete.getDate() > 9 ? dateForDelete.getDate() : '0' + String(dateForDelete.getDate())}.${(dateForDelete.getMonth() + 1) > 9 ? (dateForDelete.getMonth() + 1) : '0' + String((dateForDelete.getMonth() + 1))}.${dateForDelete.getFullYear()}*0*0`;
      } else {
        str = `${this.props.token}*${this.props.id}*ID;${TableStack.lastLines[this.props.id]}|IPTAL_TARIHI;${dateForDelete.getDate() > 9 ? dateForDelete.getDate() : '0' + String(dateForDelete.getDate())}.${(dateForDelete.getMonth() + 1) > 9 ? (dateForDelete.getMonth() + 1) : '0' + String((dateForDelete.getMonth() + 1))}.${dateForDelete.getFullYear()}*0*0`;
      }

      
      //fetch('https://creaapi.com/crealogKlon/CrealogMaster.svc/SaveForm', {
        fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/SaveForm`, {
          method: 'POST', 
          body: str, 
        //'Content-type': 'application/json; charset=UTF-8',
      })
      .then(() => updateTable(this.props.setdatapreTablePage, this.props.id, this.props.setChangeStateOfPreTablePageWrapping))
      .then(() => this.closedeleteModalWindow())
    }
  }

  updateThisTable() {
    /*
    console.log('updateTable TableStack.lastLocalUpdate', this.ref.referensOfSiblingTable.innerText);
    let node = this.ref.referensOfSiblingTable?.innerText.match(/\d+-\d+/)[0].split('-');
    console.log('updateTable referensOfSiblingTable', node);
    */
    
    
    let node = document.getElementsByClassName("MuiBox-root css-14oz8cr")[0]?.lastChild?.lastChild?.firstChild?.lastChild?.children?.[0]?.children;
    if (node.length != 0) {
      let node1 = Number(node[1].innerText.match(/\d+/)[0]);
      let size = Number(node[0].lastChild.firstChild.innerText);
      let index = (node1 - 1) / size;

      TableStack.lastLocalUpdate = {pageIndex: index, pageSize: size,};
      console.log('updateTable referensOfSiblingTable', TableStack.lastLocalUpdate);

    }
    
    updateTable(this.props.setdatapreTablePage, this.props.id, this.props.setChangeStateOfPreTablePageWrapping);
  }

  updateAllTables() {
    /*
    let node = this.ref.referensOfSiblingTable?.innerText.match(/\d+-\d+/)[0].split('-');
    let size = node[1] - node[0] + 1;
    let index = node[1] / size - 1;
    TableStack.lastCompletelyUpdate = {pageIndex: index, pageSize: size,};
    */
    
    /*
    let node = findDOMNode(this).nextElementSibling;
    if (node.className.includes("MuiBox-root")) {
      let node1 = node?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`)?.[0]?.innerText.match(/\d+-\d+/)[0].split('-');
      let size = node1[1] - node1[0] + 1;
      let index = node1[1] / size - 1;
      console.log('updateTable referensOfSiblingTable', index, size);
      TableStack.lastCompletelyUpdate = {pageIndex: index, pageSize: size,};
    }
    */

    let node = document.getElementsByClassName("MuiBox-root css-14oz8cr")[0]?.lastChild?.lastChild?.firstChild?.lastChild?.children?.[0]?.children;
    if (node.length != 0) {
      /*
      let node1 = node[0]?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`)?.[0]?.innerText.match(/\d+-\d+/)[0].split('-');
      let size = node1[1] - node1[0] + 1;
      let index = node1[1] / size - 1;
      console.log('updateTable referensOfSiblingTable', index, size);
      */ 

      let node1 = Number(node[1].innerText.match(/\d+/)[0]);
      let size = Number(node[0].lastChild.firstChild.innerText);
      let index = (node1 - 1) / size;
      /*
      console.log('updateTable referensOfSiblingTable', node[0].lastChild.lastChild.firstChild.lastChild.children[0].children);
      console.log('updateTable referensOfSiblingTable', node[0].lastChild.lastChild.firstChild.lastChild.children[0].children[1].innerText);
      console.log('updateTable referensOfSiblingTable', node[0].lastChild.lastChild.firstChild.lastChild.children[0].children[0].lastChild.firstChild.innerText);
*/
      TableStack.lastCompletelyUpdate = {pageIndex: index, pageSize: size,};
      console.log('updateTable referensOfSiblingTable', TableStack.lastCompletelyUpdate);

    }

    updateTable(this.props.setdatapreTablePage, this.props.id, this.props.setChangeStateOfPreTablePageWrapping, true);
  }

  closeThisTable() {
      for (let i = 0; i < TableStack.childListArr?.length; i++) {
        if (TableStack.childListArr[i][0] == this.props.id) {
          TableStack.childListArr.length = i;
        }
      }
      this.props.setChangeStateOfPreTablePageWrapping([0]);
  }

  renderThisComponent() {
    this.setState(function() {
      return {
        renderThisComponent: [0],
      };
    })
  }

  deleteIndexedDB() {
    if (ChangeRepository.dataBase !== 0) {
      IndexedDB.deleteIndexedDB();
    }
  }

  render() {
      return <div className='tableControlForm'>
        {this.props.id.includes('_') && <div className='buttonCloseTable' onClick={this.closeThisTable}> <span>x</span> </div>}
        {(!this.props.id.includes('_') && !!window.indexedDB) && <div className='deleteIndexedDB' onClick={this.deleteIndexedDB}>IndexedDB'yi temizle</div>}
        
        <div className='generalUpdate' onClick={this.updateAllTables}>Tümünü güncelle</div>
        <div className='controlForm' onClick={this.updateThisTable}>Güncelle</div>
        <div className={(this.state.availabilityOfMeaning != 0 && !(StoreRequest.buttonsForm[this.props.id]?.SIL === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.SIL === false)) ? 'controlForm' : 'inactiveDeletion'} 
          onClick={this.opendeleteModalWindow}>Sil</div>
                <div className={TableStack.lastLines[this.props.id] != 0 ? 'controlForm' : 'inactiveDeletion'} 
          onClick={this.viewParametersOfLine}>Gör</div>
                <div className={(this.state.availabilityOfMeaning != 0 && !(StoreRequest.buttonsForm[this.props.id]?.GUNCELLE === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.GUNCELLE === false)) ? 'controlForm' : 'inactiveDeletion'} 
          onClick={this.changeLine}>Değiştir</div>
        <div className={(ChangeRepository.add?.count === undefined && !(StoreRequest.buttonsForm[this.props.id]?.YENI_KAYIT === false || StoreRequest.buttonsForm[this.props.id]?.[this.props.numberTable]?.YENI_KAYIT === false)) ? 'controlForm' : 'inactiveDeletion'} 
          onClick={this.addLine}>Ekle</div>
          
        <div className={this.state.dialogWindow ? 'deleteModalWindow' : 'closeDeleteModalWindow'} onMouseLeave={this.closedeleteModalWindow} onBlur={this.closedeleteModalWindow}>
          <p>{this.state.availabilityOfMeaning} numaralı satırı silmek istediğinizden emin misiniz?</p>
          <div className='buttonsWrapping_deleteModalWindow'>
            <button onClick={this.closedeleteModalWindow}>Hayır</button>
            <button onClick={this.deleteLine}>Evet</button>
          </div>
        </div>

      </div>;
  }
}


export default Buttons;

/*
Для нахождения элемента нужно использовать getElementBy...
let table = document.getElementsByClassName(`${this.props.id}`);

document.getElementById('elem').parentNode - в js указываем на родителя элемента

#root > div > div.\32 268.MuiBox-root.css-gtopnv
*/



//@media (hover: none){.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover{background-color:transparent;}}

// очистить IndexedDB

/*
Возникает проблема с обновлением таблицы. Раньше такого не возникало, проблема замечена после добавления 
функционала сохранения номера страницы после обновления таблицы. Функция с данным функционалом привязана 
именно к двум кнопкам, но не работает при обновлении после добавления строки, или ее удаления. 

При возникновении ошибки идет указание на строку 571. 
В случае прохождения ошибки на 571 строке показывает совершенно пустую таблицу. 
Однако данные из хранилища не удаляются, при повторном открытии таблицы запроса на сервер нет, 
данные выводятся те же самые

Удивительно то, что ошибка появляется только в билде на сайте, а в проекте для разработки проблем 
при совершении тех же самых действий нет. 

Нужно: 
1. проверить наличие такой же проблемы при обновлении страницы во время удаления или добавления строки. 
2. посмотреть где функция удаляет данные из ъранилища
*/
