import './Table.css';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import PreTable from './preTable';
import PreTableChild from './preTableChild';
import PreFormTable from './preFormTable';
import StoreRequest from './classStoreRequest';
import ChangeRepository from './classChangeRepository';
import TableStack from './classTableStack';
import FormTableRepository from './classFormTableRepository';
import StoreTables from './classStoreTables';



function PreTablePageWrapping({id}) {
const [changeStateOfPreTablePageWrapping, setChangeStateOfPreTablePageWrapping] = useState([0]);

  if (TableStack.currentId != id) {
    TableStack.childListArr.length = 0;
    TableStack.currentId = id;
    ChangeRepository.add = [];
    FormTableRepository.formInputsState = {};
    StoreTables.tables = {};
  }
  let childpreTable = [];
  if(TableStack.childListArr.length > 0) {
    console.log('TableStack.childListArr.length', TableStack.childListArr.length);
    
    for (let elem of TableStack.childListArr) {
      console.log(elem);
      if (StoreTables.tables[elem[0]] == 0 || StoreTables.tables[elem[0]] == undefined) {
        console.log('PreTablePageWrapping StoreTables.tables[elem[0]] == 0 || StoreTables.tables[elem[0]] == undefined');
        StoreTables.tables[elem[0]] = <PreTableChild childId={elem[0]} parentId={elem[1]} number={elem[2]} setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping} key={elem[0]}/>;
        childpreTable.push(StoreTables.tables[elem[0]]);
      } else {
        childpreTable.push(StoreTables.tables[elem[0]]);
      }

      //childpreTable.push(<PreTableChild childId={elem[0]} parentId={elem[1]} number={elem[2]} setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping}/>);
      
    } 
  }
  console.log('PreTablePageWrapping обновился', id);


  if (StoreTables.tables[id] == 0 || StoreTables.tables[id] == undefined) {
    StoreTables.tables[id] = <PreTable id={id} parentId={false} number={false} setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping} key={id} />;
    console.log('PreTablePageWrapping StoreTables.tables[id] == 0 || StoreTables.tables[id] == undefined', StoreTables.tables[id]);

  }
  console.log('PreTablePageWrapping TableStack.childListArr', TableStack.childListArr);

  let preTable;
  if (TableStack.childListArr.length == 0) {
    preTable = StoreTables.tables[id];
  } else {
    preTable = [StoreTables.tables[id], ...childpreTable];

  }

  console.log('PreTablePageWrapping ChangeRepository.add.count', ChangeRepository.add.count);
  if (ChangeRepository.add.count !== undefined) {
    return <PreFormTable setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping}/>;
  }
  return <div className='PreTablePageWrapping'>
    {preTable}
  </div>;
}
export default PreTablePageWrapping;

