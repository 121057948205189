
const defaultValue = {pageName: 'authorizationPage'}
const changingPageName = function (state=defaultValue, action) {
  switch (action.type){
    case 'CHANGE_PAGE_NAME':
      return {pageName: action.pageName};
    default:
      return state;
  }
}

export default changingPageName;
