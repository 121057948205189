import { useMemo, useState, useRef } from 'react';
import './Form.css';
import './inputRange2.css';
//import StoreCalendar from './classStoreCalendar';


const nameOfClass = 'divWorkOrder';
const maxValue = 2000;


function RangeInputComponent({dataOfTemplate, setdataOfTemplate, index}) {
    
    const [dataOfinputRange, setdataOfinputRange] = useState(0);


    let range = <>
    <input className='data_of_range' value={dataOfinputRange}  
    onChange={function (event) {
        //console.log('rangeInout', event.nativeEvent.data);
        //console.log('rangeInput', event.target.value % 1, Number.isInteger(Number(event.target.value)));
        let numVariable = Number(event.target.value);
        if (event.target.value != dataOfinputRange && numVariable >= 0 && numVariable <= 2000 && Number.isInteger(numVariable)) {
            if (event.target.value != '') {    
                /*            
                if(dataOfinputRange == 0 && event.target.value[0] == '0' && event.target.value != '0') {
                    event.target.value = event.target.value.slice(1);
                }
                */
                setdataOfinputRange(numVariable);
            } else {
                setdataOfinputRange(0);
            }
        }
    }}
    onKeyDown={function (event) {
        if (event.keyCode == 13 && dataOfTemplate[index + 1] != event.target.value) {
            setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), event.target.value ]);
        }   
    }}
    onBlur={function (event) {
        //console.log('rangeInput StoreCalendar.state[index]', StoreCalendar.state[index + 1])
        if (dataOfTemplate[index + 1] != event.target.value) { 
            setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), event.target.value ]);
        }
    }}
    maxLength={5}/>
    <div className='divOfRange'>
      <input type='range' min={0} max={maxValue} value={dataOfinputRange} 
      onChange={event => setdataOfinputRange(event.target.value)}
      onMouseUp={function (event) {
        if (dataOfTemplate[index + 1] != event.target.value) { 
            setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), event.target.value ]);
        }
      }}/>
    </div>
    <div className='leibleDivOfRange'>
      <p>0</p>
      <p>2000</p>
    </div>
    </>
    return range;
}
 
export default RangeInputComponent;

//      <input type='range' min={0} max={2000} onMouseUp={event => setdataOfinputRange(event.target.value)}/>

//00df