import './Form.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import Form from './Form.js';
import StoreRequest from './classStoreRequestForm';

import adress from '../../adress.js'

const svgLoader = <svg className='loader' viewBox="0 0 32 32">
<path d="M16 0c-8.711 0-15.796 6.961-15.995 15.624 0.185-7.558 5.932-13.624 12.995-13.624 7.18 0 13 6.268 13 14 0 1.657 1.343 3 3 3s3-1.343 3-3c0-8.837-7.163-16-16-16zM16 32c8.711 0 15.796-6.961 15.995-15.624-0.185 7.558-5.932 13.624-12.995 13.624-7.18 0-13-6.268-13-14 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 8.837 7.163 16 16 16z"></path>
</svg>


function PreFormPage({id}) {
console.log("форма попала в выводимый компонент");
const token = useSelector((state) => state.tokenOfSession.token);
//const [datapreFormPage, setdatapreFormPage] = useState(0);
const [dataUpdate, setUpdate] = useState([0]);


if (StoreRequest.store[id] == undefined || StoreRequest.store[id] == 0) {
  //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetRaporParemetreler/?sessionId=${token}&formId=${id}`)
  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetRaporParemetreler/?sessionId=${token}&formId=${id}`)
  .then(response => response.json())
  .then(function (json) {
    
    console.log("форма вызвала fetch");
    StoreRequest.store[id] = (json.GetRaporParemetrelerResult).map(function (item) {
      return {
        GORUNEN_ADI: item.GORUNEN_ADI, 
        ID: item.ID,     
        KOLON_DEGER_TIPI: item.KOLON_DEGER_TIPI,
        VIEW_DATA: item.VIEW_DATA,
        ID: item.ID,
      } 
    });
    setUpdate([0]);
    console.log('fetch', json.GetRaporParemetrelerResult);
  });
}

let formComponent;
if (StoreRequest.store[id] != undefined && StoreRequest.store[id] != 0) {  
  formComponent = <Form data={StoreRequest.store[id]} id={id} />;
}


  return <>
    <div className='PreFormPageConteiner'> 
    {formComponent}
    </div>
  </>;
}

export default PreFormPage;
