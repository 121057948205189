//import './Form.css';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import repair from "../resources/repair.png"
import fuel from "../resources/fuel.png"
import arrowTangle from "../resources/arrowTangle.png"

/*
let data = {
  "ADRES": "Adres bulunamadı !",
  "ARACLAR_ID": 77206,
  "AY_KM": null,
  "BOLGE": "",
  "BOYLAM": 26.839287,
  "CAN_HIZ": 0,
  "DEPO_LINE": null,
  "DEPO_SEVIYE": 0,
  "ENLEM": 38.247758,
  "GIRIS1": 0,
  "GIRIS2": 1,
  "GIRIS3": 0,
  "GIRIS4": 1,
  "GIRIS5": 0,
  "GUN_KM": null,
  "G_X": 13,
  "G_X_SAG": 13,
  "G_X_SOL": 0,
  "G_Y": 4,
  "G_Y_ARKAYA": 0,
  "G_Y_ONE": 4,
  "G_Z": 8,
  "G_Z_ASSAGI": 0,
  "G_Z_YUKARI": 8,
  "HAFTA_KM": null,
  "HIZ": 0,
  "HIZ_LINE": null,
  "IMEI": "862430054444597",
  "ISI_LINE": null,
  "ISI_TAKIP": null,
  "KILOMETRE": 0,
  "KONTAK_ACIKMI": 0,
  "KONTROL_ANI_FREN": 1,
  "KONTROL_ANI_HIZLANMA": 1,
  "KONTROL_ARAC_CEKILIYOR": 1,
  "KONTROL_CARPISMA": 1,
  "KONTROL_CARPISMA_OLASILIGI": 1,
  "KONTROL_DIKKAT_DAGINIKLIGI": 1,
  "KONTROL_EMNIYET_KEMERI": 0,
  "KONTROL_GERI_VITES_DENETIMI": 0,
  "KONTROL_SAG_FAR": 1,
  "KONTROL_SERIT_TAKIP": 1,
  "KONTROL_SIGARA": 1,
  "KONTROL_SOL_FAR": 1,
  "KONTROL_TELEFON": 1,
  "KONTROL_VIRAJ_IHLALI": 1,
  "KONTROL_YIRGUNLUK": 1,
  "MOTOR_DEVRI": 0,
  "MOTOR_ISISI": 0,
  "NAKLIYECI": "",
  "PEDAL_DURUMU": 0,
  "PLAKA": "35BKH924",
  "RENK": "#949494",
  "SANTIYE": "",
  "SON_MESAJ_TARIHI": "04.07.2024 09:18",
  "STATULER": [],
  "STATU_BILGI_ALINAMAYAN": 1,
  "STATU_DURAN": 1,
  "STATU_GPS_HATA": 0,
  "STATU_HAREKETLI": 0,
  "STATU_HIZ_70_UZERINDE_OLAN": 0,
  "STATU_KAMERA": 0,
  "STATU_KONTAK_ACIK": 0,
  "STATU_KONTAK_KAPALI": null,
  "STATU_RENK": "#000000",
  "STATU_SIPARISI_OLAN": 0,
  "STATU_SIPARISI_OLMAYAN": 0,
  "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
  "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
  "STATU_SURUCU_EMNIYET_KEMERI": 0,
  "STATU_URETIM_OLAN": 0,
  "STATU_URETIM_OLMAYAN": 0,
  "STATU_YOLCU_EMNIYET_KEMERI": 0,
  "SURUCU_EMNIYET_KEMERI": 1,
  "TAKOGRAF_ALARM": "",
  "TAKOGRAF_ARAC_HAREKETI": "",
  "TAKOGRAF_ENTEGRASYONU": false,
  "TAKOGRAF_HIZ": 0,
  "TAKOGRAF_HIZ_IHLALI": "",
  "TAKOGRAF_KULLANIM_HATA_BILGI": "",
  "TAKOGRAF_PERFORMANS": "",
  "TAKOGRAF_SURUCU_1_ADI": "",
  "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "",
  "TAKOGRAF_SURUCU_1_KART": "",
  "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "",
  "TAKOGRAF_SURUCU_2_ADI": "",
  "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "",
  "TAKOGRAF_SURUCU_2_KART": "",
  "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "",
  "TAKOGRAF_SURUCU_NUMARASI": "",
  "TAKOGRAF_YON": "",
  "VALIDATION_MESSAGE": null,
  "YIL_KM": null,
  "YOLCU_EMNIYET_KEMERI": 1,
  "YON": 219
};

let elementNumber = 0;
*/

function SideBarBlock({data, elementNumber, elementReference_Right_Sidebar, setupdateComponent, MapWrappingUpdate, selectedBlock}) {
  const elementReference = useRef(0);

  //console.log('SideBarBlock', data);
  if (elementReference.current != 0 && elementReference.current != undefined && elementReference.current != '' && selectedBlock[1] != -1) {
    if (selectedBlock[1] == elementNumber) {
      if (elementReference_Right_Sidebar[0] == 0) {
        elementReference.current.style.marginBottom = '10px';
        elementReference.current.style.marginTop = '10px';
        elementReference_Right_Sidebar[0] = elementReference.current;
        elementReference_Right_Sidebar[1] = elementNumber;
      } else {
        if (elementReference_Right_Sidebar[1] == elementNumber) {
          elementReference.current.style.marginBottom = '';
          elementReference.current.style.marginTop = '';
          elementReference_Right_Sidebar[0] = 0;
          elementReference_Right_Sidebar[1] = -1;
        } else {
          elementReference_Right_Sidebar[0].style.marginBottom = '';
          elementReference_Right_Sidebar[0].style.marginTop = '';
          elementReference.current.style.marginBottom = '10px';
          elementReference.current.style.marginTop = '10px';
          elementReference_Right_Sidebar[0] = elementReference.current;
          elementReference_Right_Sidebar[1] = elementNumber;
        }
      }
      selectedBlock[1] = -1;
      //setupdateComponent([0]);
    }
  }


  let sidebarBlock = <div className='SidebarBlock' 
  style={{backgroundColor: data.RENK}}
  onClick={function(){
    console.log('elementReference.current', elementReference.current);
    console.log('elementReference.current', selectedBlock);

    if (elementReference.current != 0 && elementReference.current != undefined && elementReference.current != '') {
      if (elementReference_Right_Sidebar[0] == 0) {
        elementReference.current.style.marginBottom = '10px';
        elementReference.current.style.marginTop = '10px';
        elementReference_Right_Sidebar[0] = elementReference.current;
        elementReference_Right_Sidebar[1] = elementNumber;
        selectedBlock[0] = elementNumber;
      } else {
        if (elementReference_Right_Sidebar[1] == elementNumber) {
          elementReference.current.style.marginBottom = '';
          elementReference.current.style.marginTop = '';
          elementReference_Right_Sidebar[0] = 0;
          elementReference_Right_Sidebar[1] = -1;
        } else {
          elementReference_Right_Sidebar[0].style.marginBottom = '';
          elementReference_Right_Sidebar[0].style.marginTop = '';
          elementReference.current.style.marginBottom = '10px';
          elementReference.current.style.marginTop = '10px';
          elementReference_Right_Sidebar[0] = elementReference.current;
          elementReference_Right_Sidebar[1] = elementNumber;
          selectedBlock[0] = elementNumber;
        }
      }
      //selectedBlock[0] = elementNumber;
    }
    //setupdateComponent([0]);
    MapWrappingUpdate([0]);



    /*
    if (elementReference.current != 0 && elementReference.current != undefined && elementReference.current != '') {
      if (elementReference.current.style.marginBottom == '' && elementReference.current.style.marginTop == '') {
        elementReference.current.style.marginBottom = '10px';
        elementReference.current.style.marginTop = '10px';
      } else {
        elementReference.current.style.marginBottom = '';
        elementReference.current.style.marginTop = '';
      }
    }
    */
    /*
    if (elementReference.current.style.marginBottom == '' && elementReference.current.style.marginTop == '') {
      elementReference.current.style.marginBottom = '10px';
      elementReference.current.style.marginTop = '10px';
    } else {
      elementReference.current.style.marginBottom = '';
      elementReference.current.style.marginTop = '';
    }
    */
  }}
  > 
    <div className='SidebarBlock_left'>
      <div className='SidebarBlock_left_level2_number1'>
        {data.PLAKA} - {data.KILOMETRE}
      </div>
      <div className='SidebarBlock_left_level2_number2'>
        <img src={arrowTangle} className='arrowTanglePng' style={{transform: `rotate(${data.YON}deg)`}}/> 
        <div>{data.HIZ}</div>
      </div>
      <div className='SidebarBlock_left_level2_number3'>
        {data.SON_MESAJ_TARIHI}
      </div>
    </div>
    <div className='SidebarBlock_right'>
      <div className='SidebarBlock_right_level2_number1'>
        <div className='sidebarBlock_drive'>Sürüş</div>
        <div className='sidebarBlock_time1'>00:00</div>
        <div className='sidebarBlock_pause'>Mola</div>
        <div className='sidebarBlock_time2'>00:00</div>
      </div>
      <div className='SidebarBlock_right_level2_number2'>
        
        <div className='progressBar_SidebarBlock1'
        style={{backgroundImage: `linear-gradient(to right, #0dcaf0 0% 12%, #e9ecef 12% 100%)`}}
        >12%</div>
        <img src={repair} className='repairPng'/> 

        <div className='progressBar_SidebarBlock2'
        style={{backgroundImage: `linear-gradient(to right, #0dcaf0 0% ${data.DEPO_SEVIYE}%, #e9ecef ${data.DEPO_SEVIYE}% 100%)`}}
        >{data.DEPO_SEVIYE}%</div>
        <img src={fuel} className='fuelPng'/> 
      
      </div>
    </div>

  </div>;
  
  if (elementReference.current == 0 || elementReference.current == undefined || elementReference.current == '') {
    queueMicrotask(function() {
      //console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
      elementReference.current = document.getElementsByClassName('SidebarBlock')[elementNumber];

      if (selectedBlock[1] == elementNumber) {
        if (elementReference_Right_Sidebar[0] == 0) {
          elementReference.current.style.marginBottom = '10px';
          elementReference.current.style.marginTop = '10px';
          elementReference_Right_Sidebar[0] = elementReference.current;
          elementReference_Right_Sidebar[1] = elementNumber;
        } else {
          if (elementReference_Right_Sidebar[1] == elementNumber) {
            elementReference.current.style.marginBottom = '';
            elementReference.current.style.marginTop = '';
            elementReference_Right_Sidebar[0] = 0;
            elementReference_Right_Sidebar[1] = -1;
          } else {
            elementReference_Right_Sidebar[0].style.marginBottom = '';
            elementReference_Right_Sidebar[0].style.marginTop = '';
            elementReference.current.style.marginBottom = '10px';
            elementReference.current.style.marginTop = '10px';
            elementReference_Right_Sidebar[0] = elementReference.current;
            elementReference_Right_Sidebar[1] = elementNumber;
          }
        }
        selectedBlock[1] = -1;
        //setupdateComponent([0]);
      }
    });
  }

  return <>
    {sidebarBlock}
  </>;
}
  
export default SideBarBlock;

/*
DEPO_SEVIYE индикатор топлива 



  <div className='SidebarBlock_right_level2_number2'>
    <div className='progressBar_SidebarBlock'
    style={{backgroundImage: `linear-gradient(to right, #0dcaf0 0% 12%, #e9ecef 12% 100%)`}}
    >12%</div>
    <img src={repair} className='repairPng'/> 
  </div>
  <div className='SidebarBlock_right_level2_number3'>
    <div className='progressBar_SidebarBlock'
    style={{backgroundImage: `linear-gradient(to right, #0dcaf0 0% ${data.DEPO_SEVIYE}%, #e9ecef ${data.DEPO_SEVIYE}% 100%)`}}
    >{data.DEPO_SEVIYE}%</div>
    <img src={fuel} className='fuelPng'/> 
  </div>
*/

/*
Не удается при клике на блок отрегулировать его параметры. 
функция может примениться к любым элементам внутри блока, а не только к нему. 
Как написать функцию так, чтобы она применялась только к самому блоку? 
Функция должна поочередно сработать на каждом уровне. 
Нужно сделать такое сравнение, чтобы она могла сработать только с этим элементом.
Событие конечно передастся, но вот функция выполнится всего один раз. 
Простых способов не существует. 
Нужно искать в dom дереве все элементы с нужным классом и выьирать по порядковому номеру. 
Чтобы не приходилось делать поиск при каждом обновлении, запишем ссылку на данный элемент в ref.
*/

/*
Теперь каждый блок при нажатии на него будет изменять свои размеры. 
Но как сделать так, чтобы в одно время был выделен только один блок?
Здесь нужно состояние. Состояние должно быть в родительском компоненте. 

В родительсеом компоненте есть стейт со ссылкой на dom элемент. Этот элемент перелается во все 
дочерние компоненты. При нажатии на любой блок происходит проверка и если блок не сответствует 
данному dom элементу, то этот элемент теряет отступы, а элемент, сооответствующий данному блоку, 
получает отступы. 
Как проверить на равенство? Можно передавать в стейт не только ссылку на элемент, но и строку 
с номером автомобиля. Или же индекс элемента. 

Как взаимодействовать со ссылкой из родительского элемента?
В обычном состоянии нужно взять номер под индексом 1 и сравнить его с 
индексом данного элемента. 

Если они совпадают, то в элемент под индексом 0 
записываем 0, в элемент под индексом 1 записываем 0. В данном элементе убираем верхний и нижний отступы.

Если они не совпадают, то нужно в элементе под индексом 0 убрать верхний и нижний отступы, 
потом в данном элементе добавить верхний и нижний отступы, потом записать данный элемент в массив 
под индексом 0, а его индекс под индексом 1.

Если в элементе родительского массива под индексом 0 стоит 0, то в dom элементе добавить верхний и 
нижний отступы, потом записать данный элемент в массив 
под индексом 0, а его индекс под индексом 1.

1. Навешиваем на двойной клик по стрелке обновление всех компонентов и устанавливаем индекс стрелки.
2. Если elementNumber == индексу стрелки, то выделяем блок

Блок выделяется, а форма нет. 
Почему?

В пропсы формы передается флаг. 
Если под индексом 0 в elementReference_Right_Sidebar есть ссылка на элемент, то 
форма откроется. 
Решение наивное: нужно 2 обновления стейта.
Это работает, но можно ли как то по другому? 
Увы, но пока ссылка не будет передана в Right_Sidebar
*/
