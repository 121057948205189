function diagramOne (arr1, arrOfColor1) {
  let variableArr1 = [];
  for (let j = 0; j < arr1.length; j++) {
    variableArr1.push({value: arr1[j].data[0], color: arrOfColor1[j], nameColumn: arr1[j].name});
  }
  return variableArr1;
}

function diagramMultiple (obj1, arrOfColor1) {
  //arr[i].DASHBOARS_DATA_GRAFIK
  let variableObj1 = {};
  variableObj1.nameColumnGroup = obj1.categories2;
  variableObj1.signature;
  variableObj1.data = [];

  variableObj1.signature = obj1.series.map(function (item, index) {return {color: arrOfColor1[index], nameColumn: item.name}});
  console.log(obj1.text);
  for (let j = 0; j < obj1.series[0].data.length; j++) {
    let variableArr2 = [];
    for (let k = 0; k < obj1.series.length; k++) {
      variableArr2.push({value: obj1.series[k].data[j], color: arrOfColor1[k], nameColumn: obj1.series[k].name});
    }
    variableObj1.data.push(variableArr2);
  }
  return variableObj1;
}

function diagramLine (obj1, arrOfColor1) {
  //arr[i].DASHBOARS_DATA_GRAFIK
  let variableArr1 = [];
  variableArr1[0] = arrOfColor1;
  variableArr1[obj1.series.length + 2] = obj1.categories2;
  variableArr1[obj1.series.length + 1] = [];
  for (let j = 0; j < obj1.series.length; j++) {
    variableArr1[j + 1] = obj1.series[j].data;
    variableArr1[obj1.series.length + 1].push(obj1.series[j].name);
  }
  return variableArr1;
} 

function transformationDiagrammData (arr, arrOfColor) {
  let variableArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].DASHBOARS_TURU == "DASHBOARD_BAR" && arr[i].DASHBOARS_DATA_GRAFIK.categories2.length < 2) {
      variableArr.push({diagrammType: 'one', diagrammPoints: diagramOne (arr[i].DASHBOARS_DATA_GRAFIK.series, arrOfColor), maxY: Math.max.apply(null, arr[i].DASHBOARS_DATA_GRAFIK.series.map(item => item.data))});
    } else if (arr[i].DASHBOARS_TURU == "DASHBOARD_BAR" && arr[i].DASHBOARS_DATA_GRAFIK.categories2.length >= 2) {
      variableArr.push({diagrammType: 'multiple', diagrammPoints: diagramMultiple (arr[i].DASHBOARS_DATA_GRAFIK, arrOfColor), maxY: Math.max.apply(null, arr[i].DASHBOARS_DATA_GRAFIK.series.map(item => Math.max.apply(null, item.data)))});
    } else if (arr[i].DASHBOARS_TURU == "DASHBOARD_LINE_1") {
      variableArr.push({diagrammType: 'line', diagrammPoints: diagramLine (arr[i].DASHBOARS_DATA_GRAFIK, arrOfColor), maxY: Math.max.apply(null, arr[i].DASHBOARS_DATA_GRAFIK.series.map(item => Math.max.apply(null, item.data)))});
    }
  }
  return variableArr;
}


export default transformationDiagrammData;
