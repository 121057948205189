import './Table.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Table from './Table';
import StoreRequest from './classStoreRequest';
import TableStack from './classTableStack';
import ChangeRepository from './classChangeRepository';
import ButtonsComponent from './ButtonsComponent.js';

import adress from '../../adress.js'

const svgLoader = <><svg className='loader' viewBox="0 0 32 32">
<path d="M16 0c-8.711 0-15.796 6.961-15.995 15.624 0.185-7.558 5.932-13.624 12.995-13.624 7.18 0 13 6.268 13 14 0 1.657 1.343 3 3 3s3-1.343 3-3c0-8.837-7.163-16-16-16zM16 32c8.711 0 15.796-6.961 15.995-15.624-0.185 7.558-5.932 13.624-12.995 13.624-7.18 0-13-6.268-13-14 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 8.837 7.163 16 16 16z"></path>
</svg>
<div className='svgLoaderStub'></div>
</>;


function PreTablePage({id, setChangeStateOfPreTablePageWrapping}) {

const token = useSelector((state) => state.tokenOfSession.token);
const [datapreTablePage, setdatapreTablePage] = useState(0);
const [flagTable, setflagTable] = useState([0]);

/* здесь будет код для создания ошибки в react
useEffect(() => setdatapreTablePage([0]), [datapreTablePage]);
*/

if (StoreRequest.store[id] == 'generalUpdate' && datapreTablePage !== 0) {
  console.log('general update');
  setdatapreTablePage(0);
}

let columnsTable = 0;
let tableComponent;
console.log('datapreTablePage == 0 && StoreRequest.store[id] == generalUpdate', datapreTablePage);
if (datapreTablePage == 0 && (StoreRequest.store[id] == undefined || datapreTablePage == 0 && StoreRequest.store[id] == 0 || 
  datapreTablePage == 0 && StoreRequest.store[id] == 'generalUpdate')) {
  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetForm2/?sessionId=${token}&masterFormId=${id}&masterFormKayitId=0&formId=${id}&sorguSTR=null&kayitSayisi=-1`)
  .then(response => response.json())
  .then(function (json) {
    console.log('pretable json', json);
    //console.log('replace', json.GetForm2Result.DATA.replace(/(?<=\d)[TZ]/g, ''));
    let dataFetch = JSON.parse(json.GetForm2Result.DATA.replace(/(?<=\d)[TZ]/g, ' '));
    console.log('pretable dataFetch', dataFetch);
    
    if ('RENK' in dataFetch?.[0]) {
      console.log('pretable dataFetch?.[0].RENK', dataFetch?.[0].RENK);
      StoreRequest.colorArray[id] = {};
      for (let elem of dataFetch) {
        
        StoreRequest.colorArray[id][elem.ID] = (elem.RENK);
        delete elem.RENK;
      }
    }
    console.log('preTable dataFetch StoreRequest.colorArray[id]', StoreRequest.colorArray[id]);

    StoreRequest.store[id] = dataFetch;
    StoreRequest.altForm[id] = json.GetForm2Result.ALT_FORM;
    StoreRequest.buttonsForm[id] = {
      SIL: json.GetForm2Result.SIL,
      GUNCELLE: json.GetForm2Result.GUNCELLE,
      YENI_KAYIT: json.GetForm2Result.YENI_KAYIT,
    };
    setdatapreTablePage(id);
    

    console.log('fetch', dataFetch);
  });
} else if (datapreTablePage == 0 && (StoreRequest.store[id] != undefined && StoreRequest.store[id] != 0)) {
  setdatapreTablePage(id);
}

console.log('id', id);
if (StoreRequest.store[id] != undefined && StoreRequest.store[id] != 0) {  
  columnsTable = Object.keys(StoreRequest.store[id][0]);
  tableComponent = <Table data={StoreRequest.store[id]} columnsTable={columnsTable} altForm={StoreRequest.altForm[id]} id={id} page={'PreTablePage'} setflagTable={setflagTable} setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping}/>;
}
console.log('PreTable StoreRequest.store[id]', StoreRequest.store[id]);
let plateNoData;
if (!Array.isArray(StoreRequest.store[id])) {
  plateNoData = svgLoader;
} else if (Array.isArray(StoreRequest.store[id]) && StoreRequest.store[id].length == 0) {
  plateNoData = <div className='noDataTable'> Bilgi bulunmamaktadır </div>;
}
  return <>
    <div className='PreTablePageConteiner'> 
    
    <ButtonsComponent 
    id={id} 
    token={token} 
    setdatapreTablePage={setdatapreTablePage}
    serverIdForLine={0}  
    setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping}
    />

    {columnsTable != 0 && tableComponent}
    {plateNoData}
    
    </div>
  </>;
}
 
export default PreTablePage;


//.replace(/[(?<=\d)T(?<=\d)Z]/g, ' ')
//YENI_KAYIT