import { useState, useRef, useSyncExternalStore } from 'react';
import { useSelector } from 'react-redux';


function setForms (data, linkDataArray, heightOfFields) {
  let objVariable = {};
  for (let i = 0; i < data.BILGI.length; i++) {
    if (objVariable[data.BILGI[i].KATEGORI] == undefined) {
      objVariable[data.BILGI[i].KATEGORI] = [];
    }
  }

  for (let i = 0; i < data.BILGI.length; i++) {
    objVariable[data.BILGI[i].KATEGORI].push(data.BILGI[i]);
  }

  console.log('SideBarForm setForms objVariable', objVariable);

  let arrVariable = [];

  for (let key in objVariable) {
    arrVariable.push(<div className='groupNameOfTheSideBarForm'
    onClick={function(event) {

      console.log(event);
      console.log(event.target.nextSibling.clientHeight);
      if (event.target.nextSibling.style.height == '' || event.target.nextSibling.style.height == 'auto' || event.target.nextSibling.clientHeight == heightOfFields[key]) {
        if (heightOfFields[key] == undefined) {
          event.target.nextSibling.style.height = `${event.target.nextSibling.clientHeight}px`;
          heightOfFields[key] = event.target.nextSibling.clientHeight;
        }
        console.log('SideBarForm setForms event.target.nextSibling.style.height', event.target.nextSibling.style.height);
        event.target.nextSibling.style.height = '0px';
      } else if (event.target.nextSibling.style.height = '0px') {
        event.target.nextSibling.style.height = `${heightOfFields[key]}px`;
      }

    }}
    >{key} - {linkDataArray[0]} </div>);

    let arrVariable1 = [];
    for (let i = 0; i < objVariable[key].length; i++) {
      arrVariable1.push(<div className='fildOfTheSideBarForm'>{objVariable[key][i].ADI} </div>); 
    }
    arrVariable.push(<div className='conteinerOfTheSideBarForm'>{arrVariable1} </div>);

  }
  /*
  let arrVariable = data.map(function(item, index) {
    return <div className='fildOfTheSideBarForm'>{item.ADI} </div>;
  });
  */

  return arrVariable;
}

function SideBarForm({flag, index, data, flag_elementReference}) {
  const heightOfFields = useRef({});

  
  if (index == -1) {
    index = 0;
  }
  
  if (flag_elementReference) {
    flag = false;
  }

  
  let linkDataArray = [data.GUNCEL_TAKIP[index].PLAKA];

  console.log('SideBarForm index', index, linkDataArray);




  let sideBarForm = <div className='SideBarForm'
  style={{right: `${flag ? '300px' : '-100px'}`}}
  > 
  {
    setForms(data, linkDataArray, heightOfFields.current)
    

  }
  </div>;
  


  return <>
    {sideBarForm}
  </>;
}
  
export default SideBarForm;


/*
Куда нужно вставить этот сайдбар?
Явно не в right_sidebar. 
Значит вставлять нужно в MapWrapping. 

Нужно иметь связь с right_sidebar, чтобы выводить информацию в форму. 
Как эту связь сделать? 
Что лучше передать? 
Может ref? 
Или state? 
Ref кажется хорошим вариантом 
А state может менять состояние компонента 
Нужно ли обновлять состояние компонента? В каких случаях это может быть нужно?
Например в случае изменения данных. 
Однако в таком случае проще просто обновить все компоненты.

С другой стороны нет необходимости выводить компонент в MapWrapping, можно вывести и в right_sidebar
В таком случае нет проблем получить досту к данным. 

*/

/*
Как обновлять компонент? 
В случае изменения выделенного компонента не произойдет перерендеринга. 
Как обновить форму, если будет выбран другой компонент, или выделение будет снято? 
Можно использовать хук external, чтобы отслеживать состояние ref.
*/

/*
Нужно сделать список с полями. Эти поля не надо заполнять, но возможно 
их придется закрывать/открывать. 
Сделать поля не так сложно, а вот закрытие и открытие, а также постоянная и ненужная перерисовка 
для компонентов создает сложности. 
Как избежать лишних перерисовок? 
Можно сделать каждую строку в виде компонента и использовать useMemo
Можно не обновлять компоненты и просто вставлять значения, количество и название полей одинаково 
для каждого блока. 
Можно сделать разные компоненты и ссылки на dom для них. 

Нет ни одного хорошего решения для решения данной проблемы. 
Допустим поля юудут сделаны в виде отдельных компонентов, но все они все равно будут 
обновляться слишком часто. 

Стоит ли использовать useMemo? В таком случае пересчет будет происходить только при изменении 
пропсов, то есть всегда. Все изменения заключаются в установке или удаления выделения. 

В принципе, при монтировании компонента в ref можно записать все поля, а значения для этих полей 
сделать ссылочными значениями. В таком случае можно сделать другую переменную со ссылкой на эти же 
значения и при обновлении будут выводиться неизменные формы, а значения будут меняться. 
Не слишком ли сложно? Насколько это оправдано? Вель скорее всего dom изменится и вставит в себя 
совершенно новый html элемент! 

Даже есл решеть задачу в лоб, то при каждом изменении выделенного элемента будут 
происходить перерисовки. 

Тогда как сделать закрытие и открытие контейнеров? Думаю, что пока лучше все просто сделать 
через auto.

Как распределить все строки по группам? 
Сначала их надо найти. 
Потом нужно сделать отдельно строку для названия групы и контейнер дл группы. 
Потом нужно вставить в контейнер все элементы. 
Как это сделать без лишних вычислений? 
Нужно в начале собрать html, а потом 


    data.map(function(item, index) {
      return <div className='fildOfTheSideBarForm'>{item.ADI} </div>;
    })


*/
