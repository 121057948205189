import { useRef, useState, useEffect} from 'react';
import './FormTable.css';

import AirDatepicker from 'air-datepicker';
import localeTr from 'air-datepicker/locale/tr';
import 'air-datepicker/air-datepicker.css';

import FormTableRepository from '../Components/tableComponent/classFormTableRepository';

function selectionDate (propTimepicker, name) {
  let selectedDateTimeVariable;
  if (!propTimepicker) {
  //selectedDateTime = FormTableRepository.formInputsState[name].split('.').reverse().join('-');
    let v = FormTableRepository.formInputsState[name];
    selectedDateTimeVariable = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1];
  } else {
    let v = FormTableRepository.formInputsState[name];
    console.log('calendar selectionDate v', v);
    selectedDateTimeVariable = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1] + ' ' + v[11] + v[12] + v[13] + v[14] + v[15];
  }
  //console.log('calendar selectionDate selectedDateTimeVariable', selectedDateTimeVariable);
  return selectedDateTimeVariable;
}

function Calendar({name, state, setState, index, repository, placeholderData, defaultValue, propTimepicker, statusOpen}) {

const [flag, setflag] = useState(false);
const inputDate = useRef();
const refDate = useRef();
const currentDate = useRef(FormTableRepository.formInputsState[name]);



let rangeVar = true;
if(repository == 'FormTable') {
  rangeVar = false;
}

let selectedDateTime;
if (refDate.current == undefined) {
  selectedDateTime = selectionDate (propTimepicker, name);
  console.log('calendar selectionDate refDate.current == undefined');

}
/*
if (!propTimepicker) {
  //selectedDateTime = FormTableRepository.formInputsState[name].split('.').reverse().join('-');
  let v = FormTableRepository.formInputsState[name];
  selectedDateTime = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1];
} else {
  let v = FormTableRepository.formInputsState[name];
  selectedDateTime = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1] + ' ' + v[11] + v[12] + v[13] + v[14] + v[15];
}
*/

useEffect(() => {
  refDate.current = new AirDatepicker(inputDate.current, {
    locale: localeTr,
    timeFormat: 'HH:mm',
    range: rangeVar,
    multipleDatesSeparator: ' - ',
    timepicker: propTimepicker,
    firstDay: 0,
    showEvent: "focus",
    buttons: 'clear',
    isMobile: true,
    selectedDates: [selectedDateTime],
    onSelect: function ({date, formattedDate, datepicker}) {
      console.log('calendar onSelect');
      if (repository == 'FormTable') {
        if(formattedDate !== undefined) {
          FormTableRepository.formInputsState[name] = formattedDate;
        } else {
          FormTableRepository.formInputsState[name] = 0;
        }
        
        setState([true]);// не запускается
        /*
        Проблема была в том, что setState не запускался, т.к. в него всегда ложилось
        значение равное предыдущему. Теперь в него передается ссылка, и теперь
        стейт будет запускаться всегда
        */

      } else if (repository == 'StoreCalendar') {
        setflag(!flag);
        setState([...(FormTableRepository.state).slice(0, index + 1), formattedDate, ...FormTableRepository.state.slice(index + 2)]);
      }
      /*
      if (formattedDate.length == 0) {
        setflag(false);
      } else {
        setflag(true);
      }
      */
    },
  });
}, []);

let inputDateTag = <div ref={inputDate} className={`${(FormTableRepository.formInputsState[name] === undefined || FormTableRepository.formInputsState[name] === 0 || FormTableRepository.formInputsState[name] === '') ? 'inactiveinput' : 'activeinput'} calendar${repository}`} style={{height: statusOpen ? 'auto' : '0px', overflow: 'hidden' }}></div>;

/*
console.log('calendar inputDate', inputDate);
if (inputDate.current?.value != undefined) {
  inputDate.current.value = '2021-07-25';
}
*/
if (refDate.current != undefined) {
  console.log('calendar refDate', refDate);
  if (currentDate.current != FormTableRepository.formInputsState[name]) {
    let variableSelectionDate = selectionDate (propTimepicker, name);
    console.log('calendar selectionDate refDate.current != undefined', selectionDate (propTimepicker, name));
    //refDate.current.selectDate(selectionDate (propTimepicker, name), true);
    //refDate.current.selectDate('2021-07-25 11:30', true);
    let oldYear = currentDate.current[6] + currentDate.current[7] + currentDate.current[8] + currentDate.current[9];
    let newYear = FormTableRepository.formInputsState[name][6] + FormTableRepository.formInputsState[name][7] + FormTableRepository.formInputsState[name][8] + FormTableRepository.formInputsState[name][9];
    if(oldYear != newYear) {
      refDate.current.setViewDate(variableSelectionDate);
      console.log('calendar selectionDate currentDate.current', oldYear, newYear);
    }

    refDate.current.selectDate(variableSelectionDate, {updateTime: true, silent: true});
    console.log('calendar selectionDate refDate.current != undefined');

    currentDate.current = FormTableRepository.formInputsState[name];
  }
}

//let inputDateTag = <input ref={inputDate} placeholder={`${name}`} className={`${flag == false ? 'inactiveinput' : 'activeinput'}${repository}`} onBlur={() => console.log('!!!')} readOnly/>;
//inputDateTag = <input ref={inputDate} placeholder={`${placeholderData}`} className={`${(FormTableRepository.formInputsState[name] === undefined || FormTableRepository.formInputsState[name] === 0 || FormTableRepository.formInputsState[name] === '') ? 'inactiveinput' : 'activeinput'}${repository}`} value={defaultValue != 0 ? defaultValue : ''} readOnly />;


  return (
    inputDateTag

  );
}

export default Calendar;


/*
Если указать просто дату, то функция selectionDate выполнится только 1 раз, а если изменения 
произошли во времени, то выполнится 2 раза. Почему?

Возможно при изменении времени выпзывается событие onSelect. Тогда функция может изменить 
данные в хранилище на предыдущий результат. 
Но почему функция не вызывается при изменении даты?
Оказывается вторым параметром в функцию selectDate нужно ложить объект со свойствами updateTime: true, 
silent: true.

*/
