import ChangeRepository from './classChangeRepository';
import TableStack from './classTableStack';
import FormTableRepository from './classFormTableRepository';
import StoreTables from './classStoreTables';



function cleaningStorageOfTables () {
    TableStack.childListArr.length = 0;
    ChangeRepository.add = [];
    FormTableRepository.formInputsState = {};
    StoreTables.tables = {};
}


export default cleaningStorageOfTables;
