class StoreSearchEngine {
  static menuList = [];
  static flagOfCarRequest = false;
  static wait_flagOfCarRequest = false;
  static flagOfMap = 0;
  static indexOfCar = -1;

}

export default StoreSearchEngine;

/*
1. menu1
2. headerDropDownList
3. MapWrapping
4. Right_Sidebar

{name: item.GORUNEN_ADI, ID: item.ID}
*/

/*
Задача: нужно делать поисковик по сайту. При введении данных снизу от поля ввода должен выпадать список 
с возможными вариантами выбора. Важное уточнение, нужно иметь возможность выбрать не только пункт 
меню, но и машину на карте. Поле ввода должно быть нечувствителльно к регистру символов, а также 
должен быть экранирован спецсимвол "|". 

Решение: Сделать поисковик для пунктов меню конечно несложно, однако как сделать список всех доступных 
машин, если этот список будет доступен только после запроса после открытия страницы? Можно сделать 
специальный запрос после получения данных от меню. 

Меню сделано, однако почти не работает. При выборе пункта из списка выполняются те же действия, что 
и при выборе пункта из меню, однако запроса на сервер не происходит и срабатывает 
только если страница была открыта ранее. 
1. Нужно найти причину, почему не выполняется запрос на сервер. 
2. Теперь нужно найти причину по которой вкладки появляются второй раз. 

Проблема была в том, что индекс должен был быть в формате строки, а был числом. 

3. При первой загрузке сайта, header начинает мерцать, его высота постоянно изменяется. 
Такое поведение не набл/дается в старой версии. 
Пока такое поведение прекратилось. 
Возможно это был конфликт высот у разных элементов header. Удаление фиксированной высоты 
у контейнера с выпадающим списком кажется помогло. 


Теперь надо иметь возможность искать машину на странице карты. 
Нужно делать запрос на сервер, чтобы получить номера всех доступных для пользователя машин. 
А что делать потом?

Теперь нужно проверить наличие в хранилище страницы с картой, чтобы решить делать ли вообще запрос, 
а также найти и вынести в отдельную переменную реквизиты этой страницы, чтобы можно было ее 
открывать при нажатии на пункты с машинами. 
Однако есть проблема, при открытии компонента headerDropDownList хранилище может быть еще пустым. 
Это создает проблему, учитывая, что запрос на сервер ограничен, и в таком случае его просто не произойдет. 

Как эту проблему вообще можно решить?
Проблема решена добавлением проверки на длину массива в хранилище. 

Нужно сделать так, чтобы данные из поисковика в хедере добавлялись в поисковик в сайдбаре в карте. 
Это сделать несложно, однако это не реализует всего функционала. 

Что нужно для блока в сайдбаре? 
1. ссылка на сам блок, это переменная elementReference в компоненте SideBarBlock.
2. elementReference_Right_Sidebar. Это ссылка на выбраный блок. 
3. selectedBlock. В нем хранится индекс нужного блока. Для самого блока используется 
   элемент под индексом 1. 

Для 1 и 2 элемента кажется не нужны какие то значения, кажется, что они могут быть обработаны и без 
ссылок на блок. Однако это не так. Может это из за того, что ссылки не найдены? Может 
нужно сначала открыть компонент 1 раз, а потом его обновить? 

*/
