import './WeHelp.css';
import * as d3 from "d3";
import { useState, useSyncExternalStore } from 'react';

function WeHelpDiagram({data, maxY}) {
console.log('WeHelpDiagram data', data, maxY)
/*
const lengthAxisY = 500;//1/3 widthSvg
const widthSvg = 1600;
const rectWidth = 95;// 1/17 widthSvg
const columnGap = 55;// 1/29 widthSvg
*/
//const screenWidth = window.screen.width;

//const [updateComponent, setUpdateComponent] = useState([0]);
/*
window.onresize = function (event) {
  console.log('WeHelpDiagram1 resize', window.innerWidth);
  setUpdateComponent[0];
}
*/


const screenWidth = useSyncExternalStore(function(onChange){
  window.addEventListener("resize", onChange);

  return () => window.removeEventListener("resize", onChange);
}, 
function () {
  console.log('WeHelpDiagram1 useSyncExternalStore работает');
  //setUpdateComponent[0];
  return window.innerWidth;
} );
//console.log('WeHelpDiagram1 variable', variable);

//const screenWidth = window.innerWidth;
console.log('WeHelpDiagram1 width', window.innerWidth, screenWidth);


/*
useSyncExternalStore неприятный хук, слишком сложный, 
непонятная магия с коллбэком onChange,откуда он взялся, что в него кладется?
нет никакой необходимости что то возвращать, записывать и т.д., 
потому что единственное достоинство данного хука - навешивание 
события и перерисовка компонента после срабатывания события.
*/

console.log('WeHelpDiagram screenWidth', screenWidth);
const widthSvg = screenWidth/100 * 80;
const lengthAxisY = Math.round(widthSvg/3);//1/3 widthSvg
const rectWidth = Math.round(widthSvg/12);// 1/17 widthSvg
const columnGap = Math.round(widthSvg/29);// 1/29 widthSvg
const marginLeft = (widthSvg - rectWidth * data.length - (columnGap * (data.length - 1)) - columnGap) / 2;

const [heightY, setheightY] = useState(0);
if (heightY == 0) {
  setheightY(maxY + 9);
}

const getYRect = d3.scaleLinear()
  .domain([0, heightY])
  .range([lengthAxisY,0]);

const getYAxis = ref => {
  const yAxis = d3.axisLeft(getYRect)
    .tickSize(-widthSvg) // ширина горизонтальных линий на графике
  d3.select(ref).call(yAxis);
};

const buttonZoomPlus = <div className='buttonZoomPlus' onClick={function () {
  if (heightY > Math.floor(maxY / 7)) {
    setheightY(heightY - Math.floor(maxY / 7))
  }
}}>+</div>;
const buttonZoomMinus = <div className='buttonZoomMinus' onClick={function () {
  if (heightY < maxY) {
    setheightY(heightY + Math.floor(maxY / 7))
  }
}}>&mdash;</div>;

const svg = <svg width={widthSvg} height={lengthAxisY} style={{borderRight: '1px solid #b3b3b3'}}>
<g className='YAxis' ref={getYAxis} strokeWidth='0.3'/>
{
  data.map( function (item, index) {
    return <> 
    <circle className="circleRect"
      key={index}
      cx={`${marginLeft + (index * columnGap) + ((index + 1) * rectWidth) - rectWidth/2}`}
      cy={lengthAxisY + 20}
      r={10}//1/160 widthSvg
      fill= {`${item.color}`}
    />

    <rect className={`rect${index}`} fill= {`${item.color}`} 
    x={`${(index * rectWidth) + (index * columnGap) + marginLeft}`} y={getYRect(item.value)} 
    width={rectWidth} height={lengthAxisY - getYRect(item.value)} 
    onClick={event => console.log(event.target.className)}></rect>
    
    <text
    fill="black"
    x={`${marginLeft + (index * columnGap) + ((index + 1) * rectWidth) - rectWidth/2}`}
    y={getYRect(item.value) - 8}
    textAnchor="middle">
    {item.value}
    </text>

    <text
    className='columnText'
    fill="black"
    x={`${marginLeft + (index * columnGap) + ((index + 1) * rectWidth) - rectWidth/2}`}
    y={lengthAxisY + 45}
    textAnchor="middle">
    {item.nameColumn}
    </text>
    </>

  })
}

</svg>;

return <>
  {svg}
  {buttonZoomPlus}
  {buttonZoomMinus}
</>;
}

export default WeHelpDiagram;

//const marginLeft = (widthSvg - ((rectWidth + columnGap) * data.length)) / 2;

//      cx={`${60 + (index * rectWidth) + (index * columnGap) + marginLeft}`}

// rect   x={`${20 + (index * rectWidth) + (index * columnGap) + marginLeft}`} y={getYRect(item.value)} 


// text2     x={`${60 + (index * (rectWidth)) + (index * columnGap) + marginLeft}`}

/*
Задача: после любого изменения размера экрана, размер графика должен подстроиться под него. 
Решение: в window.onresize можно записать функцию, которая будет выполняться при любом изменении экрана. 
Недостаток в том, что по какой то причине не выполняется функция изменения стейта. 
Возможно потому что это 
*/
