
import { useMemo, useEffect} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box } from '@mui/material';





function MapTable ({data, columnsTable, id}) {

useEffect(() => {
  queueMicrotask(function() {
    let app = document.getElementsByClassName('MuiBox-root')[0]; 
    app.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  });
}, []);

let tableDataVariable = columnsTable.map(function (item) {
  return {
    accessorKey: item, //access nested data with dot notation
    header: item,
  }
});

const columns = useMemo(
  () => tableDataVariable,
  [tableDataVariable],
);
  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization: MRT_Localization_TR,
    enableDensityToggle: false,
    initialState: { density: 'comfortable' },
  });

  return <>
  <Box
  sx={{
    width: '100vw',
    position: 'absolute',
    marginTop: '-2vh',
    zIndex: 3000,
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}`} >
    <MaterialReactTable table={table} />
  </Box>
  </>
};

export default MapTable;


