import './Table.css';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Table from './Table';
import StoreRequest from './classStoreRequest';
import TableStack from './classTableStack';
import ChangeRepository from './classChangeRepository';
import ButtonsComponent from './ButtonsComponent.js';

import adress from '../../adress.js'


const svgLoaderPreTableChildPage = <><svg className='loaderPreTableChildPage' viewBox="0 0 32 32">
<path d="M16 0c-8.711 0-15.796 6.961-15.995 15.624 0.185-7.558 5.932-13.624 12.995-13.624 7.18 0 13 6.268 13 14 0 1.657 1.343 3 3 3s3-1.343 3-3c0-8.837-7.163-16-16-16zM16 32c8.711 0 15.796-6.961 15.995-15.624-0.185 7.558-5.932 13.624-12.995 13.624-7.18 0-13-6.268-13-14 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 8.837 7.163 16 16 16z"></path>
</svg>
<div className='svgLoaderStub'></div>
</>;

function arrayIndexСheck (arr, str) {
  console.log('PreTableChildPage arrayIndexСheck', arr, str);
  for (let i = 0; i < arr?.length; i++) {
    //console.log('PreTableChildPage typeof arr[i][0]', typeof arr[i][0]);
    console.log('PreTableChildPage arr[i][0]', arr[i]);
    if (arr[i][0] == str) {
      return i;
    }
  }
}

function creatingAltButtons (childId, numberTableRef, index, setChangeStateOfPreTablePageWrapping, setdatapreTablePage) {
  let arrVariable = [];
  for (let i = 0; i < StoreRequest.store[childId].length; i++) {
    console.log('PreTableChildPage creatingAltButtons цикл', i);
    arrVariable.push(
      <div className={`altButton ${numberTableRef.current == i && 'activeAltButton'}`} onClick={function () {
      //<div className={`altButton`} onClick={function () {  
        if (numberTableRef.current != i) {
          numberTableRef.current = i;
          console.log('PreTableChildPage index', index);
          TableStack.childListArr.length = index + 1;
          setdatapreTablePage(0);
          setChangeStateOfPreTablePageWrapping([0]);
          /*
          Здесь нельзя удалять функцию setChangeStateOfPreTablePageWrapping, иначе 
          не будут удаляться дочерние таблицы
          */
        }
      }}> { StoreRequest.store[childId][i]['FORM_ADI']} </div>
    );
  }
  console.log('PreTableChildPage creatingAltButtons запускается', arrVariable);
  return arrVariable;
}


function PreTableChildPage({childId, parentId, number, setChangeStateOfPreTablePageWrapping}) {

const token = useSelector((state) => state.tokenOfSession.token);
const [datapreTablePage, setdatapreTablePage] = useState(0);

let numberTableRef = useRef(0);



if (StoreRequest.store[childId] == 'generalUpdate' && datapreTablePage !== 0 || childId != datapreTablePage && datapreTablePage !== 0) {
  setdatapreTablePage(0);
}


console.log('PreTableChildPage childId', childId);
console.log('PreTableChildPage StoreRequest.store[childId]', StoreRequest.store[childId]);

let columnsTable = 0;
let tableComponent;
let storeFlag = StoreRequest.store[childId] != undefined;

console.log('PreTableChildPage comparingchildId', datapreTablePage == 0 && !storeFlag || datapreTablePage == 0 && StoreRequest.store[childId] == 0 || 
datapreTablePage == 0 && StoreRequest.store[childId] == 'generalUpdate');

if (datapreTablePage == 0 && !storeFlag || datapreTablePage == 0 && StoreRequest.store[childId] == 0 || 
  datapreTablePage == 0 && StoreRequest.store[childId] == 'generalUpdate') {
  if (ChangeRepository.add.count === undefined) {
  //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetAltFormlar/?sessionId=${token}&masterFormId=${parentId}&masterFormKayitId=${number}`)
  //.then(response => response.json())
  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetAltFormlar/?sessionId=${token}&masterFormId=${parentId}&masterFormKayitId=${number}`)
  .then(function (response) {
    console.log(response.ok);
    return response.json();
    })
  .then(function (json) {
    console.log('PreTableChildPage fetch json', json);
    StoreRequest.store[childId] = json.GetAltFormlarResult;
    for (let elem of StoreRequest.store[childId]) {
      elem.FORM_DATA = JSON.parse(elem.FORM_DATA.replace(/(?<=\d)[TZ]/g, ' '));
    } 
    console.log('PreTableChildPage fetch StoreRequest.store[childId]', StoreRequest.store[childId]);
    StoreRequest.altForm[childId] = [];
    StoreRequest.buttonsForm[childId] = [];

    for (let elem of json.GetAltFormlarResult) {
      StoreRequest.altForm[childId].push(elem.ALT_FORM);
      StoreRequest.buttonsForm[childId].push({
        SIL: elem.SIL,
        GUNCELLE: elem.GUNCELLE,
        YENI_KAYIT: elem.YENI_KAYIT
      })
    }

    console.log('PreTableChildPage StoreRequest.store[childId]', StoreRequest.store[childId]);
    console.log('PreTableChildPage StoreRequest.altForm[childId]', StoreRequest.altForm[childId]);
    console.log('PreTableChildPage StoreRequest.buttonsForm[childId]', StoreRequest.buttonsForm[childId]);
    console.log('PreTableChildPage fetch StoreRequest.store[childId]', StoreRequest.store[childId]);

    setdatapreTablePage(childId);
    /*
      Здесь проблема с присылвемыми данными
    */
  })
  .then(function () {
    //StoreRequest.store[childId]
    for (let i = 0; i < StoreRequest.store[childId].length; i++) {
      //StoreRequest.colorArray[StoreRequest.store[childId][i].FORM_ID] = {};
      for (let j = 0; j < StoreRequest.store[childId][i].FORM_DATA.length; j++) {
        //StoreRequest.store[childId][i].FORM_DATA[j][StoreRequest.store[childId][i].FORM_DATA[j]]
        //StoreRequest.colorArray[`${childId}_${i}`][StoreRequest.store[childId][i].FORM_DATA[j].ID] = StoreRequest.store[childId][i].FORM_DATA[j].RENK;
        console.log('PreTableChildPage fetch StoreRequest.store[childId][i].FORM_DATA[j].RENK', StoreRequest.store[childId][i].FORM_DATA[j].RENK);
        
        if (StoreRequest.store[childId][i].FORM_DATA[j].RENK !== undefined) {
          if (StoreRequest.colorArray[StoreRequest.store[childId][i].FORM_ID] == undefined) {
            StoreRequest.colorArray[StoreRequest.store[childId][i].FORM_ID] = {};
          }
          StoreRequest.colorArray[StoreRequest.store[childId][i].FORM_ID][StoreRequest.store[childId][i].FORM_DATA[j].ID] = StoreRequest.store[childId][i].FORM_DATA[j].RENK;
          delete StoreRequest.store[childId][i].FORM_DATA[j].RENK;
        }
        

      }
      console.log('PreTableChildPage fetch StoreRequest.colorArray', StoreRequest.colorArray);

    }

  });
  } 
} else if (datapreTablePage == 0 && (StoreRequest.store[childId] != undefined && StoreRequest.store[childId] != 0)) {
  setdatapreTablePage(childId);
}





let numberTable;
console.log('PreTableChildPage TableStack.childListArr', TableStack.childListArr, childId);
let index = arrayIndexСheck (TableStack.childListArr, childId);
let formChildTable;
if (storeFlag && StoreRequest.store[childId] != 0) {
  formChildTable = <div className='childTableForm'>
  {creatingAltButtons (childId, numberTableRef, index, setChangeStateOfPreTablePageWrapping, setdatapreTablePage)}

  </div>;

  numberTable = numberTableRef.current;
  if(StoreRequest.store[childId][numberTable] !== undefined && StoreRequest.store[childId][numberTable]['FORM_DATA'] != null && StoreRequest.store[childId][numberTable]['FORM_DATA'][0] != null) {
    columnsTable = Object.keys(StoreRequest.store[childId][numberTable]['FORM_DATA'][0]);
    tableComponent = <Table data={StoreRequest.store[childId][numberTable]['FORM_DATA']} columnsTable={columnsTable} altForm={StoreRequest.store[childId][numberTable]['ALT_FORM']} id={StoreRequest.store[childId][numberTable]['FORM_ID']} page={childId} setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping} />;
  } 
}

//    {columnsTable != 0 ? tableComponent : <div className='noDataTable'> Bilgi bulunmamaktadır </div>}

let plateNoData;

if (!storeFlag) {
  plateNoData = svgLoaderPreTableChildPage;
} else if (columnsTable == 0) {
  plateNoData = <div className='noDataTable'> Bilgi bulunmamaktadır </div>;
}



  return <>
  {formChildTable}
  <div className='PreTableChildPageConteiner'> 

    <ButtonsComponent 
    id={childId} 
    token={token}
    setdatapreTablePage={setdatapreTablePage}
    numberTable={numberTable}
    serverIdForLine={StoreRequest.store[childId]?.[numberTable]?.['FORM_ID'] ?? 0}  
    setChangeStateOfPreTablePageWrapping={setChangeStateOfPreTablePageWrapping}
    />
    
    {columnsTable != 0 && tableComponent}
    {plateNoData}
    
  </div>
  </>;
}

export default PreTableChildPage;


//https://creaapi.com/crealogKlon/CrealogMaster.svc/SaveForm

/*
fa4b5ac2-6133-4fda-b8e1-0b9407a9f039*4348*ID;0|TEST_ID;3|ADI;1234567|SOYADI;1234567*2268*3
*/

/*
По какой то причине компонент не обновляется после получения данных с сервера.
Возможно ли, что state принял значение до получения информации с сервера и уже не обновляется?

Причина в том, что условие отправки данных к серверу в некоторых случаях ложно.
Может из за того что state не принимает значение 0 и сохраняет предудущий ıd.
*/


/*
<symbol id="icon-cancel-circle" viewBox="0 0 32 32">
<path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"></path>
<path d="M21 8l-5 5-5-5-3 3 5 5-5 5 3 3 5-5 5 5 3-3-5-5 5-5z"></path>
</symbol>
*/

/*

непонятно что делать с кнопкой закрытия таблицы. 
Возникает проблема с версткой. Невозможно предвидеть наличие или отсутствие скроллбара 
между элементом, к которому привязана кнопка, и кнопками из компонента buttons. 
Пока что единственный вариант решения проблемы - это перенести кнопку в компонент buttons. 
Однако и у этого решения есть проблема. В случае переноса нужно переносить и функции, и переменные, 
и функции для вычисления этих переменных в компонент buttons. Однако эти функции должны выполниться всего 
один раз. 

*/