//import './Form.css';
import { useState, useRef } from 'react';
import SideBarBlock from './SideBarBlock';
import SideBarForm from './SideBarForm';
import MapRepository from './classMapRepository.js'
import StoreSearchEngine from '../header/classStoreSearchEngine';


const data = [
  {
      "ADRES": "Zafer Mahallesi, Gaziemir, İzmir, Ege Bölgesi, Türkiye",
      "ARACLAR_ID": 77206,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 27.119877,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 0,
      "ENLEM": 38.348463,
      "GIRIS1": 0,
      "GIRIS2": 1,
      "GIRIS3": 0,
      "GIRIS4": 1,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 14,
      "G_X_SAG": 14,
      "G_X_SOL": 0,
      "G_Y": 3,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 3,
      "G_Z": 8,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 8,
      "HAFTA_KM": null,
      "HIZ": 0,
      "HIZ_LINE": null,
      "IMEI": "862430054444597",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 0,
      "KONTAK_ACIKMI": 1,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 0,
      "MOTOR_ISISI": 0,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 0,
      "PLAKA": "35BKH924",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "05.07.2024 10:02",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 0,
      "STATU_DURAN": 1,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 0,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 1,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#8CB140",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "",
      "TAKOGRAF_ARAC_HAREKETI": "",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": -1,
      "TAKOGRAF_HIZ_IHLALI": "",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "",
      "TAKOGRAF_PERFORMANS": "",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_1_KART": "",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_2_KART": "",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_NUMARASI": "",
      "TAKOGRAF_YON": "",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 201
  },
  {
      "ADRES": "Binbaşı Reşatbey Mahallesi, Gaziemir, İzmir, Ege Bölgesi, Türkiye",
      "ARACLAR_ID": 77207,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 27.13408,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 0,
      "ENLEM": 38.35395,
      "GIRIS1": 0,
      "GIRIS2": 1,
      "GIRIS3": 0,
      "GIRIS4": 1,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 8,
      "G_X_SAG": 8,
      "G_X_SOL": 0,
      "G_Y": 18,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 18,
      "G_Z": 4,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 4,
      "HAFTA_KM": null,
      "HIZ": 17,
      "HIZ_LINE": null,
      "IMEI": "862843047141957",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 0,
      "KONTAK_ACIKMI": 1,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 0,
      "MOTOR_ISISI": 0,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 0,
      "PLAKA": "35BKH900",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "03.05.2024 15:41",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 1,
      "STATU_DURAN": 0,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 1,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 1,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#000000",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "",
      "TAKOGRAF_ARAC_HAREKETI": "",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": -1,
      "TAKOGRAF_HIZ_IHLALI": "",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "",
      "TAKOGRAF_PERFORMANS": "",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_1_KART": "",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_2_KART": "",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_NUMARASI": "",
      "TAKOGRAF_YON": "",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 8
  },
  {
      "ADRES": "Dolmuş Gündoğan-Bodrum, Bodrum, Muğla, Ege Bölgesi, Türkiye",
      "ARACLAR_ID": 76951,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 27.463193,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 0,
      "ENLEM": 37.068315,
      "GIRIS1": 0,
      "GIRIS2": 0,
      "GIRIS3": 0,
      "GIRIS4": 0,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 6,
      "G_X_SAG": 6,
      "G_X_SOL": 0,
      "G_Y": 15,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 15,
      "G_Z": 3,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 3,
      "HAFTA_KM": null,
      "HIZ": 0,
      "HIZ_LINE": null,
      "IMEI": "868999035861727",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 0,
      "KONTAK_ACIKMI": 1,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 0,
      "MOTOR_ISISI": 0,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 0,
      "PLAKA": "ISISENSOR",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "05.07.2024 10:01",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 0,
      "STATU_DURAN": 1,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 0,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 1,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#8CB140",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "",
      "TAKOGRAF_ARAC_HAREKETI": "",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": -1,
      "TAKOGRAF_HIZ_IHLALI": "",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "",
      "TAKOGRAF_PERFORMANS": "",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_1_KART": "",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_2_KART": "",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_NUMARASI": "",
      "TAKOGRAF_YON": "",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 58
  },
  {
      "ADRES": "Kastamonu-İnebolu yolu, Kastamonu Merkez, Kastamonu, Karadeniz Bölgesi, Türkiye",
      "ARACLAR_ID": 76963,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 33.758947,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 0,
      "ENLEM": 41.494228,
      "GIRIS1": 0,
      "GIRIS2": 1,
      "GIRIS3": 0,
      "GIRIS4": 1,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 0,
      "G_X_SAG": 0,
      "G_X_SOL": 0,
      "G_Y": 0,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 0,
      "G_Z": 0,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 0,
      "HAFTA_KM": null,
      "HIZ": 0,
      "HIZ_LINE": null,
      "IMEI": "869867034060832",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 0,
      "KONTAK_ACIKMI": 0,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 0,
      "MOTOR_ISISI": 0,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 0,
      "PLAKA": "CREATECH-DATALOGER-1",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "22.12.2022 19:09",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 1,
      "STATU_DURAN": 1,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 0,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 0,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#000000",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "",
      "TAKOGRAF_ARAC_HAREKETI": "",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": 0,
      "TAKOGRAF_HIZ_IHLALI": "",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "",
      "TAKOGRAF_PERFORMANS": "",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_1_KART": "",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_2_KART": "",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_NUMARASI": "",
      "TAKOGRAF_YON": "",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 157
  },
  {
      "ADRES": "Cumhuriyet Mahallesi, Çorlu, Tekirdağ, Marmara Bölgesi, Türkiye",
      "ARACLAR_ID": 77306,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 27.7524,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 0,
      "ENLEM": 41.11904,
      "GIRIS1": 0,
      "GIRIS2": 1,
      "GIRIS3": 0,
      "GIRIS4": 1,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 6,
      "G_X_SAG": 6,
      "G_X_SOL": 0,
      "G_Y": 2,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 2,
      "G_Z": 1,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 1,
      "HAFTA_KM": null,
      "HIZ": 0,
      "HIZ_LINE": null,
      "IMEI": "862843047181573",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 0,
      "KONTAK_ACIKMI": 0,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 0,
      "MOTOR_ISISI": 0,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 0,
      "PLAKA": "35BKD841",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "05.07.2024 10:01",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 0,
      "STATU_DURAN": 1,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 0,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 0,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#FF0022",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "",
      "TAKOGRAF_ARAC_HAREKETI": "",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": 0,
      "TAKOGRAF_HIZ_IHLALI": "",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "",
      "TAKOGRAF_PERFORMANS": "",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_1_KART": "",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "",
      "TAKOGRAF_SURUCU_2_KART": "",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "",
      "TAKOGRAF_SURUCU_NUMARASI": "",
      "TAKOGRAF_YON": "",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 56
  },
  {
      "ADRES": "Adres bulunamadı !",
      "ARACLAR_ID": 77305,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 27.775415,
      "CAN_HIZ": 50,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 0,
      "ENLEM": 41.1488499,
      "GIRIS1": 1,
      "GIRIS2": 0,
      "GIRIS3": 0,
      "GIRIS4": 0,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 0,
      "G_X_SAG": 0,
      "G_X_SOL": 0,
      "G_Y": 0,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 0,
      "G_Z": 0,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 0,
      "HAFTA_KM": null,
      "HIZ": 50,
      "HIZ_LINE": null,
      "IMEI": "352016708292529",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 10022,
      "KONTAK_ACIKMI": 1,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 1669,
      "MOTOR_ISISI": 0,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 82,
      "PLAKA": "35BKD855",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "05.07.2024 09:42",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 0,
      "STATU_DURAN": 0,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 1,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 1,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#0802C4",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "ALARM_VAR",
      "TAKOGRAF_ARAC_HAREKETI": "VAR",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": 0,
      "TAKOGRAF_HIZ_IHLALI": "VAR",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "HATA_YOK",
      "TAKOGRAF_PERFORMANS": "NORMAL_PERFORMANS",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "RESET",
      "TAKOGRAF_SURUCU_1_KART": "KART_TAKILI",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "RESET",
      "TAKOGRAF_SURUCU_2_KART": "KART_TAKILI",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_NUMARASI": "",
      "TAKOGRAF_YON": "ILERI",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 225
  },
  {
      "ADRES": "141 Sokak, Çankaya Mahallesi, Konak, İzmir, Ege Bölgesi, Türkiye",
      "ARACLAR_ID": 76768,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 27.101097,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 52,
      "ENLEM": 38.4046,
      "GIRIS1": 0,
      "GIRIS2": 0,
      "GIRIS3": 0,
      "GIRIS4": 0,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 0,
      "G_X_SAG": 0,
      "G_X_SOL": 0,
      "G_Y": 0,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 0,
      "G_Z": 0,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 0,
      "HAFTA_KM": null,
      "HIZ": 0,
      "HIZ_LINE": null,
      "IMEI": "869943054283487",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 276239,
      "KONTAK_ACIKMI": 1,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 798,
      "MOTOR_ISISI": 115,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 34,
      "PLAKA": "06CREA06",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "05.07.2024 10:02",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 0,
      "STATU_DURAN": 1,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 0,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 1,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#8CB140",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "ALARM_VAR",
      "TAKOGRAF_ARAC_HAREKETI": "YOK",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": 0,
      "TAKOGRAF_HIZ_IHLALI": "YOK",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "HATA_YOK",
      "TAKOGRAF_PERFORMANS": "NORMAL_PERFORMANS",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "CALISMA",
      "TAKOGRAF_SURUCU_1_KART": "KART_TAKILIDEGIL",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "RESET",
      "TAKOGRAF_SURUCU_2_KART": "KART_TAKILIDEGIL",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_NUMARASI": "010301001968004B-0222401F5FC02B3C-03D1182EF8702B07-04004B32FFFFFFFF",
      "TAKOGRAF_YON": "ILERI",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 132
  },
  {
      "ADRES": "",
      "ARACLAR_ID": 77782,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 26.8392933,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 100,
      "ENLEM": 38.2478166,
      "GIRIS1": 1,
      "GIRIS2": 0,
      "GIRIS3": 0,
      "GIRIS4": 0,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 31,
      "G_X_SAG": 31,
      "G_X_SOL": 0,
      "G_Y": 65519,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 65519,
      "G_Z": 1025,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 1025,
      "HAFTA_KM": null,
      "HIZ": 0,
      "HIZ_LINE": null,
      "IMEI": "350612078848080",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 121538,
      "KONTAK_ACIKMI": 1,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 1745,
      "MOTOR_ISISI": 122,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 91,
      "PLAKA": "ESHOT XX",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "25.03.2024 14:53",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 1,
      "STATU_DURAN": 1,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 0,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 1,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#000000",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "ALARM_VAR",
      "TAKOGRAF_ARAC_HAREKETI": "VAR",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": 0,
      "TAKOGRAF_HIZ_IHLALI": "VAR",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "HATA_YOK",
      "TAKOGRAF_PERFORMANS": "NORMAL_PERFORMANS",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "RESET",
      "TAKOGRAF_SURUCU_1_KART": "KART_TAKILI",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "RESET",
      "TAKOGRAF_SURUCU_2_KART": "KART_TAKILI",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_NUMARASI": "011300C0126DC044-0219A0284FC02B22-030032840319FFFF",
      "TAKOGRAF_YON": "ILERI",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 0
  },
  {
      "ADRES": "Ulamış Mahallesi, Seferihisar, İzmir, Ege Bölgesi, Türkiye",
      "ARACLAR_ID": 79051,
      "AY_KM": null,
      "BOLGE": "",
      "BOYLAM": 26.83944,
      "CAN_HIZ": 0,
      "DEPO_LINE": null,
      "DEPO_SEVIYE": 0,
      "ENLEM": 38.2475683,
      "GIRIS1": 1,
      "GIRIS2": 0,
      "GIRIS3": 0,
      "GIRIS4": 0,
      "GIRIS5": 0,
      "GUN_KM": null,
      "G_X": 0,
      "G_X_SAG": 0,
      "G_X_SOL": 0,
      "G_Y": 0,
      "G_Y_ARKAYA": 0,
      "G_Y_ONE": 0,
      "G_Z": 0,
      "G_Z_ASSAGI": 0,
      "G_Z_YUKARI": 0,
      "HAFTA_KM": null,
      "HIZ": 0,
      "HIZ_LINE": null,
      "IMEI": "352016708564844",
      "ISI_LINE": null,
      "ISI_TAKIP": null,
      "KILOMETRE": 0,
      "KONTAK_ACIKMI": 1,
      "KONTROL_ANI_FREN": 1,
      "KONTROL_ANI_HIZLANMA": 1,
      "KONTROL_ARAC_CEKILIYOR": 1,
      "KONTROL_CARPISMA": 1,
      "KONTROL_CARPISMA_OLASILIGI": 1,
      "KONTROL_DIKKAT_DAGINIKLIGI": 1,
      "KONTROL_EMNIYET_KEMERI": 0,
      "KONTROL_GERI_VITES_DENETIMI": 0,
      "KONTROL_SAG_FAR": 1,
      "KONTROL_SERIT_TAKIP": 1,
      "KONTROL_SIGARA": 1,
      "KONTROL_SOL_FAR": 1,
      "KONTROL_TELEFON": 1,
      "KONTROL_VIRAJ_IHLALI": 1,
      "KONTROL_YIRGUNLUK": 1,
      "MOTOR_DEVRI": 1773,
      "MOTOR_ISISI": 0,
      "NAKLIYECI": "",
      "PEDAL_DURUMU": 93,
      "PLAKA": "09.05.24-64844",
      "RENK": "#949494",
      "SANTIYE": "",
      "SON_MESAJ_TARIHI": "09.05.2024 17:08",
      "STATULER": [],
      "STATU_BILGI_ALINAMAYAN": 1,
      "STATU_DURAN": 1,
      "STATU_GPS_HATA": 0,
      "STATU_HAREKETLI": 0,
      "STATU_HIZ_70_UZERINDE_OLAN": 0,
      "STATU_KAMERA": 0,
      "STATU_KONTAK_ACIK": 1,
      "STATU_KONTAK_KAPALI": null,
      "STATU_RENK": "#000000",
      "STATU_SIPARISI_OLAN": 0,
      "STATU_SIPARISI_OLMAYAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANAN": 0,
      "STATU_SIPARIS_IKMALLER_TAMAMLANMAYAN": 0,
      "STATU_SURUCU_EMNIYET_KEMERI": 0,
      "STATU_URETIM_OLAN": 0,
      "STATU_URETIM_OLMAYAN": 0,
      "STATU_YOLCU_EMNIYET_KEMERI": 0,
      "SURUCU_EMNIYET_KEMERI": 1,
      "TAKOGRAF_ALARM": "ALARM_VAR",
      "TAKOGRAF_ARAC_HAREKETI": "VAR",
      "TAKOGRAF_ENTEGRASYONU": false,
      "TAKOGRAF_HIZ": 0,
      "TAKOGRAF_HIZ_IHLALI": "VAR",
      "TAKOGRAF_KULLANIM_HATA_BILGI": "HATA_YOK",
      "TAKOGRAF_PERFORMANS": "NORMAL_PERFORMANS",
      "TAKOGRAF_SURUCU_1_ADI": "",
      "TAKOGRAF_SURUCU_1_CALISMA_DURUMU": "RESET",
      "TAKOGRAF_SURUCU_1_KART": "KART_TAKILI",
      "TAKOGRAF_SURUCU_1_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_2_ADI": "",
      "TAKOGRAF_SURUCU_2_CALISMA_DURUMU": "RESET",
      "TAKOGRAF_SURUCU_2_KART": "KART_TAKILI",
      "TAKOGRAF_SURUCU_2_SURUS_ANALIZI": "NORMAL",
      "TAKOGRAF_SURUCU_NUMARASI": "",
      "TAKOGRAF_YON": "ILERI",
      "VALIDATION_MESSAGE": null,
      "YIL_KM": null,
      "YOLCU_EMNIYET_KEMERI": 1,
      "YON": 340
  }
]
let data1 = [
  {
      "ADI": "Plaka",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "PLAKA",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sensör Değer",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "SENSOR_DEGER",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sürücü İsmi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "SURUCU_ISIM",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sürücü Telefon",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "SURUCU_TELEFON",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Araç Adres",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "ADRES",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Araç Yükseklik",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "YUKSEKLIK",
      "TIPI": " metre",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Araç Besleme",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "BESLEME_VOLTAJ",
      "TIPI": " voltaj",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Araç Hızı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "UYDUDAN_HIZ",
      "TIPI": " km/s",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Son Mesaj Tarihi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "WEB_SOKET_TARIH",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Römork Isı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "ROMORK_ISI",
      "TIPI": " Celsius",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Römork Plaka",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Genel",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "ROMORK_PLAKA",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Araç Depo",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "DEPO_LT",
      "TIPI": " Litre",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Devri",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_DEVRI",
      "TIPI": " rpm",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Isısı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_ISISI",
      "TIPI": " Celsius",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Tork",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_TORK",
      "TIPI": " nm",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Yükü",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_YUKU",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Saati",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_SAATI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Gaz Akış Hızı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_GAZ_AKIS_HIZI",
      "TIPI": "kg/h",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Telep Edilen Motor Torku",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ISTENILIEN_MOTOR_TORKU",
      "TIPI": " nm",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Marj Modu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_MARS_MODU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Soğutma Suyu Sıcaklığı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_SOGUTMA_SUYU_SICAKLIGI",
      "TIPI": " Celsius",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Yağ Sıcaklığı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_YAGI_SICAKLIGI",
      "TIPI": " Celsius",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Gaz Kütle Akış Hızı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_GAZ_KUTLE_AKIS_HIZI",
      "TIPI": " kg/h",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Yağ Seviyesi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_YAGI_SEVIYESI",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Yağ Basıncı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_YAGI_BASINCI",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Soğutma Suyu Basıncı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_SOGUTUCU_BASINCI",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Soğutma Suyu Seviyesi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Motor ve Performans",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_MOTOR_SOGUTUCU_SEVIYESI",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Anlık Yakıt Tüketimi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ANLIK_YAKIT_TUKETIMI",
      "TIPI": " km/L",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Ortalama Yakıt Tüketimi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ORTALAMA_YAKIT_TUKETIMI",
      "TIPI": " km/L",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Toplam Yakıt Tüketim Sayacı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_TOPLAM_YAKIT_TUKETIM_SAYACI",
      "TIPI": " Litre",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Depo Seviye",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_YAKIT_DEPO_SEVIYE",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "2. Depo Seviye",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_YAKIT_DEPO_SEVIYE_2",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Yakıt Sıcaklığı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_YAKIT_SICAKLIGI",
      "TIPI": " Celsius",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Adblue Depo Seviye",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Yakıt ve Tüketim",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ADBLUE_DEPO_SEVIYE",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Debriyaj Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DEBRIYAJ_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Park Freni Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_PARK_FRENI_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Servis Fren Hava Basıncı 1",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_SERVIS_FREN_HAVA_BASINCI_1",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Servis Fren Hava Basıncı 2",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_SERVIS_FREN_HAVA_BASINCI_2",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Romork Hava Basıncı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_PARK_ROMORK_HAVA_BASINCI",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Yardımcı Ekipman Besleme Basıncı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_YARDIMCI_EKIPMAN_BESLEME_BASINCI",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fen Pedal Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_PEDAL_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Ön Aks Sol",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_ON_AKS_SOL_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Ön Aks Sağ",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_ON_AKS_SAG_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Aks 1 Sol",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_AKS_1_SOL_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Aks 1 Sağ",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_AKS_1_SAG_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Aks 2 Sol",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_AKS_2_SOL_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Aks 2 Sağ",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_AKS_2_SAG_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Aks 3 Sol",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_AKS_3_SOL_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Fren Balatası Aks 3 Sağ",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Frenleme Sistemi",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_FREN_BALATASI_AKS_3_SAG_TEKERLEK",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Hız",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Sürüş Kontrol Sistemleri",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_HIZ",
      "TIPI": " km/s",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Gaz Pedal Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Sürüş Kontrol Sistemleri",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_GAZ_PEDAL_DURUMU",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Cruise Kontrol Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Sürüş Kontrol Sistemleri",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_CRUISE_CONTROL_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Elektronik Retarder Kontrol Trok Modu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Sürüş Kontrol Sistemleri",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ELECTRONIC_RETARDER_KONTROL_TORK_MODU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Elektronik Retarder Kontrol Trok Yüzdesi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Sürüş Kontrol Sistemleri",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ELECTRONIC_RETARDER_KONTROL_TORK_YUZDESI",
      "TIPI": " %",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Elektronik Retarder Kontrol Seçim",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Sürüş Kontrol Sistemleri",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ELECTRONIC_RETARDER_KONTROL_SECIMI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Kickdown Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Sürüş Kontrol Sistemleri",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_KICKDOWN_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Koruma Lambası",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_MOTOR_KORUMA_LAMBASI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motoer Uyarı Lambası",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_MOTOR_UYARI_LAMBASI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Kırmızı Uyarı Lambası",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_MOTOR_KIRMIZI_UYARI_LAMBASI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Motor Mil Lambası",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_MOTOR_MIL_LAMBASI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sorun Kodları 1 Kısım",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_1_EN_AZ_ONEMLI_KISIMLARI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sorun Kodları 2 Kısım",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_2_IKINCI_KISMI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sorun Kodları 3 Kısım",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_3_EN_ONEMLI_KISMIMLARI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sorun kodları Arıza Modu Tanımlayıcı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_ARIZA_MODU_TANIMLAYICISI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sorun Kodları Oluşum Sayısı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_OLUSUM_SAYISI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Sorun Kodları Dönüşüm Yönetemi",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Diagnostik ve Uyarılar",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_DIYAGNOSTIK_SORUN_KODLARI_DONUSUM_YONTEMI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Araç Kapıları",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ARAC_KAPILARI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "PTO Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_PTO_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Hava Kompresör Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_HAVA_KOMPRESORU_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Elektronik İletim Kontrol Cihazı Genel Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ELECTRONIC_ILETIM_KONTROL_CIHAZI_GENEL_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Elektronik İletim Kontrol Cihazı Çalışma Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ELECTRONIC_ILETIM_KONTROL_CIHAZI_CALISMA_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Emniyet Kemeri Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_EMNIYET_KEMERI_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Araç Hız SInırlama Anahtar Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_ARAC_HIZ_SINIRLAMA_ANAHTAR_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Otomatik Vites Değiştirme ANahtar Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_OTOMATIK_VITES_DEGISTIRMEYI_ETKINLESTIRME_ANAHTAR_DURUMU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Ana Işık Kontrol Anahtar Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_OPERATOR_HARICI_ISIK_KONTROL_ANA_ISIK_ANAHTARI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Dönüş Sinyali Anahtar Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_OPERATOR_HARICI_ISIK_KONTROL_DONUS_SINYAL_ANAHTARI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Tehlike Işıkları Anahtar Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_OPERATOR_HARICI_ISIK_KONTROL_TEHLIKE_ISIK_ANAHTARI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Uzun Kısa Işıklar Anahtar Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_OPERATOR_HARICI_ISIK_KONTROL_UZUN_KISA_ISIK_ANAHTARI",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Silecek Kontrol Durumu",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 0,
      "KATEGORI": "Can-Bus | Gövde ve Diğer Kontroller",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_OPERATOR_SILECEK_KONTROLU",
      "TIPI": "",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Pnomatik Besleme Basıncı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Basınç ve Süspansiyon",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_PNOMATIK_BESLEME_BASINCI",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Suspansiyon Basıncı",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Basınç ve Süspansiyon",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_HAVA_SUSPANSIYON_BASINCI",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Suspansiyon Körük Basıncı Ön Aks Sol",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Basınç ve Süspansiyon",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_SUSPANSIYON_KORUK_BASINCI_ON_AKS_SOL",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Suspansiyon Körük Basıncı Ön Aks Sağ",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Basınç ve Süspansiyon",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_SUSPANSIYON_KORUK_BASINCI_ON_AKS_SAG",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Suspansiyon Körük Basıncı Arka Aks Sol",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Basınç ve Süspansiyon",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_SUSPANSIYON_KORUK_BASINCI_ARKA_AKS_SOL",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  },
  {
      "ADI": "Suspansiyon Körük Basıncı Arka Aks Sağ",
      "ARAC_UST_BILGI": 0,
      "GRAFIK": 1,
      "KATEGORI": "Can-Bus | Basınç ve Süspansiyon",
      "LISTE": 1,
      "MOBIL": 1,
      "SOKET_ADI": "CAN_SUSPANSIYON_KORUK_BASINCI_ARKA_AKS_SAG",
      "TIPI": " kPa",
      "UST_BILGI": 1,
      "WEB": 1
  }
];

function createSideBarBlock (data, reference, stringForFilter, setupdateComponent, selectedBlock, MapWrappingUpdate) {

  let variableArr = [];
  //const regexpVariable = new RegExp(`${stringForFilter}`, 'i');
  const regexpVariable = new RegExp(`${stringForFilter.replace(/[-\/\\^$.*+?()[\]{}|]/g, '\\$&')}`, 'i');

  for (let i = 0; i < data.length; i++) {
    if (regexpVariable.test(data[i].PLAKA)) {
      variableArr.push(<SideBarBlock data={data[i]} elementNumber={i} elementReference_Right_Sidebar={reference} setupdateComponent={setupdateComponent} MapWrappingUpdate={MapWrappingUpdate} selectedBlock={selectedBlock} />);
      variableArr.push(<hr />);
    }
  }
  return variableArr;
}

function Right_Sidebar({selectedBlock, MapWrappingUpdate}) {
  const elementReference_Right_Sidebar = useRef([0, -1]);
  const [stringForFilter, setstringForFilter] = useState('');
  const [updateComponent, setupdateComponent] = useState([0]);

  console.log('Right_Sidebar обновился');

  if (StoreSearchEngine.flagOfMap != 0) {
    queueMicrotask(function() {
        setstringForFilter(StoreSearchEngine.flagOfMap);
        StoreSearchEngine.flagOfMap = 0;
    })

  }

  let sidebar = <div className='Right_Sidebar' onClick={()=>console.log('Right_Sidebar elementReference_Right_Sidebar', elementReference_Right_Sidebar.current)}> 
    <div className='Right_Sidebar_inputDiv'>
      <div className={`Right_Sidebar_${stringForFilter == '' ? 'activeDiv' : 'inactiveDiv'}`} >Ara</div>
      <input className='Right_Sidebar_input' 
      defaultValue={stringForFilter}
      onInput={function(event) {

        setstringForFilter(event.target.value);
      }}
      />

    </div>
    {createSideBarBlock (MapRepository.data.GUNCEL_TAKIP, elementReference_Right_Sidebar.current, stringForFilter, setupdateComponent, selectedBlock, MapWrappingUpdate)}
  </div>;
  console.log('Right_Sidebar elementReference_Right_Sidebar.current[1]', selectedBlock[1]);
  return <>
    {sidebar}
    <SideBarForm 
    flag={elementReference_Right_Sidebar.current[0] != 0 && 
      elementReference_Right_Sidebar.current[0] != undefined && 
      elementReference_Right_Sidebar.current[0] != '' || selectedBlock[1] != -1} 
    index={selectedBlock[1] == -1 ? elementReference_Right_Sidebar.current[1] : selectedBlock[1]} 
    data={MapRepository.data} 
    flag_elementReference={elementReference_Right_Sidebar.current[0] != 0 && 
        elementReference_Right_Sidebar.current[0] != undefined && 
        elementReference_Right_Sidebar.current[0] != '' && selectedBlock[1] != -1} />
    
  </>;
}
  
export default Right_Sidebar;

/*
нужно теперь сделать инпут для сортировки элементов. 
Этот инпут надо стилизовать. 
Как это лучше сделать? 

    index={selectedBlock[1] != -1 ? elementReference_Right_Sidebar.current[1] : selectedBlock[1]} 
    index={elementReference_Right_Sidebar.current[1]} 

*/ 
//const regexpForPopUp = new RegExp(`${templateForPopUp.replace(/\|/g, '\\|')}`, 'i');
//regexpForPopUp.test((item.ADI));

/*
    flag={elementReference_Right_Sidebar.current[0] != 0 && 
    elementReference_Right_Sidebar.current[0] != undefined && 
    elementReference_Right_Sidebar.current[0] != ''} 
*/

/*
При повторном клике на стрелку, выделение блока снимается, а форма остается на месте.
Почему? 
Потому что значение в selectedBlock[1] не равно -1 на момент перерендеринга. 
Как это отрегулировать? В MapComponent нет информации о выделении блока. 
Зато эта информация есть в форме. Если selectedBlock[1] != -1 && elementReference_Right_Sidebar.current[0] != 0, 
то можно закрывать форму. 

*/
