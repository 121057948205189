
const defaultValue = {userName: 0}
const userAuthentication = function (state=defaultValue, action) {
  switch (action.type){
    case 'SET_USER_NAME':
      return {userName: action.userName};
    default:
      return state;
  }

}

export default userAuthentication;
