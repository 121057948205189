class StoreRequestTypeComponent {
  static pageType;
}

export default StoreRequestTypeComponent;

/*
это хранилище нужно для определения типа компонента в компоненте sortComponent
Используется в этих компонентах: 
- SortComponent;
- menu во всех вариантах;
- topMenu.
*/
