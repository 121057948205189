import { useRef, useState } from 'react';
import './userSettings.css';
import { useSelector } from 'react-redux';



function UserSettingsPage() {



const [dataOfPassword, setdataOfPassword] = useState(['', '', '']);
const [passwordisCorrect, setpasswordisCorrect] = useState('noWarning_fildOfSettings');

function changingPassword (event, fild) {
  setdataOfPassword([...dataOfPassword.slice(0, fild), event.target.value, ...dataOfPassword.slice(fild + 1)]);
}

const settingsOfUser = <div className='fildOfSettings'>
  <div className='label_topBlock_fildOfSettings'>
    <p> ŞİFRE İSEMLERİ </p>
  </div>
  <div className='topBlock_fildOfSettings'>
    <div className='old_password_topBlock_fildOfSettings'>
      <p className={`${dataOfPassword[0] == '' ? 'activeparagraph_topBlock_fildOfSettings' : 'inactiveparagraph_topBlock_fildOfSettings'}`}>Eski Şifre</p>
      <input placeholder='Eski Şifre' className={`${dataOfPassword[0] == '' ? 'inactiveinput_topBlock_fildOfSettings' : 'activeinput_topBlock_fildOfSettings'}`} onChange={(event) => changingPassword (event, 0)} type='password'/>
    </div>
    <div className='new_password_topBlock_fildOfSettings'>
      <p className={`${dataOfPassword[1] == '' ? 'activeparagraph_topBlock_fildOfSettings' : 'inactiveparagraph_topBlock_fildOfSettings'}`}>Yeni Şifre</p>
      <input placeholder='Yeni Şifre' className={`${dataOfPassword[1] == '' ? 'inactiveinput_topBlock_fildOfSettings' : 'activeinput_topBlock_fildOfSettings'}`} 
      onChange={(event) => changingPassword (event, 1)} 
      onBlur={function (event) {
        if (event.target.value != dataOfPassword[2] && dataOfPassword[2] != '') {
          setpasswordisCorrect('Warning_fildOfSettings')
        } else if (event.target.value == dataOfPassword[2] && event.target.value !='') {
          setpasswordisCorrect('concurrencePasswords_fildOfSettings')
        }
      }} 
      type='password'/>
    </div>
    <div className={`new_password_again_topBlock_fildOfSettings ${passwordisCorrect}`} >
      <p className={`${dataOfPassword[2] == '' ? 'activeparagraph_topBlock_fildOfSettings' : 'inactiveparagraph_topBlock_fildOfSettings'}`}>Yeni Şifre Tekrar</p>
      <input placeholder='Yeni Şifre Tekrar' className={`${dataOfPassword[2] == '' ? 'inactiveinput_topBlock_fildOfSettings' : 'activeinput_topBlock_fildOfSettings'}`} 
      onClick={() => setpasswordisCorrect('noWarning_fildOfSettings')}
      onChange={(event) => changingPassword (event, 2)} 
      onBlur={function (event) {
        if (event.target.value != dataOfPassword[1] && event.target.value !='') {
          setpasswordisCorrect('Warning_fildOfSettings')
        } else if (event.target.value == dataOfPassword[1] && event.target.value !='') {
          setpasswordisCorrect('concurrencePasswords_fildOfSettings')
        }
      }}
      type='password'/>
      {passwordisCorrect == 'Warning_fildOfSettings' && <p style={{color: 'red', marginTop: '5px'}}>Girilen şifreler birbiriyle eşleşmiyor</p>}
    </div>
      <button>Kaydet</button>
  </div>

  <div className='centralBlock_fildOfSettings'>
    <div className='label_centralBlock_fildOfSettings'>
      <p> PROFİL İSEMLERİ </p>
    </div>
    <div className='landing_page_mobile_centralBlock_fildOfSettings'>
      <p className={`${dataOfPassword[0] == '' ? 'activeparagraph_centralBlock_fildOfSettings' : 'inactiveparagraph_centralBlock_fildOfSettings'}`}>Açılış Sayfası Mobil</p>
      <input placeholder='Açılış Sayfası Mobil' className={`${dataOfPassword[0] == '' ? 'inactiveinput_centralBlock_fildOfSettings' : 'activeinput_centralBlock_fildOfSettings'}`} onChange={(event) => changingPassword (event, 0)} type='password'/>
    </div>
    <div className='landing_page_web_centralBlock_fildOfSettings'>
      <p className={`${dataOfPassword[0] == '' ? 'activeparagraph_centralBlock_fildOfSettings' : 'inactiveparagraph_centralBlock_fildOfSettings'}`}>Açılış Sayfası Web</p>
      <input placeholder='Açılış Sayfası Web' className={`${dataOfPassword[0] == '' ? 'inactiveinput_centralBlock_fildOfSettings' : 'activeinput_centralBlock_fildOfSettings'}`} onChange={(event) => changingPassword (event, 0)} type='password'/>
    </div>
    <label className='testInputLabel' onClick={function (event) {
        console.log('label', event.target.checked);
        console.log('label', event.target.className);
      }}>
      <p>Здесь чекбокс!</p>
      <input className={`testInput ${123}`} type='checkbox' onClick={function (event) {
        console.log(event.target.checked);
        console.log(event.target.className);
      }}/>
    </label>
    
  </div>
</div>
  
  return (
    settingsOfUser

  );
}

export default UserSettingsPage;
