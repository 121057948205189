import {createStore, combineReducers} from 'redux'
import changingPageName from './changingPageName.js'
import tokenOfSession from './tokenOfSession.js'
import userAuthentication from './userName.js'

const rootReducer = combineReducers({
  changingPageName: changingPageName,
  tokenOfSession: tokenOfSession,
  userAuthentication: userAuthentication
});
const store = createStore(rootReducer);

export default store;
