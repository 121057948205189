//import './Form.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import StoreRequestSortComponent from './classStoreRequestSortComponent';

import StoreRequest from './formComponent/classStoreRequestForm.js';
import PreFormPage from './formComponent/preForm.js';
import PreTablePage from './tableComponent/preTableWrapping.js';
import PreDinamikRapor from './dinamikRapor/preDinamikRapor.js';

import StoreRequestTypeComponent from './classRequestLimiter.js';


function SortComponent({id}) {

  console.log('sortComponent StoreRequestTypeComponent.pageType', StoreRequestTypeComponent.pageType);

  /*
  const token = useSelector((state) => state.tokenOfSession.token);
  const [flag, setFlag] = useState(0);
  const [flagState, setFlagState] = useState(0);

  console.log('sortComponent token', token);
  console.log('sortComponent StoreRequestTypeComponent.pageType', StoreRequestTypeComponent.pageType);


  if (StoreRequestSortComponent.pageType[id] != undefined && flag === 0) {
    setFlag(StoreRequestSortComponent.pageType[id]);
    console.log('StoreRequestSortComponent.pageType[id]', StoreRequestSortComponent.pageType[id]);
  } 
  if (Number(id) != flagState && flag !== 0) {
    setFlagState(id);
    setFlag(0);
  }
  

console.log('flag', flag);
  if (flag === 0 && (StoreRequestSortComponent.pageType[id] == undefined || StoreRequestSortComponent.pageType[id] == 0)) {
    console.log('sortComponent вызвал fetch');
    //fetch(`https://creaapi.com/crealog/CrealogMaster.svc/GetForm2/?sessionId=${token}&masterFormId=${id}&masterFormKayitId=0&formId=${id}&sorguSTR=null&kayitSayisi=-1`)
    //fetch(`https://creaapi.com/crealog/CrealogMaster.svc/GetRaporParemetreler/?sessionId=${token}&formId=${id}`)
    fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetRaporParemetreler/?sessionId=${token}&formId=${id}`)
    .then(response => response.json())
    .then(function (json) {
      if(json.GetRaporParemetrelerResult !== null) {
        StoreRequest.store[id] = (json.GetRaporParemetrelerResult).map(function (item) {
          return {
            GORUNEN_ADI: item.GORUNEN_ADI, 
            ID: item.ID,     
            KOLON_DEGER_TIPI: item.KOLON_DEGER_TIPI,
            VIEW_DATA: item.VIEW_DATA,
            ID: item.ID,
          } 
        });
        StoreRequestSortComponent.pageType[id] = 1;
        setFlag(1);
      } else {
        StoreRequestSortComponent.pageType[id] = 2;
        setFlag(2);
      }
    });
  } else if (flag === 0 && (setFlag(StoreRequestSortComponent.pageType[id]) != undefined || setFlag(StoreRequestSortComponent.pageType[id]) != 0)) {
    setFlagState(id);
  }

  
  let component;
  if (StoreRequestSortComponent.pageType[id] !== 0) {
    if (StoreRequestSortComponent.pageType[id] === 1) {
      console.log('flag', flag);
      component = <PreFormPage id={id} />;
    } else if (StoreRequestSortComponent.pageType[id] === 2) {
      component = <PreTablePage id={id} />;
    }
  }
*/


  let component;
  switch (StoreRequestTypeComponent.pageType){
    case 'RAPOR_TABLE':
      component = <PreFormPage id={id} />;
    break;
    case 'FORM_DINAMIK':
      component = <PreTablePage id={id} />;
    break;
    case 'DINAMIK_RAPOR':
      component = <PreDinamikRapor id={id} />;
    break;
  }
  
  return component;
}
  
  export default SortComponent;

//https://creaapi.com/crealogKlon/CrealogMaster.svc/GetRaporParemetreler/?sessionId=${token}&formId=2268

//FORM_DINAMIK - таблица
//RAPOR_TABLE - форма 
//DINAMIK_RAPOR - новая форма

/*
Как сделать DINAMIK_RAPOR? 
Может в родительском компоненте сделать выпадающий список, 
потом сделать дочерние компоненты с полями ввода, 
а потом добавить графики, таблицу и карту?



*/
