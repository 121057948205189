class FormTableRepository {
  static state = {
    
  };
  static formInputsState = {
    
  };
}
/*
Здесь нужно чтобы stateSetting обновляла 
стейт при изменении в store

т.е. в календаре функция должна запускаться, а в form изменять state
*/
export default FormTableRepository;
