import './WeHelp.css';
import * as d3 from "d3";
import { useState, useSyncExternalStore } from 'react';


function WeHelpDiagramMultiple({data, signature, nameColumnGroup, maxY}) {
console.log("WeHelpDiagramMultiply data", data, signature, nameColumnGroup, maxY);
console.log("WeHelpDiagramMultiply nameColumnGroup", nameColumnGroup);

const screenWidth = useSyncExternalStore(function(onChange){
  window.addEventListener("resize", onChange);

  return () => window.removeEventListener("resize", onChange);
}, 
function () {
  return window.innerWidth;
});

const widthSvg = screenWidth/100 * 80;
const lengthAxisY = Math.round(widthSvg/3); //указывает высоту поля графика

const width_G_Svg = widthSvg / data.length;
const rectWidth = Math.round(width_G_Svg/12);// 1/17 widthSvg
const columnGap = Math.round(width_G_Svg/29);// 1/29 widthSvg
const marginLeft = (width_G_Svg - rectWidth * data[0].length - (columnGap * (data[0].length - 1)) - columnGap) / 2;
const marginLeft_signature = widthSvg/(signature.length + 1);


const rect_G_Width = 95 / data.length;
const columnGap_G = 55 / data.length;
const signatureGap = widthSvg / signature.length;
const nameColumnGroupGap = widthSvg / nameColumnGroup.length;

const [heightY, setheightY] = useState(0);
if (heightY == 0) {
  setheightY(maxY + 9);
}

const getYRect = d3.scaleLinear()
  //.domain([0, 22])
  .domain([0, heightY]) //maxY указывает максимальное значение для оси Y
  .range([lengthAxisY,0]);

const getYAxis = ref => {
  const yAxis = d3.axisLeft(getYRect)
    .tickSize(-widthSvg) // ширина горизонтальных линий на графике
  d3.select(ref).call(yAxis);
};

const buttonZoomPlus = <div className='buttonZoomPlus' onClick={function () {
  if (heightY > Math.floor(maxY / 7)) {
    setheightY(heightY - Math.floor(maxY / 7))
  }
}}>+</div>;
const buttonZoomMinus = <div className='buttonZoomMinus' onClick={function () {
  if (heightY < maxY) {
    setheightY(heightY + Math.floor(maxY / 7))
  }
}}>&mdash;</div>;

 
const svg = <svg width={widthSvg} height={lengthAxisY} style={{borderRight: '1px solid #b3b3b3'}}>
  <g className='YAxis' ref={getYAxis} stroke-width='0.3'/>
  {signature.map( function(item, index) {
      return <circle className={`circleRect${index}`}
      key={index}
      //cx={`${(index * signatureGap) + (signatureGap / 2)}`}
      cx={`${marginLeft_signature * (index + 1) }`}

      cy={lengthAxisY + 52}
      r={10}
      fill= {`${item.color}`}
    />
    })}

  { nameColumnGroup.map( function(item, index) {// здесь названия колонок разбиваются на части, т.к. мало места в таблице

      return <text height={17}
      className='columnGroupText'
      fill="black"
      //x={`${(index * nameColumnGroupGap + 5) + (nameColumnGroupGap / 2) - 5}`}
      x={`${(index + 1) * width_G_Svg - width_G_Svg/2}`}
      y={lengthAxisY + 27}
      textAnchor="middle">
      {item}
      </text>
    })}

  {data.map(function(item, index0) {
      return <>
    {
      item.map( function (item, index) {
        return <> 
        <rect className={`rect${index}`} fill= {`${item.color}`} 
        //x={`${20 + (index * rect_G_Width) + (index * columnGap_G) + (index0 * width_G_Svg)}`} y={getYRect(item.value)} 
        x={`${(index * rectWidth) + (index * columnGap) + marginLeft + width_G_Svg * index0}`} y={getYRect(item.value)} 
        width={rect_G_Width} height={lengthAxisY - getYRect(item.value)} 
        onClick={event => console.log(event.target.className)}></rect>
        
        <text
        fill="black"
        //x={`${20 + (index * rect_G_Width) + (index * columnGap_G) + (index0 * width_G_Svg) + (rect_G_Width / 2)}`}
        x={`${marginLeft + (index * columnGap) + ((index + 1) * rectWidth) - rectWidth/2 - 5  + width_G_Svg * index0}`}

        y={getYRect(item.value) - 8 }
        textAnchor="middle">
        {item.value}
        </text>

        </>
    
      })}
      </>
    })
  }

  {signature.map( function(item, index) {
    return <text
      className='columnText'
      fill="black"
      //x={`${(index * signatureGap) + (signatureGap / 2)}`}
      x={`${marginLeft_signature * (index + 1) }`}
      y={lengthAxisY + 79}
      textAnchor="middle">
      {item.nameColumn}
      </text>
    })
  }
  
</svg>;

return <>
  {svg}
  {buttonZoomPlus}
  {buttonZoomMinus}
</>;
}

export default WeHelpDiagramMultiple;

/*
в nameColumnGroup не добавлять больше 3 слов,
между словами не использовать символов кроме пробела, / и -

*/

/*
1 - сегменты по числу групп столбцов
2 - marginLeft
3 - ширина столбца 
4 - ширина пробела между столбцами

Какую ширину дать столбцам? 
А это важно? Можно дать побольше



*/

