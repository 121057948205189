import TableStack from './classTableStack';


function saveSelectedRowsBetweenMounts () {
    //let a = '_SelectedRow'; 12
    //console.log('saveSelectedRowsBetweenMounts', typeof '_SelectedRow');

    
    for( let elem in TableStack.lastLines) {
        console.log('saveSelectedRowsBetweenMounts', TableStack.lastLines[elem]);
        if (typeof TableStack.lastLines[elem] == 'string' && TableStack.lastLines[elem].includes('_SelectedRow')) {
            continue;
        }
        /*
        условие выше нужно для исправления работы react в режиме разработки, потому что в этом режиме 
        react делает несколько перерисовок за один раз и в функцию попадают уже обработанные значения.
        */

        TableStack.lastLines[elem] = String(TableStack.lastLines[elem]) + '_SelectedRow';
        console.log('saveSelectedRowsBetweenMounts', TableStack.lastLines[elem]);

    }
}


export default saveSelectedRowsBetweenMounts;
