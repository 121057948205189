class ChangeRepository {
  static add = {
    
  };
  static dataBase = 0;
  static checkingDatabaseValue  = {};
}
/*
В dataBase хранится объект для взаимодействия с indexedDB, 
не трогать и не изменять эту переменную. 

В checkingDatabaseValue хранится объект с другими вложенными объектами с ключом равным id 
каждой таблицы. В этих объектах хранятся значения true или false для каждой строки в таблице. 
Назначение: проверка наличия записи из строки в idexedDB.   

В компоненте indexedDB в методе putValue в checkingDatabaseValue создается пустой объект с ключом 
id таблицы. В этот объект кладется значение true для отдельной строки.

В компоненте buttons в методе updateTable в переменную checkingDatabaseValue или в один из его ключей 
записывается пустой объект.



*/
export default ChangeRepository;
