
import { useMemo, useState, useRef} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import Bufer_AltForms_Table from './DinamikRapor_AltForms/classBufer_AltForms_Table';

import StoreRequest from '../formComponent/classStoreRequestForm';



function DinamikRaporTable ({data, columnsTable, id, divFlag, currentValueOfTheDropdownList, refForChangeThirdMiddle, name, index,  altFormFlag,}) {
  const refHeightSize = useRef('auto');
  const refPreviousValueOfTheDropdownList = useRef(0);
  const token = useSelector((state) => state.tokenOfSession.token);
  const refFlagOfRequest = useRef(true);
  const refRow = useRef(0);

  const refDOMTableElement = useRef(0);


  const [updateComponent, setupdateComponent] = useState([0]);
  /*
  if (refPreviousValueOfTheDropdownList.current != currentValueOfTheDropdownList || refForChangeThirdMiddle[0]) {
    refPreviousValueOfTheDropdownList.current = currentValueOfTheDropdownList; 
    refHeightSize.current = 0;
    refForChangeThirdMiddle[0] = false;
    setupdateComponent([0]);
  }
  */
  
  if (divFlag && Bufer_AltForms_Table.middleComponentVariable[0]) {
    refDOMTableElement.current = 0;
    refHeightSize.current = 'auto';
  }
  
  
  //console.log('DinamikRaporTable refHeightSize.current', refHeightSize.current, refDOMTableElement.current);

  if (refHeightSize.current == 'auto' && (refDOMTableElement.current == 0 || refDOMTableElement.current == undefined)) {
    queueMicrotask(function() {
      //console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
      //refHeightSize.current = document.getElementsByClassName('MiddleComponentForThirdForm')?.[0]?.offsetHeight + 5;
      //setheightSize(`${refHeightSize.current}px`);
      //setheightSize(`0px`);

      refDOMTableElement.current = document.getElementsByClassName('MiddleComponentForThirdForm');

      //if ((refDOMTableElement.current?.[index] != undefined && refDOMTableElement.current?.[index] != 0) && refDOMTableElement.current?.[index]?.offsetHeight != 0) {
      if (refHeightSize.current == 'auto' && refDOMTableElement.current?.[index] != undefined && refDOMTableElement.current?.[index] != 0) {
        console.log('DinamikRaporTable refHeightSize.current', refHeightSize.current, refDOMTableElement.current?.[index]?.offsetHeight);
        refDOMTableElement.current[index].style.height = 'auto';
        refHeightSize.current = refDOMTableElement.current?.[index]?.offsetHeight + 5;
        refDOMTableElement.current[index].style.height = '0px';

        if (index == refDOMTableElement?.current?.length - 1) {
          Bufer_AltForms_Table.middleComponentVariable[0] = false; 
        }
      }




    // при нажатии кнопки ara размер контейнера не увеличивается
    });
  
    /*
    здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
    повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
    нельзя установить плавное закрытие div, он закроется мгновенно.
    */
  } 
let tableDataVariable = columnsTable.map(function (item) {
  return {
    accessorKey: item, //access nested data with dot notation
    header: item,
  }
});

const columns = useMemo(
  () => tableDataVariable,
  [tableDataVariable],
);
console.log('DinamikRaporTable data', data);

const table = useMaterialReactTable({
  columns,
  data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  muiTableBodyRowProps: ({ row, staticRowIndex }) => ({
    onDoubleClick: (event) => {
      
      if (!divFlag && altFormFlag) {//если 0, то alt forms не выводятся и в переменную функция не записывается
        console.log('data', data);
        if (refRow.current == 0) {
          event.target.parentElement.classList?.add('highlightedLine');
          refRow.current = event.target.parentElement;
          Bufer_AltForms_Table.refVariable[0] = !Bufer_AltForms_Table.refVariable[0];
          Bufer_AltForms_Table.stateVariable[0]([0]);
        } else if (refRow.current != event.target.parentElement) {
          refRow.current.classList?.remove('highlightedLine');
          event.target.parentElement.classList?.add('highlightedLine');
          refRow.current = event.target.parentElement;
        } else if (refRow.current == event.target.parentElement) {
          refRow.current.classList?.remove('highlightedLine');
          refRow.current = 0;
          //Bufer_AltForms_Table.tableIndex[0] = -1;
          Bufer_AltForms_Table.refVariable[0] = !Bufer_AltForms_Table.refVariable[0];
          Bufer_AltForms_Table.stateVariable[0]([0]);
        }
      } else {
        if (refRow.current == 0) {
          event.target.parentElement.classList?.add('highlightedLine');
          refRow.current = event.target.parentElement;
        } else if (refRow.current != event.target.parentElement) {
          refRow.current.classList?.remove('highlightedLine');
          event.target.parentElement.classList?.add('highlightedLine');
          refRow.current = event.target.parentElement;
        } else if (refRow.current == event.target.parentElement) {
          refRow.current.classList?.remove('highlightedLine');
          refRow.current = 0;
        }
      }
      

      if (!divFlag && refFlagOfRequest.current && Bufer_AltForms_Table.tableIndex[0] != staticRowIndex && altFormFlag) {      
        
        Bufer_AltForms_Table.tableIndex[0] = staticRowIndex;
        refFlagOfRequest.current = false;
        let requestParameters = []; 
        for (let elem of StoreRequest.store[id].thirdFormList["FORM_DETAY"].ALT_FORMLAR) {
          console.log('DinamikRaporTable requestParameters', elem);
          let stringVariable = elem.ALT_FORM_SERVIS_PARAMETRE.split(';'); 
          if (Array.isArray(stringVariable)) {
            for (let i = 0; i < stringVariable.length; i++) {
              stringVariable[i] = '&' + stringVariable[i] + row.original[stringVariable[i]];
            }
          }
          requestParameters.push(elem.ALT_FORM_SERVIS + '?sessionId=' + token + stringVariable.join(''));

        }
 
        let fetchVariable = requestParameters.map(function (url) {
          return fetch(url).then(response => {
            return response.json(); 
          });
        });

        Promise.all(fetchVariable).then(function(res) {
          res = res.flat();
          for (let i = 0; i < res.length; i++) {
            res[i].FORM_DATA = JSON.parse(res[i].FORM_DATA);
          }
          Bufer_AltForms_Table.store = res; 
          for (let i = 0; i < Bufer_AltForms_Table.store.length; i++) {
            if (Bufer_AltForms_Table.store[i].FORM_TURU == 'TABLE') {
              Bufer_AltForms_Table.tableDataVariable[i] = (Object.keys(Bufer_AltForms_Table.store[i].FORM_DATA[0]).map(function (item) {
                return {
                  accessorKey: item, //access nested data with dot notation
                  header: item,
                }
              }));
            }
          }
        })
        .then(function () {
          refFlagOfRequest.current = true;
          Bufer_AltForms_Table.refVariable[0] = true;
          //Bufer_AltForms_Table.refVariable[0] = !Bufer_AltForms_Table.refVariable[0];
          Bufer_AltForms_Table.stateVariable[0]([0]);
        });
        
      


      }
    },
  }),
  localization: MRT_Localization_TR,
  enableDensityToggle: false,
  paginateExpandedRows: false,
  initialState: { pagination : { pageSize : 100 }, density: 'comfortable' },
});

if (divFlag) {
  <div className='MiddleComponentForThirdForm' style={{height: 'auto'}}>
    <Box
    sx={{
      width: '94vw',
      marginLeft: '1vw',
      marginBottom: '15px',
      '& .highlightedLine:hover td': {
        backgroundColor: '#c8c7cc',
      }
    }} className={`${id}`} >
      <MaterialReactTable table={table} />
    </Box>
  </div>
} else {
  <div className='MiddleComponentForThirdForm' style={{height: 'auto'}}>
  <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}`} >
    <MaterialReactTable table={table} />
  </Box>
</div>
}

let tableReturn;
if (divFlag) {
  tableReturn = <>
  <div
  className='nameplate_MiddleComponentForThirdForm'
  onClick={function (event) {
    if (refDOMTableElement.current != 0 && refDOMTableElement.current != undefined) {
      if(refDOMTableElement.current?.[index]?.offsetHeight != 0) {
        refDOMTableElement.current[index].style.height = '0px';
        refDOMTableElement.current[index].previousElementSibling.children[1].children[0].innerHTML = '&#9650;';
      } else {
        refDOMTableElement.current[index].style.height = String(refHeightSize.current) + 'px';
        refDOMTableElement.current[index].previousElementSibling.children[1].children[0].innerHTML = '&#9660;';
      }
    }
  }} >
    <p className='header_nameplate_MiddleComponentForThirdForm'> {name} </p>
    <p className='lastText_nameplate_MiddleComponentForThirdForm'><span>&#9650;</span></p>
  </div>
  
  <div className='MiddleComponentForThirdForm'>
    <Box
    sx={{
      width: '94vw',
      marginLeft: '1vw',
      marginBottom: '15px',
      '& .highlightedLine:hover td': {
        backgroundColor: '#c8c7cc',
      }
    }} className={`${id}`} >
      <MaterialReactTable table={table} />
    </Box>
  </div>
  </>
} else {
  tableReturn = <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}`} >
    <MaterialReactTable table={table} />
  </Box>
}

  return tableReturn;
};

export default DinamikRaporTable;


/*
[
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukAltTablo/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TARIH08-10-2024&ID2",
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukHarita0/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TURUARIZA&DEPARTMANITEKNİK DESTEK",
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukHarita1/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TARIH08-10-2024&USER_STATUTD SERVİS GEREKLİ",
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukHarita2/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TARIH08-10-2024&USER_STATUTD SERVİS GEREKLİ"
]
*/

/*

Существует проблема выделения строк в таблице: 
1. При нажатии на выделенную строку выделение снимается, а таблица не исчезает. 
2. При нажатии на другую строку выделение появляется, но таблица исчезает. 
3. Если строка выделена, то сначала нужно снять это выделение, а потом выделить еще раз, чтобы
   таблица появилась. 

   Что может решить эту проблему? Выделение и запрос как то связаны. 

   Итак 3 условия: 
  1. !divFlag - не влияет
  2. refFlagOfRequest.current - предназначен для того, чтобы в одно время выполнялась только одна 
     группа запросов. В начале выполнения кода принимает значение false, а в конце принимает значение 
     nrue. Не влияет на проблему. 

  3. Bufer_AltForms_Table.tableIndex[0] != staticRowIndex - проверяет на какую строку в таблице 
     было нажатие и не допускает повторного нажатия. Влияет на проблему. Условие true только 
     когда нажимается другая строка. Или же повторно выбирается та же строка.

  Похоже проблема именно в этой строке Bufer_AltForms_Table.refVariable[0] = !Bufer_AltForms_Table.refVariable[0];
  Решением проблемы было бы изменение состояния этой переменной. Но как это сделать? 
  Можно попробовать изменить состояние переменной перед этим. 

*/

/*

Нужно сделать переключатель строк в родительской таблице и переключатель дочерних 
элементов заново. 
Алгоритм: 
1. При нажатии на строку должна быть проверка на ее активность. 
2. Если строка неативна, то делаем запрос на сервер и выводим компоненты новой таблицы. 
3. Присваиваем класс highlight для активной строки. 
4. Если строка активна, то убираем класс highlight для данной строки и убираем компоненты новой 
   таблицы. 
5. Есть также вариант, когда выбирается другая строка. В таком случае класс highlight убирается 
   с последней выбранной строки, а действия флага для запроса и выбора таблицы такое же, как и раньше.


Сейчас ситуация лучше, однако возникают проблемы при клике на одну и ту же строку. 
Таблица опять показывается на 3 двойной клик. 
Проблема решена. 

Теперь возникла новая проблема, флаг для указания открытого состояния 
находится в положении true, хотя в хуке useEffect в компоненте DinamikRapor_AltForms 
ставится в значение false. 
Вероятно причина в том, что флаг срабатывает для вывода компонента DinamikRapor_AltForms, 
то есть до срабатывания useEffect. 
Нужно как то ставить флаг в положение false после размонтирования компонента.


*/

/*

Возникает проблема с открытием и закрытием таблиц. Было бы хорошо изменять высоту контейнера 
для таблицы по клику на контейнер. Но ссылка появится только после 1 загрузки таблицы на страницу, 
а до этого туда нужно поставить "auto". 

Теперь можно изменять размер контейнера прямо через dom, но как это согласовать со 
стилем самого элемента? 
Решено. 

Следующая проблема, не во всех таблицах можно открыть alt form. 
Компоненты alt form не выводятся, если они не должны открываться. 
Однако если сначала открыть таблицы, в которых alt form есть, а потом 
открыть таблицы, где alt form нет, то в хранилище запишется функция, 
которая при двойном клике вызовет ошибку в таблице, где alt form нет. 

Нужно эту функцию удалять перед размонтированием компонента. 
Возможно лучше просто передать флаг через props.

*/
