class Bufer_AltForms_Table {
  static refVariable = [false];
  static stateVariable = [0];
  static tableIndex = [-1];
  static tableDataVariable = {};
  static componentUpdateVariable = [];



  static store = [0];

  static middleComponentVariable = [false]; /*
  Эта переменная решает такую проблему:
    В компоненте DinamikRaporTable есть механизм для измерения точной высоты контейнера таблицы и 
    последующее плавное закрытие и открытие контейнера на эту высоту. Из за особенностей кода это 
    должно быть сделано только с одним наьором таблиц за одну сессию компонента. Если в это время послать 
    запрос на сервер, то таблицы обновятся, а высота не сможет обновиться и останется прежней. 
  Решение: 
    Нужно впоймать изменение набора таблиц. При нажатии на кнопку "ara" в переменную middleComponentVariable 
    записывается значение [true], далее в компоненте DinamikRaporTable в переменную refHeightSize 
    записывается значение auto, а в переменную refDOMTableElement записывается 0. 
    Далее условие, при котором в стиль для контейнера сначала записывается значение auto, 
    потом высота контейнера замеряется, записывается в переменную refHeightSize, а 
    дальше в стиль для контейнера сначала записывается значение 0px.

  */
  
  
  
  // для изменения размеров контейнеров для таблиц 
  // в компоненте DinamikRaporTable

}

export default Bufer_AltForms_Table;

/*
Это буфер для обмена данными между параллельными компонентами DinamikRapor_AltForms и DinamikRaporTable, 
чтобы не обновлять родительский компонент. 


dinamikRapor: 
- middleComponent;
- DinamikRaporTable;

DinamikRapor_AltForms: 
- DinamikRapor_AltForms; 
- DinamikRaporAltTable;

*/

/*
17.10.24
Обнаружены следующие недостатки:
1. Данные в этом хранилище в store сохраняются после размонтирования компонента. 
2. При повторном запросе во 2 компоненте 3 компонент просто обновляется, хотя желательное 
   поведение именно перемонтирование, чтобы не было проблем с высотой таблиц. 
3. Во 2 компоненте было бы хорошо сохранять состояние кастомных чекбоксов. 

1. Данные теперь удаляются, однако нет уверенности, что проблема решена, но сейчас все работает. 

2. Нужно как то впоймать момент, когда нужно вывести новые данные для таблиц. 
   Можно сделать специальную переменную в данном хранилище.
*/
