import './WeHelp.css';
import * as d3 from "d3";
import { useState, useRef, useSyncExternalStore } from 'react';

function createArray (length, infersion = false, index = 0) {
  let arr = [];
  console.log('createArray index', index);
  if (infersion) {
    for (let i = 0; i <= length; i++) {
      if (i != index) {
        arr[i] = false;
      } else {
        arr[i] = true;
      }
    }
  } else {
    for (let i = 0; i <= length; i++) {
      arr[i] = true;
    }
  }

  return arr;
  /*
  В полученном массиве будет на 1 элемент больше, чем линий на графике, потому что 
  i <= length, а не i < length. Это не ошибка, последний элемент массива нужен для отслеживания 
  состояния графика. Если последний элемент равен true, то на графике изображены все линии, а если 
  false, то только одна линия.
  */
}

function datesMap(arr, widthSvg, lengthAxisY) {

console.log('lengthAxisY', lengthAxisY);

  let step = Math.round(arr.length/(widthSvg/19));

  if (step == 0) {
    step = 1;
  }
  let arr1 = [];
  let j = 0;
  for (let i = 0; i < arr.length; i += step) {
    if (j * 22  - lengthAxisY/5 >= widthSvg - widthSvg/10) {
      break;
    }
    arr1.push(
      <text className='subscriptionOfDate_WeHelpDiagramLine2'
      x={j * 22 }
      y={lengthAxisY + 35}
      >
        {arr[i]}
    </text>);
    j++;
  }

  return arr1;
}

function WeHelpDiagram({data, maxY}) {
let dataArr = data.slice(1, -2);
console.log('WeHelpDiagramLine data', data);

const [filterArray, setfilterArray] = useState(createArray(data.length - 3));
console.log('WeHelpDiagramLine filterArray', filterArray);

const [mouseXCoord, setmouseXCoord] = useState(-1);
const [fixFlag, setfixFlag] = useState(false);
console.log('WeHelpDiagramLine fixFlag', fixFlag);


const screenWidth = useSyncExternalStore(function(onChange){
  window.addEventListener("resize", onChange);

  return () => window.removeEventListener("resize", onChange);
}, 
function () {
  return window.innerWidth;
});

const widthSvg = screenWidth/100 * 90;
const lengthAxisY = 500; //указывает высоту поля графика

const [heightY, setheightY] = useState(0);
if (heightY == 0) {
  setheightY(maxY + 9);
}

const getY = d3.scaleLinear()
  .domain([0, heightY + 9])
  .range([lengthAxisY,0]);


const getX = d3.scaleLinear()
  .domain([0, data[1].length - 1])
  .range([0, widthSvg]);

const getYAxis = ref => {
  const yAxis = d3.axisLeft(getY)
    .tickSize(-widthSvg) // ширина горизонтальных линий на графике
    .tickPadding(7);
  d3.select(ref).call(yAxis);
};

const curveFunc = d3.line()
.x(function(d) { return getX(d.x) })
.y(function(d) { return getY(d.y) });
//.curve(d3.curveStep);
//.curve(d3.curveMonotoneX);


function x_params (objX) {
  let objX1 = [];
  for (let i = 0; i < objX.length; i++) {
    objX1.push({y: objX[i], x: i});
  }
  return objX1;
}


let variableCircleX = Math.round(getX.invert(mouseXCoord));


const svg = <svg width={widthSvg} height={lengthAxisY} style={{borderRight: '1px solid #b3b3b3'}} className="WeHelpDiagramLine2"
onMouseMove={function(event) {
  if (!fixFlag) {
    setmouseXCoord(d3.pointer(event)[0]);
  }
 }}
 onMouseOut={function() {
  if (!fixFlag) {
    setmouseXCoord(-1);
  }
 }}
 onClick={() => setfixFlag(!fixFlag)}>
  <text className='subscriptionOfGrafik_WeHelpDiagramLine2'
    x={0}
    y={-15}>
      {name}
  </text>
  <g className='YAxis' ref={getYAxis} strokeWidth='0.3'/>
  {dataArr.map(function(item, index) {
    let line;
    if (filterArray[index]) {
      if (mouseXCoord > -1) {
        line = <>
          <path className={`line0`} style={{fill: 'none'}} strokeWidth={3} stroke={data[0][index]} d={curveFunc (x_params (item))}></path>
          <circle
          className={`points0`}
          cx={getX(variableCircleX)}
          cy={getY(item[variableCircleX])}
          r={7}
          fill={data[0][index]}
          stroke="white"
          strokeWidth={2}
          />
          <text className='subscriptionOfPoint_WeHelpDiagramLine2'
            x={getX(variableCircleX) - 1}
            y={getY(item[variableCircleX]) - 15}>
            { item[variableCircleX]}
          </text>
        </>
      } else {
        line = <path className={`line0`} style={{fill: 'none'}} strokeWidth={3} stroke={data[0][index]} d={curveFunc (x_params (item))}></path>;
      }
    }


    return line; 
  })}
  <text className='subscriptionOfPoint_WeHelpDiagramLine2'
  x={widthSvg/2}
  y={ - 15}>
    {mouseXCoord > -1 && data[data.length - 1][variableCircleX]}
  </text>
  {datesMap(data[data.length - 1], widthSvg, lengthAxisY)}
  {data[data.length - 2].map(function (item, index) {

  return <>
  <text
    fill="black"
    x={ index * widthSvg / (data.length - 3 + 1)  + widthSvg/10}
    y={lengthAxisY + 70 + 70}
    textAnchor="middle"
    onMouseMove={function(event) {
      event.stopPropagation();
    }}
    onMouseOut={function(event) {
      event.stopPropagation();
    }}
    onClick={function(event) {
      event.stopPropagation();
      if (filterArray[index] && !filterArray[data.length - 3]) {
        setfilterArray(createArray(data.length - 3));
      } else {
        setfilterArray(createArray(data.length - 3, true, index));
      }
    }}>
    {item}
  </text>
  <circle className={`circle${index}`}
    cx={ index * widthSvg / (data.length - 3 + 1) + widthSvg/10}
    cy={lengthAxisY + 35 + 70}
    r={10}
    fill={data[0][index]}
    onMouseMove={function(event) {
      event.stopPropagation();
    }}
    onMouseOut={function(event) {
      event.stopPropagation();
    }}
    onClick={function(event) {
      event.stopPropagation();
      console.log('WeHelpDiagramLine index', index);
      if (filterArray[index] && !filterArray[data.length - 3]) {
        setfilterArray(createArray(data.length - 3));
      } else {
        setfilterArray(createArray(data.length - 3, true, index));
      }
      
    }}
  />
  </>

  })}
  

</svg>;

const buttonZoomPlus = <div className='buttonZoomPlus' style={{transform:'translateY(-29vw) translateX(10vw)'}} onClick={function () {
  if (heightY > Math.floor(maxY / 7)) {
    setheightY(heightY - Math.floor(maxY / 7))
  }
}}>+</div>;
const buttonZoomMinus = <div className='buttonZoomMinus' style={{transform:'translateY(-28vw) translateX(10vw)'}} onClick={function () {
  if (heightY < maxY) {
    setheightY(heightY + Math.floor(maxY / 7))
  }
}}>&mdash;</div>;

return <>
  {svg}
  {buttonZoomPlus}
  {buttonZoomMinus}
</>;
}

export default WeHelpDiagram;

/*
Если в эту диаграмму передается слишком большое количество данных, 
то возникает несколько проблем:
1. надписи с датами в самом низу диаграммы сливаются в одну сплошную строку. 
2. даже если надписи будут видны, то все равно очень сложно разглядеть к какой дате относится 
   выбранный показатель. 
3. если при наведении на нужную точку выводить дату для этой точки, то нужно написать очень 
   много кода с нуля. 
4. Есть старая проблема с настройками css. Настройки css статичны и не могут изменяться под 
   присылаемые с сервера данные. Получается, что нужно все переписывать с нуля. 

Можно ли использовать график как для множества показателей? 
Можно, функционал очень похож, но нужно сделать много линий на одном графике. 
Как это сделать? 

А еще нужно чтобы можно было считать данные с каждой отдельной линии графика, 
а они часто идут внахлест. 

Значит нужно сначала выделить какую то линию, а потом отслеживать ее положение. 
А что делать когда линия не выделена? 

Может сразу находить все точки? 

При движении курсора на графике будут появляться точки на всех линиях, а также выводиться 
дата, одна на все точки. Даты в низу таблицы будут урезаться и быть декоративным элементом. 
Уменьшение самой таблицы здесь не должно делаться, вместо этого должна быть возможность 
увеличения таблицы.  

Как сделать так, чтобы можно было добавлять и удалять линии?
Можно сделать сейт или ref с массивом. Массив заполнить нулями, при выведении сверять по индексу 
значения из ref и значения из массива с данными для линий. Если в ref по индексу не 0, 
то линия не выводится. При нажатии на кнопку с подписью или линию меняем ref и по всем индексам, 
кроме индекса элемента, записываем 1. 

Если 1, то нужно не удалять, а изменять opacity, чтобы можно было плавно сделать ненужные элементы невидимыми. 

Проблема с несоответствием индексов по какой то причине удаляется из массива элемент под индексом 0. 
Для рисования линий используется массив dataArr (8), для создания подписей используется массив data[data.length - 2] (8),
т.е. предпоследний массив с названиями подписей. 
Проблем с массивом подписей вроде нет, похоже что какая то проблема с dataArr.
dataArr[0][0] = 4;
data[1][0] = 4;
код цвета по адресу data[0][0] действительно синеватый и принадлежит первому элементу
*/

/*
можно ли сделать страничку, которая должна открываться в случаях:
1. ошибки самого приложения;
2. невыполненного fetch;
3. в случае отсутствия подключения к интернету; 

Fetch может не выполниться в случае:
1. нет интернета
2. выброшен reject
3. сервер сам не отправляет данные. 

В случае ошибки самого приложения сайт либо вообще остановится и выведет ошибку, 
либо продолжит работу, но какие то функции выполняться не будут. 
Невозможно узнать где именно произойдет ошибка, к тому же при ошибке сайт полностью прекращает свою работу. 
В случае fetch сайт может отправить обратно сообщение с кодом ошибки, а это отловить уже будет сложно. 
К тому же нет доступа к коду сервера. 

Из всех варантов в итоге можно сделать только если fetch не вернул ответ или стал rejected. 
Стоит ли в таком случае вообще делать отдельную страницу? 

*/
