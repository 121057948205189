import { useRef, useState, useEffect} from 'react';
//import './Form.css';

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeTr from 'air-datepicker/locale/tr';

//import StoreCalendar from './classStoreCalendar';

function selectionDate (propTimepicker, dataOfTemplate) {
  let selectedDateTimeVariable;
  if (!propTimepicker) {
  //selectedDateTime = FormTableRepository.formInputsState[name].split('.').reverse().join('-');
    let v = dataOfTemplate;
    selectedDateTimeVariable = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1];
  } else {
    let v = dataOfTemplate;
    console.log('calendar selectionDate v', v);
    selectedDateTimeVariable = v[6] + v[7] + v[8] + v[9] + '-'  + v[3] + v[4] + '-'  + v[0] + v[1] + ' ' + v[11] + v[12] + v[13] + v[14] + v[15];
  }
  return selectedDateTimeVariable;
}

function checkAndChangeDate (event, refDate, index, type, setflag, dataOfTemplate, setdataOfTemplate, maxCalendarDate) {
  if(event.target.value.length == 16 && type == "TARIH_SAAT") {
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.' && event.target.value[10] == ' ' && event.target.value[13] == ':';
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let hourValue = String(event.target.value[11]) + String(event.target.value[12]);
    let minuteValue = String(event.target.value[14]) + String(event.target.value[15]);
    let checkTheNaN = dataValue + monthValue + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9] + hourValue + minuteValue;
    checkTheNaN = Number.isNaN(Number(checkTheNaN));

    if(checkTheNaN || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 ||
    Number(hourValue) > 23 || Number(hourValue) < 0 ||
    Number(minuteValue) > 59 || Number(minuteValue) < 0 || !chekingPointsFlag) {
      if (dataOfTemplate[index + 1] == '') {
        event.target.value = '';
        setflag(false);
        return;
      } else {
        event.target.value = '';
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
        setflag(false);
        return;
      }

    }
  } else if (event.target.value.length == 10 && type == "TARIH") {
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.';
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let checkTheNaN = dataValue + monthValue + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9];
    console.log('calendar input onBlur checkTheNaN', checkTheNaN);

    checkTheNaN = Number.isNaN(Number(checkTheNaN));
    console.log('calendar input onBlur checkTheNaN', checkTheNaN);
    if(checkTheNaN || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 ||
    !chekingPointsFlag) {
      if (dataOfTemplate[index + 1] == '') {
        event.target.value = '';
        setflag(false);
        return;
      } else {
        event.target.value = '';
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
        setflag(false);
        return;
      }
    }
  } else {
    console.log('calendar input event', event.target.value);

    event.target.value = '';
    setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
    setflag(false);
    return;
    
  }

  if (maxCalendarDate != false) {
  
    let dateEvent = [event.target.value[0] + event.target.value[1], event.target.value[3] + event.target.value[4], event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9]];
    let dateMax = [maxCalendarDate[8] + maxCalendarDate[9], maxCalendarDate[5] + maxCalendarDate[6], maxCalendarDate[0] + maxCalendarDate[1] + maxCalendarDate[2] + maxCalendarDate[3]];

    let dateExcess = false; 
    for (let i = 2; i >= 0; i--) {
      if (dateEvent[i] > dateMax[i]) {
        dateExcess = true;
      }
      if ((dateEvent[i] < dateMax[i])) {
        break;
      }
    }
    
    if (dateExcess) {
      if (event.target.value.length == 16) {
        event.target.value = maxCalendarDate[8] + maxCalendarDate[9] + '.' + maxCalendarDate[5] + maxCalendarDate[6] + '.' + maxCalendarDate[0] + maxCalendarDate[1] + maxCalendarDate[2] + maxCalendarDate[3] + ' 23:59';
      } else {
        event.target.value = maxCalendarDate[8] + maxCalendarDate[9] + '.' + maxCalendarDate[5] + maxCalendarDate[6] + '.' + maxCalendarDate[0] + maxCalendarDate[1] + maxCalendarDate[2] + maxCalendarDate[3];
      }
    }
  }

  let variableSelectionDate = selectionDate (type != 'TARIH' ? true : false, event.target.value);

  if (refDate.current.viewDate.getFullYear() != (event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9])) {
    refDate.current.setViewDate(variableSelectionDate);
  }
  refDate.current.selectDate(variableSelectionDate, {updateTime: true, silent: true});
  setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), event.target.value, ...dataOfTemplate.slice(index + 2)]);
  setflag(true);

}

function Calendar({name, setdataOfTemplate, index, type, rangeData, dataOfTemplate, selectedDateTime, maxCalendarDate}) {

const [flag, setflag] = useState(!!selectedDateTime);
//const [flag, setflag] = useState(true);

const inputDate = useRef();
const refDate = useRef();

console.log('calendar dataOfTemplate', dataOfTemplate);

let buttonClose = {
  content: 'Kapalı',
  onClick: function (dp) {
    dp.hide();
  }
}
console.log('calendar maxCalendarDate', maxCalendarDate);

useEffect(() => {
  refDate.current = new AirDatepicker(inputDate.current, {
    locale: localeTr,
    timeFormat: 'HH:mm',
    keyboardNav: false,
    range: rangeData,
    multipleDatesSeparator: ' - ',
    timepicker: type != 'TARIH' && true,
    firstDay: 0,
    showEvent: "focus",
    buttons: ['clear', buttonClose],
    isMobile: false,
    maxDate: maxCalendarDate,
    selectedDates: selectedDateTime == false ? false : [selectionDate (type != 'TARIH' ? true : false, selectedDateTime)],
    onSelect: function ({date, formattedDate, datepicker}) {
      if (formattedDate != undefined) {
        //setdataOfTemplate([...(StoreCalendar.state).slice(0, index + 1), formattedDate, ...StoreCalendar.state.slice(index + 2)]);
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), formattedDate, ...dataOfTemplate.slice(index + 2)]);
      } else {
        //setdataOfTemplate([...(StoreCalendar.state).slice(0, index + 1), '', ...StoreCalendar.state.slice(index + 2)]);
        setdataOfTemplate([...dataOfTemplate.slice(0, index + 1), '', ...dataOfTemplate.slice(index + 2)]);
      }
      if (formattedDate?.length != 0 && formattedDate?.length != undefined) {
        setflag(true);
      } else {
        setflag(false);
      }
      
    },
  });
}, []);



let inputDateTag =  <input ref={inputDate} placeholder={`${name}`} className={`${flag == false ? 'inactiveinputFormTable' : 'activeinputFormTable'}`} 

  onChange={function(event) {
    console.log('calendar input event.nativeEvent.data', event.nativeEvent.data);
    if (event.nativeEvent.data != null) {
      if (type == "TARIH_SAAT") {
        switch (event.target.value.length) {
          case 2: 
            event.target.value+= '.';
          break;
          case 5: 
            event.target.value+= '.';
          break;
          case 10: 
            event.target.value+= ' ';
          break;
          case 13: 
            event.target.value+= ':';
          break;
        }
      } else {
        switch (event.target.value.length) {
          case 2: 
            event.target.value+= '.';
          break;
          case 5: 
            event.target.value+= '.';
          break;
        }
      }
    }

  }}  

  onBlur={function(event) {
    console.log('onBlur сработал');
    if (refDate.current != undefined && event.target.value != dataOfTemplate[index + 1]) {
      checkAndChangeDate (event, refDate, index, type, setflag, dataOfTemplate, setdataOfTemplate, maxCalendarDate);
    }
  }}

  onKeyDown={function(event) {
    console.log('calendar input event', event.target.value, dataOfTemplate[index + 1]);
    if (refDate.current != undefined && event.target.value != dataOfTemplate[index + 1] && event.keyCode == 13) {
      checkAndChangeDate (event, refDate, index, type, setflag, dataOfTemplate, setdataOfTemplate, maxCalendarDate);
    }

  }}
  onClick={() => console.log('calendarForForms flag', flag)}
  maxLength={type == "TARIH_SAAT" ? 16 : 10}
  />;

  return (
    inputDateTag

  );
}

export default Calendar;

/*
Как определить когда данные из input удаляются, а когда добавляются? 
Нужно стравнить длину значения в input до и после события. 
*/

/*
Нужно унифицировать календарь, сделать так, чтобы его можно было подставить в любой компонент. 

Нужно переписать код так, чтобы не было необходимости использования дополнительных элементов. 
В календаре хранилище используется по той причине, что стейт, который передается через пропсы не 
изменяется. Поэтому используется хранилище, которое изменяется вместе с изменением стейта. 
Но почему? Разве при обновлении родительского стейта в дочерний элемент не должен ложиться обновленный 
стейт? 
Нужно проверить. 
Теперь нет необходимости использовать хранилище для календаря, можно использовать просто стейт. 

setflag([false]);
*/


/*
Здесь проблемы с условием для определения старшей даты. 
Здесь надо сравнить год, месяц и день. 
Нужно проверить последовательно все три сравнения, и если 
при последовательном сравнении хоть один из них вернет true, 
то запускать код. 
Ключевое слово последовательное сравнение.
Нужно сравнить:
1. год, если год больше, то запускаем код, если равен, то идем дальше.
2. месяц, если месяц больше, то запускаем код, если равен, то идем дальше.
3. день, если день больше, то запускаем код. 

*/

/*
Описание компонента. 

Данный компонент выводит input и calendar при нажатии на input. 
Для выведения календаря используется библиотека AirDatepicker, потому что 
стандартный календарь плохо выглядит и плохо работает. 

В хуке useEffect делается привязка к input объекта из библиотеки AirDatepicker. 
Там же делается настройка параметров объекта.


Функционал блокирования выбора более поздней даты, чем дата этого дня, в компоненте calendarForForms. 

Если в компоненте secondForm выводится calendar, то начальная дата не может превыщать дату этого дня, 
потому что сервер не присылает ответ по такому запросу. 
Поэтому нужен код, который при превышении даты сегодняшнего дня будет всегда возвращать дату этого дня. 

Функционал включается если в props maxCalendarDate передано значение, которое не равно false. 

В параметр maxDate во время создания объекта calendar кладется этот props, и в самом объекте 
блокируется возможность выбрать более позднюю дату. 

Чтобы заблокировать возможность выбора более поздней даты в input, в функцию checkAndChangeDate 
кладется props maxCalendarDate. 

Алгоритм блокировки более поздней даты: 
1. В компоненте secondForm делаем ref currentDate, в который записываем объект с датой этого дня. 
   Также создаем переменную maxDate, в которую записываем значение false.

2. Делаем проверку на то, является ли данный компонент нужным. Если его имя 'İlk Tarih', 
   то это нужный компонент. В таком случае записываем в переменную maxDate значение true.
   
3. Вызываем компонент calendarForForms и в один из props кладем значение переменной maxDate. 
   Далее все действия происходят в компоненте calendarForForms.

4. делается проверка на отсутствия значения false в maxCalendarDate. 

5. создаются переменные dateEvent и dateMax, в которые кладутся массивы с датами. Также создается 
   переменная dateExcess, в которую записывается false. 

6. далее нужно сравнить данные массивов в строгом порядке и со сложными условиями. 
   Чтобы это было просто и читаемо, данное сравнение делается в цикле. Если вводимая в input 
   дата позже, чем этот день, то в переменную dateExcess записывается true.

7. Если в переменной dateExcess лежит true, то выполняется условие, при котором значение input 
   перезаписывается значением из maxCalendarDate.



*/
