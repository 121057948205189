
import { useEffect, useState, useRef, useMemo, useSyncExternalStore} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box } from '@mui/material';

import Bufer_AltForms_Table from './classBufer_AltForms_Table';
import DinamikRaporAltTable from './DinamikRaporAltTable';
import MapComponent_AltForms from './MapComponent_AltForms';

//import StoreRequest from '../../formComponent/classStoreRequestForm';



function DinamikRapor_AltForms ({id}) {
  const [componentUpdate, setcomponentUpdate] = useState([0]);
  const refComponentUpdate = useRef(0);
  const refIndexOfBuferStore = useRef(0);

  
  useEffect(function() {
    Bufer_AltForms_Table.refVariable[0] = false;
    Bufer_AltForms_Table.stateVariable[0] = setcomponentUpdate;

    return function () {
      Bufer_AltForms_Table.refVariable[0] = false
      Bufer_AltForms_Table.stateVariable[0] = 0;
      Bufer_AltForms_Table.store = [0];
    };
  }, []);

  let returnForm; 

  if (Bufer_AltForms_Table.store[0] != 0) {
    switch (Bufer_AltForms_Table.store[refIndexOfBuferStore.current].FORM_TURU){
      case "TABLE":
        returnForm = <DinamikRaporAltTable id={id} indexOfBuferStore={refIndexOfBuferStore.current} />
      break;
      case "HARITA-1":
        returnForm = <>
          <div className='map'></div>
          <MapComponent_AltForms class={'map'} HARITA_DATA={Bufer_AltForms_Table.store[refIndexOfBuferStore.current]} />
        </>
      break;
    }
  }
  
  console.log('DinamikRapor_AltForms Bufer_AltForms_Table.store', Bufer_AltForms_Table.store);


  let AltForm = <><div className='signboard_middleComponentForThirdForm' > 
  {Bufer_AltForms_Table.store.map( function (item, index) {
    console.log('DinamikRapor_AltForms item.FORM_ADI', item.FORM_ADI);
    let variable = refIndexOfBuferStore.current == index;
    return <div className='stickerAlt' 
    style={{borderBottom: `${variable ? '1px solid #383839' : 'none'}`, color: `${variable ? '#4fa2fc' : '#383839'}`}}
    onClick={function() {
      /*
      if (refIndexOfBuferStore.current == index) {
        return;
      }
      */
      
      if (refIndexOfBuferStore.current != index) {
        refIndexOfBuferStore.current = index;
        setcomponentUpdate([0]);
      }

      //updateComponent();
    }}>
      <p className='stickerHeader'> {item.FORM_ADI} </p>
    </div>
    
    })}
  </div>
  {returnForm}
  </>;

  



  return  <>{Bufer_AltForms_Table.refVariable[0] && AltForm}</>;
};

export default DinamikRapor_AltForms;

//Bufer_AltForms_Table.store[0] = 0
