import './WeHelp.css';
import './WeHelpAdaptive.css';
import WeHelpDiagramOne from './WeHelpDiagramOne.js';
import WeHelpDiagramMultiple from './WeHelpDiagramMultiple.js';
//import WeHelpDiagramLine from './WeHelpDiagramLine.js';
import WeHelpDiagramLine from './WeHelpDiagramLineInfo.js';
import svgLoader from '../../loader/loader.js';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import DiagrammData_Wehelp_Dashboard from './classDiagrammData_Wehelp_Dashboard.js'
import transformationDiagrammData from './sortOfDiagramms.js'

import adress from '../../adress.js'


const color_1 = '#34C6CD';
const color_2 = '#FF3500';
const color_3 = '#B1F100';
const color_4 = '#FFF500';
const color_5 = '#7908AA';
const color_6 = '#FF7340';
const color_7 = '#FFAB00';
const color_8 = '#009E66';
const color_9 = '#AA66CC';

const colorArr = [color_1, color_2, color_3, color_4, color_5, color_6, color_7, color_8, color_9];

function WeHelp_DashboardPage() {
  const token = useSelector((state) => state.tokenOfSession.token);
  const [diagrammDataFlag, setdiagrammDataFlag] = useState(0);

  
  if (diagrammDataFlag == 0 && DiagrammData_Wehelp_Dashboard.data === 0) {
    //fetch(`https://creaapi.com/crealog/CrealogMaster.svc/GetDasboards/?sessionId=${token}&formId=4873`)
    //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetDasboards/?sessionId=${token}&formId=4873`)
    fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetDasboards/?sessionId=${token}&formId=4873`)
    .then(response => response.json())
    .then(function (json) {
      DiagrammData_Wehelp_Dashboard.data = transformationDiagrammData (json.GetDasboardsResult, colorArr);
      setdiagrammDataFlag(1);
      console.log('fetch', json);
      console.log('fetch вызвано');
    });
  } 
  console.log(' DiagrammData_Wehelp_Dashboard.data',  DiagrammData_Wehelp_Dashboard.data);

  let diagramArr;
  if (DiagrammData_Wehelp_Dashboard.data !== 0) {
    diagramArr = [<label className='description'>
      Genel Departman Adetler
      <input className='input_wehelppage' type='checkbox'/>
      </label>,
    <div className='diagram0 oneDimensionalDiagram'>
      <WeHelpDiagramOne data={DiagrammData_Wehelp_Dashboard.data[0].diagrammPoints} maxY={DiagrammData_Wehelp_Dashboard.data[0].maxY} />
    </div>,

    <label className='description'>
      Türe Göre Departman Adetler
      <input className='input_wehelppage' type='checkbox'/>
      </label>,
    <div className='diagram1 multidimensionalDiagram'>
      <WeHelpDiagramMultiple data={DiagrammData_Wehelp_Dashboard.data[1].diagrammPoints.data}  
      signature={DiagrammData_Wehelp_Dashboard.data[1].diagrammPoints.signature} 
      nameColumnGroup={DiagrammData_Wehelp_Dashboard.data[1].diagrammPoints.nameColumnGroup} 
      maxY={DiagrammData_Wehelp_Dashboard.data[1].maxY} />
    </div>,

    <label className='description_lineDiagram'>
      Günlük Departman Adetler
      <input className='input_wehelppage' type='checkbox'/>
      </label>,
    <div className='diagram2 lineDiagramInfo' >{<WeHelpDiagramLine data={DiagrammData_Wehelp_Dashboard.data[2].diagrammPoints} maxY={DiagrammData_Wehelp_Dashboard.data[2].maxY}/>}</div>,

    <label className='description'>
      Haftalık Departman Adetler
      <input className='input_wehelppage' type='checkbox'/>
      </label>,
    <div className='diagram3 multidimensionalDiagram'>
      <WeHelpDiagramMultiple data={DiagrammData_Wehelp_Dashboard.data[3].diagrammPoints.data}  
      signature={DiagrammData_Wehelp_Dashboard.data[3].diagrammPoints.signature} 
      nameColumnGroup={DiagrammData_Wehelp_Dashboard.data[3].diagrammPoints.nameColumnGroup} 
      maxY={DiagrammData_Wehelp_Dashboard.data[3].maxY} />
    </div>,

    <label className='description'>
      Aylık Departman Adetler
      <input className='input_wehelppage' type='checkbox'/>
      </label>,
    <div className='diagram4 multidimensionalDiagram'>
      <WeHelpDiagramMultiple data={DiagrammData_Wehelp_Dashboard.data[4].diagrammPoints.data}  
      signature={DiagrammData_Wehelp_Dashboard.data[4].diagrammPoints.signature} 
      nameColumnGroup={DiagrammData_Wehelp_Dashboard.data[4].diagrammPoints.nameColumnGroup} 
      maxY={DiagrammData_Wehelp_Dashboard.data[4].maxY} />
    </div>,

    <label className='description'>
      Yıllık Departman Adetler
      <input className='input_wehelppage' type='checkbox'/>
      </label>,
    <div className='diagram5 multidimensionalDiagram'>
      <WeHelpDiagramMultiple data={DiagrammData_Wehelp_Dashboard.data[5].diagrammPoints.data}  
      signature={DiagrammData_Wehelp_Dashboard.data[5].diagrammPoints.signature} 
      nameColumnGroup={DiagrammData_Wehelp_Dashboard.data[5].diagrammPoints.nameColumnGroup} 
      maxY={DiagrammData_Wehelp_Dashboard.data[5].maxY} />
    </div>
    ];
  } else {
    diagramArr = svgLoader;
  }
  

  return <>
    {diagramArr}
  </>
}

export default WeHelp_DashboardPage;


//    <div className='diagram2 lineDiagram' style={{paddingTop: '50px', paddingBottom: '80px', height: '800px', overflow: 'visible'}}>{<WeHelpDiagramLine data={DiagrammData_Wehelp_Dashboard.data[2].diagrammPoints} maxY={DiagrammData_Wehelp_Dashboard.data[2].maxY}/>}</div>,
