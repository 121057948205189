import { useState, useEffect, startTransition, useRef } from 'react';
import './header1.css';
import './headerAdaptive.css';
import StoreRequestTypeComponent from '../Components/classRequestLimiter';
import { useDispatch, useSelector } from 'react-redux';



function TopMenu({arrOfTopMenu, setarrOfTopMenu}) {

const nameOfPage = useSelector((state) => state.changingPageName.pageName);
const dispatch = useDispatch();
const token = useSelector((state) => state.tokenOfSession.token);

const [arrOfLincsTopMenu, setarrOfLincsTopMenu] = useState([]); 
const [flagOfTopMenuButtons, setflagOfTopMenuButtons] = useState(0);

const refConteinerOfTopMenu = useRef(0);
const refFlagConteiner = useRef([false, 0, 0]);

useEffect( function () {
  console.log('TopMenu arrOfTopMenu[0]', arrOfTopMenu);
  console.log(arrOfLincsTopMenu.flat().includes(arrOfTopMenu[0]));
  if (arrOfTopMenu[0] != 'initKey' && !arrOfLincsTopMenu.flat().includes(arrOfTopMenu[0])) {
    setarrOfLincsTopMenu([...arrOfLincsTopMenu, arrOfTopMenu]);
    setarrOfTopMenu(['initKey', 0]);
  }
} , [arrOfTopMenu]);

const screenWidth = window.screen.width;
const widthLink = 250;
const coeffOfTopMenu = Math.floor(screenWidth / widthLink);

function deleteLink (event) {
  setarrOfLincsTopMenu(arrOfLincsTopMenu.filter( item => item[0] != (event.target.className).split(' _')[1]));
  if (nameOfPage != 'homePage') {
    dispatch({type: 'CHANGE_PAGE_NAME', pageName: 'homePage' });
  }
  if (flagOfTopMenuButtons > widthLink) {
    setflagOfTopMenuButtons(flagOfTopMenuButtons - widthLink);
  } else if (flagOfTopMenuButtons < 0) {
    setflagOfTopMenuButtons(flagOfTopMenuButtons + widthLink);
  }
  
}

function transitiontoAnotherTab (event) {
  sessionStorage.setItem('pageName',  String((event.target.className).split(' _')[1]));
  sessionStorage.setItem('token',  token);
  console.log('transitiontoAnotherTab', (event.target.className).split(' _'));
  if (event.target.className.split(' _')[1] == '96_harita') {
    window.open(`https://www.createch.com.tr/cl/harita/${token}`, '_blank');  
  } else {
    window.open(window.location.href, '_blank');  
  }
  sessionStorage.removeItem('pageName');
  sessionStorage.removeItem('token');
}

function processinglinksOfTopMenu (arr) {
  let variableOfLinks = [];
  for (let elem of arr) {
    let styleOfTopLinks = nameOfPage != elem[0] ? {opacity: '0.75'} : {borderBottom: '1px solid black'};
    variableOfLinks.push(
      <div className = {`linksOfTopMenu _${elem[0]}`} style={styleOfTopLinks} key={String(elem[0])}> 
        <p className={`textLinkOfMenu _${elem[0]}`} onClick={function(event) {
          dispatch({type: 'CHANGE_PAGE_NAME', pageName: `${(event.target.className).split(' _')[1]}` });
          console.log('topMenu processinglinksOfTopMenu elem', elem[2]);
          StoreRequestTypeComponent.pageType = elem[2];
          
          }} key={String(elem[0]) + "0"}> {elem[1]}-{elem[0]} </p>
        <div key={String(elem[0]) + "1"}>
          <div className={`buttonOfTransition _${elem[0]}`} onDoubleClick={(event) => transitiontoAnotherTab(event)} key={String(elem[0]) + "2"}>&#10064;</div>
          <div className={`deleteButton _${elem[0]}`} onDoubleClick={(event) => deleteLink (event)} key={String(elem[0]) + "3"}>&#215;</div>
        </div>
      </div>
    );
  }
  return variableOfLinks;
}

const arraylinksOfMenu = processinglinksOfTopMenu (arrOfLincsTopMenu);



if (arraylinksOfMenu.length <= coeffOfTopMenu && flagOfTopMenuButtons != 0) {
  setflagOfTopMenuButtons(0)
}

const topMenu = <div className='top_menu'>
  <div className='conteinerOfTopMenu' 
  style={{display: 'flex'}}
  ref={refConteinerOfTopMenu} 
  onMouseDown={(event) => {
    //console.log('TopMenu', refConteinerOfTopMenu.current.scrollLeft, event.clientX);
    console.log('TopMenu', refConteinerOfTopMenu);
    refFlagConteiner.current = [true, event.clientX, refConteinerOfTopMenu.current.scrollLeft];
    }}
  onMouseUp={(event) => {
    console.log('TopMenu', refFlagConteiner.current);
    refFlagConteiner.current = [false, 0, 0];
    }}
  onMouseLeave={(event) => {
    if (refFlagConteiner.current[0]) {
      console.log('TopMenu', refFlagConteiner.current);
      refFlagConteiner.current = [false, 0, 0];
    }
  }}
  onMouseMove={(event) => {
    //console.log('TopMenu', refConteinerOfTopMenu.current.scrollLeft, event.clientX);
    //refFlagConteiner.current = [true, event.clientX];
    
    if (refFlagConteiner.current[0]) {
      refConteinerOfTopMenu.current.scrollLeft = refFlagConteiner.current[1] - event.clientX + refFlagConteiner.current[2];
      //refConteinerOfTopMenu.current.scrollLeft += 10;

      //refConteinerOfTopMenu.current.scrollLeft = refFlagConteiner.current[1] - event.clientX + refFlagConteiner.current[2];
      console.log('TopMenu', refConteinerOfTopMenu.current.scrollLeft, event.clientX);
      console.log('TopMenu', refFlagConteiner.current[1] - event.clientX + refFlagConteiner.current[2]);

    }
  
  }}>
    {arraylinksOfMenu}
  </div>
</div>
console.log('flagOfTopMenuButtons', flagOfTopMenuButtons);
return topMenu;

}

export default TopMenu;
