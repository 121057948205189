class MapRepository {
  static data = 0;

}

export default MapRepository;

/*
этот класс предназначен исключительно для хранения данных карты.
*/
