class StoreTables {
  static tables = {
    
  };

}

export default StoreTables;

/*
С какими компонентами связан store:
1. в preTable и в preTableChild происходит запись объектов. После этого компоненты обновляются;
2. в preTable и в preTableChild store попадает второй раз. Если по нужному ключу store не 
  возвращает undefined, то вызывается компонент Table;

- в altForm хранится информация о возможности открытия новой таблицы при двойном клике
- в buttonsForm хранится информация о возможности использования кнопок добавления, изменения, удаления.
*/
