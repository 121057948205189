import { useRef, useState } from 'react';
import './FormTable.css';

import { useSelector } from 'react-redux';
import ChangeRepository from './classChangeRepository';
import FormTableRepository from './classFormTableRepository';
import FormTable from './FormTable';
import StoreRequest from './classStoreRequest';
import TableStack from './classTableStack';
import StoreTables from './classStoreTables';

import saveSelectedRowsBetweenMounts from './saveSelectedRowsBetweenMounts';

import adress from '../../adress.js'


function changingTheStateOfTheFields (indicatorOfCompletionOfFields, key, i, rerenderPreFormTable, setRerenderPreFormTable) {
  if (ChangeRepository.add[key][i]?.BAGLI_KOLON_MASTER == 1) {
    return <FormTable group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} rerenderPreFormTable={false} setRerenderPreFormTable={setRerenderPreFormTable} condition={false}/>;
  } else if (ChangeRepository.add[key][i]?.BAGLI_KOLON_MASTER != 1 && ChangeRepository.add[key][i]?.BAGLI_KOLON_ID != 0) {
    let condition = false;
    let variableForIndexForTheGroupOfDependentFields = ChangeRepository.add.indexForTheGroupOfDependentFields?.[rerenderPreFormTable[0]]?.[String(key)+ '_' +String(i)];
    console.log('preFormTable changingTheStateOfTheFields variableForIndexForTheGroupOfDependentFields', variableForIndexForTheGroupOfDependentFields);

    if (variableForIndexForTheGroupOfDependentFields == true && rerenderPreFormTable[1] == ChangeRepository.add[key][i].BAGLI_KOLON_DEGER) {
      condition = ChangeRepository.add[key][i].BAGLI_KOLON_DEGER_ESITSE;
    } else if (variableForIndexForTheGroupOfDependentFields == true && rerenderPreFormTable[1] != ChangeRepository.add[key][i].BAGLI_KOLON_DEGER) {
      condition = ChangeRepository.add[key][i].BAGLI_KOLON_DEGER_ESIT_DEGILSE;
    }
    if (condition == "SIFIRLA") {
      let variableForKey = String(key)+ '_' +String(i);
      console.log("SIFIRLA", FormTableRepository.formInputsState[variableForKey]);
      FormTableRepository.formInputsState[variableForKey] = '';
      return <FormTable key={variableForKey} group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} rerenderPreFormTable={false} setRerenderPreFormTable={false} condition={condition} />;

    }
    if (condition != 'GIZLE') {
      return <FormTable group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} rerenderPreFormTable={false} setRerenderPreFormTable={false} condition={condition} />;
    }

  } else if (ChangeRepository.add[key][i]?.BAGLI_KOLON_MASTER != 1 && ChangeRepository.add[key][i]?.BAGLI_KOLON_ID == 0) {
    return <FormTable group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} rerenderPreFormTable={false} setRerenderPreFormTable={false} condition={false}/>;

  }
}

function inputsWrapping (indicatorOfCompletionOfFields, rerenderPreFormTable, setRerenderPreFormTable) {
  let arr = [];
  for (let key in ChangeRepository.add) {
    if (key != 'count' && key != 'ID' && key != 'justWatch' && key != 'indexForTheGroupOfDependentFields') {
      let arr1 = [];
      for (let i = 0; i < ChangeRepository.add[key].length; i++) {
        if (ChangeRepository.add.ID != '0' && (ChangeRepository.add[key][i].KOLON_DEGER !== null && ChangeRepository.add[key][i].KOLON_DEGER !== undefined && ChangeRepository.add[key][i].KOLON_DEGER !== '')) {
          FormTableRepository.formInputsState[String(key) + '_' + String(i)] = ChangeRepository.add[key][i].KOLON_DEGER;
        }
        if (ChangeRepository.add.justWatch == false) {
          if (!ChangeRepository.add[key][i].KOLON_KITLI) {
            if(ChangeRepository.add.ID == 0 || ChangeRepository.add.indexForTheGroupOfDependentFields.count == 0) {
              //arr1.push(<FormTable group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} />);
              arr1.push(<FormTable group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} rerenderPreFormTable={false} setRerenderPreFormTable={false} condition={false}/>);
            } else {
              console.log('preformtable inputsWrapping changingTheStateOfTheFields применяется');
              arr1.push(changingTheStateOfTheFields (indicatorOfCompletionOfFields, key, i, rerenderPreFormTable, setRerenderPreFormTable));
            }
          }
        } else {
          if(ChangeRepository.add.ID == 0 || ChangeRepository.add.indexForTheGroupOfDependentFields.count == 0) {
            //arr1.push(<FormTable group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} />);
            arr1.push(<FormTable group={key} i={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} rerenderPreFormTable={false} setRerenderPreFormTable={false} condition={false}/>);
          } else {
            console.log('preformtable inputsWrapping changingTheStateOfTheFields применяется');
            arr1.push(changingTheStateOfTheFields (indicatorOfCompletionOfFields, key, i, rerenderPreFormTable, setRerenderPreFormTable));
          }
        }

        

      }
      arr.push(<>
      <label>
        <p> {key} </p>
        <input type='checkbox' />
      </label>
      <div className='groupFormTable'>{arr1}</div></>);
    }
  }
  return arr;
}


function preFormTable({setChangeStateOfPreTablePageWrapping}) {

const token = useSelector((state) => state.tokenOfSession.token);
let indicatorOfCompletionOfFields = useRef(true);
const [rerenderPreFormTable, setRerenderPreFormTable] = useState([0, 0]);
/*
stateFormId нужен для очистки dataOfTemplate при переходе между страницами
*/

let sendButton = <div className='ekleFormTable' onClick={function () {
  console.log(ChangeRepository.add);
  let str = `${token}*`;
  let parentId;
  let parentLine;
  let tableID;


  for (let key in ChangeRepository.add) {
    if (key != 'count' && key != 'ID' && key != 'indexForTheGroupOfDependentFields' && key != 'justWatch') {
      str+= `${ChangeRepository.add[key][0].LS_FORM_RAPOR_MASTER_ID}*`;
      tableID = ChangeRepository.add[key][0].LS_FORM_RAPOR_MASTER_ID;
      console.log('ChangeRepository.add[key][0].LS_FORM_RAPOR_MASTER_ID', ChangeRepository.add[key]);
      console.log(key);

      if (!ChangeRepository.add[key][0].flagMainTable) {
        parentId = ChangeRepository.add[key][0].parentId;
        parentLine = ChangeRepository.add[key][0].parentLine;
      } else {
        parentId = '0';
        parentLine = '0';
      }
      break;
    }
  }

  console.log(ChangeRepository.add);
  let str2 = [];

  for (let key in ChangeRepository.add) {
    if (key != 'count' && key != 'ID' && key != 'justWatch') {

    let defaultType; 
      for (let i = 0; i < ChangeRepository.add[key].length; i++) {
        if (ChangeRepository.add[key][i].KOLON_TIPI == "TARIH_SAAT" || ChangeRepository.add[key][i].KOLON_TIPI == "TARIH") {
          defaultType = 'null';
        } else {
          defaultType = '0';
        }
        
        if (ChangeRepository.add[key][i].KOLON_KITLI) {

          if (str[str.length - 1] === '*') {
            str+= `${ChangeRepository.add[key][i].KOLON_ADI};${ChangeRepository.add[key][i].KOLON_DEGER ?? defaultType}`;
          } else {
            str+= `|${ChangeRepository.add[key][i].KOLON_ADI};${ChangeRepository.add[key][i].KOLON_DEGER ?? defaultType}`;
          }
        } else if (ChangeRepository.add[key][i].BOS_OLABILIRMI == 0) {
          if (!FormTableRepository.formInputsState[String(key) + '_' + String(i)]) {
            indicatorOfCompletionOfFields.current = false;
            setRerenderPreFormTable([0]);
            return;
          }
          if (str[str.length - 1] === '*') {
            str+= `${ChangeRepository.add[key][i].KOLON_ADI};${FormTableRepository.formInputsState[String(key) + '_' + String(i)] ?? defaultType}`;
          } else {
            str+= `|${ChangeRepository.add[key][i].KOLON_ADI};${FormTableRepository.formInputsState[String(key) + '_' + String(i)] ?? defaultType}`;
          }
        } else {
          if (FormTableRepository.formInputsState[String(key) + '_' + String(i)] ?? false) {  
            if (str[str.length - 1] === '*') {
              str+= `${ChangeRepository.add[key][i].KOLON_ADI};${FormTableRepository.formInputsState[String(key) + '_' + String(i)]}`;
            } else {
              str+= `|${ChangeRepository.add[key][i].KOLON_ADI};${FormTableRepository.formInputsState[String(key) + '_' + String(i)]}`;
            }
          }
        }
      }
    }
  }
  str += `*${parentId}*${parentLine}`;
  console.log(str);

  
  //fetch('https://creaapi.com/crealogKlon/CrealogMaster.svc/SaveForm', {
  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/SaveForm`, {
    method: 'POST', // Здесь так же могут быть GET, PUT, DELETE
    body: str, // Тело запроса в JSON-формате

  })
  .then((response) => {
    console.log('запрос отправлен', response.ok);
    if (parentId == '0') {
      StoreRequest.store[tableID] = 0;
      ChangeRepository.checkingDatabaseValue[tableID][`${TableStack.lastLines[tableID]}_${tableID}`] = false;
      StoreTables.tables[tableID] = 0;
    } else {
      let variableChildId = `${parentId}_${parentLine}_child`;
      StoreRequest.store[`${parentId}_${parentLine}_child`] = 0;
      ChangeRepository.checkingDatabaseValue[variableChildId][TableStack.lastLines[variableChildId]] = false;
      StoreTables.tables[variableChildId] = 0;
    }
    
    /*
    здесь мы удаляем данные таблицы,
    чтобы она обновилась и отобразила новые данные
    */
   /*
    console.log('ChangeRepository.checkingDatabaseValue[tableID][TableStack.lastLines[tableID]]', ChangeRepository.checkingDatabaseValue);
    if (parentId == '0') {
      ChangeRepository.checkingDatabaseValue[tableID][`${TableStack.lastLines[tableID]}_${tableID}`] = false;
    } else {
      let variableChildId = `${parentId}_${parentLine}_child`;
      ChangeRepository.checkingDatabaseValue[variableChildId][TableStack.lastLines[variableChildId]] = false;
    }
    */
    ChangeRepository.add = [];
    FormTableRepository.formInputsState = {};
    saveSelectedRowsBetweenMounts();
    setChangeStateOfPreTablePageWrapping([0]);
  })
    
}}> Ekle </div>;

let form = <div className='FormTable'>
  <div className='closeFormTable' onClick={function () {
    ChangeRepository.add = [];
    FormTableRepository.formInputsState = {};
    saveSelectedRowsBetweenMounts();
    setChangeStateOfPreTablePageWrapping([0]);
  }}> x </div>
  {inputsWrapping (indicatorOfCompletionOfFields.current, rerenderPreFormTable, setRerenderPreFormTable)}
  {indicatorOfCompletionOfFields.current === false && <div className='reminderToFillOutAllFields'>
    Lütfen gerekli tüm alanları doldurup tekrar deneyin.  
  </div>}
  {ChangeRepository.add.justWatch == false && sendButton}
</div>


  return form;
}

export default preFormTable;

/*

Алгоритм сохранения данных картинок и pdf. 
1. Нажимается кнопка из компонента buttons. Делается запрос и проверяется наличие данных в ответе для 
рисунков и pdf, для этого проверяем kolon_tipi и kolon_değer. Если форма для отправки картинок или 
pdf, а также если kolon_değer != null, а также если будет доступен indexedDB, то вызывается функция, 
которая будет открывать indexedDB и запишет данные. Также данные запишутся и в хранилище, 
они удалятся при закрытии компонента preformtable.

2. В функции открывается indexedDB и в методе onupgradeneeded создается хранилище и указывается название 
поля с ключом. 
Если выбрать функцию, то хуки будут недоступны, если выбрать компонент, то он будет выполняться после всего 
кода, если оставить как есть, то это плохо повлияет на код, так как нужно отделить логику с использованием 
indexedDB от логики без нее. Возможно стоит вернуть ссылку на объект и записать ее в useRef в preformtable. 


3. В методе onsuccess в переменную записываем database. Можно уже провести транзакции для записи 
значений данной строки. 

4. Далее обновляем компонент formtable, чтобы получить переменную с database. 
5. Далее можно проводить транзакции по записи и чтению данных, но нужно учитывать, что 
транзакции асинхронны. 

Перед закрытием приложения нужно удалить database, возможно просто подключить к App файл с js кодом, 
где написать код по удалению database. Возможно для этого потребуется ее вновь открыть. 
Открыть, в onsuccess удалить database, а если таблица так и не была открыта, то ничего и не произойдет.



Следующая задача: заблокировать отправку данных на сервер пока в полях с обязательными данными 
не будут эти данные. Выделить цветом те поля, которые не заполнены, но их необходимо заполнить. 
*/

/*
Возможно ли сделать другую архитектуру таблиц?
Нужно чтобы не производились лишние расчеты при добавлении таблиц или их изменении. 
Как понять, что изменения не требуются?
Может сделать специальные функции для добавления и удаления таблиц, а также изменения строк?
Предположим, что можно сохранить в хранилище все компоненты в виде массива, где каждый элемент 
является компонентом. Тогда можно сверяться с tableStack и удалять из массива лишние компоненты. 
Однако как отделить ситуации, когда можно не обновлять компонент, а когда его нужно обновить? 
Может использовать специальные функции для нужных действий?

Для главной таблицы можно использовать useMemo, в качестве зависимостей указать id страницы, а также 
наличие данных в хранилище для этой таблицы, все равно при любом изменении двнные будут заменяться 
нулем. 
Напоминание: функция в useMemo не вызывается если в массиве зависимостей нет изменений.  

Как быть с дочерними таблицыми?
их нельзя сохранить в useMemo.
Можно ли их сохранить в хранилище при вызове специальных функций?
А как это сделать? В дочерних компонентах, в которых и происходят изменения, нет доступа к данным 
из родительского компонента.

Удивительно, но если сохранить результат вызова компонента в переменную, то потом при использовании 
этой переменной выводятся готовые данные без обращения к функциям, лежащим во всех вложенных компонентах.
Однако по какой то причине не работает вызов дочерних таблиц. 

Проблем возникает много, и в дальнейшем нужно будет их исправить, а функционал доработать.


Задача: сделать так, чтобы в дереве таблиц при изменении одной таблицы не проводились вычисления 
в других таблицах. 

Решение: допустим, что таблицы можно сохранять в хранилище в разных переменных. 
Тогда можно изменить только одну таблицу, а остальные выдать из переменных хранилища. 
Как отследить какую таблицу изменить нужно, а какую нет?
Может при срабратывании события обновления? Допустим.
Может при удалении таблицы из стека? Допустим.
Может при значении 0 в переменной хранилища? Ну, это вероятно будет основным механизмом 
срабатывания обновления таблицы. 

Как именно изменить таблицу? 
Может действительно в главном компоненте проверять нет ли значения 0 в нужном компоненте? если 
есть, то снова записываем в эту переменную компонент и переменную опять вызываем.

id для дочерних таблиц нужно брать из TableStack.

Нужно: 
1. сделать специальное хранилище
2. добавить в функции обновления приравнивание переменной с текущим id или вообще всех переменных
нулю.
3. добавить код в главный компонент.


Возникает проблема: не удается добавлять дочерние таблицы. Не удается открыть страницу для изменения 
таблиц?

Проблема: не срабатывает функция для обновления состояния главного компонента.
Правильнее сказать, она срабатывает только несколько раз, а потом уже никак не реагирует. 
Иногда данная фанкция срабатывает, иногда нет. Почему? 
Может проблема в имеющемся значении state? Может оно просто не получает новое значение, пытается 
изменить его на то состояние, которое уже есть в главном компоненте? В таком случае реакт не 
проводит изменения.
Нужно сделать новый стейт, и всегда устанавливать в него ссылочный тип.
Это сработало!

По какой то причине таблица обновляется при закрытии страницы для изменения таблиц. 
Что вообще может вызывать такое поведение?
Обновление не вызывается в главном компоненте.
Компонент обновляет setdatapreTablePage.

В дочерних компонентах пока все работает кроме перехода по таблицам на одном уровне. 
Как организован этот переход?

*/


/*
Задача: нужно чтобы на сайт не отправлялись данные, если не заполнены все поля. 
Подзадачи: 
1. Нужно запретить отправку, если есть незаполненные поля. 
2. Нужно как то обозначить, что не все поля были заполнены. 
3. Нужно как то обозначить конкретные поля, которые не были заполнены. 

Нужно запретить отправку, если есть незаполненные поля. Решение: 
Можно проверить строку перед отправкой, и если там null, то не отправлять. 
Однако это может получиться только с полями "TARIH_SAAT" или "TARIH", в остальных 
случаях будет 0. Также нужно учитывать, что такое значение может быть поставлено специально. 
Так как это все выполняется внутри функции, то можно в случае отсутствия значения в 
ChangeRepository.add[key][i].BOS_OLABILIRMI поставить return.
Это сработало! 

Нужно как то обозначить, что не все поля были заполнены. Как это сделать?
Может выделить красным цветом незаполненные поля? Проще всего будет дать 
класс со всеми нужными стилями всем таким компонентам вообще. 
Можно сделать стейт, который в начале будет равен 0, а потом принимать true или false.
Во время события blur можно перерисовать компонент и дать ему новый стиль. Покрасить 
границу в зеленый цвет если поле заполнено, и в красный цвет если пусто. 
Также можно вывести на 10 секунд маленькое окно, в которой указать причины проблемы. 
Идея с окном и таймером не очень хорошая из за проблем для производительности. 
Может просто указать рядом с полем красный восклицательный знак или надпись 
"поле должно быть заполнено"?
Лучше восклицательный знак. 
Лучше сделать просто восклицательный знак в круглом div. 

<symbol id="icon-warning" viewBox="0 0 32 32">
<path d="M16 2.899l13.409 26.726h-26.819l13.409-26.726zM16 0c-0.69 0-1.379 0.465-1.903 1.395l-13.659 27.222c-1.046 1.86-0.156 3.383 1.978 3.383h27.166c2.134 0 3.025-1.522 1.978-3.383h0l-13.659-27.222c-0.523-0.93-1.213-1.395-1.903-1.395v0z"></path>
<path d="M18 26c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path>
<path d="M16 22c-1.105 0-2-0.895-2-2v-6c0-1.105 0.895-2 2-2s2 0.895 2 2v6c0 1.105-0.895 2-2 2z"></path>
</symbol>

<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24">
  <path d="M18 2v6h-4l5 5 5-5h-4V2zm-8 18H2v-6h4l-5-5-5 5h4v6zm8-12v6h-4l5 5 5-5h-4V8z"/>
</svg>


Проблема в поле для даты: из за того, что календарь является отдельным элементом, клик на календарь 
вызывает событие onBlur для поля. В данный момент еще нет информации по дате, она появляется только 
после срабатывания onBlur. Примечательно, что после вызова календаря в первый раз, каждый последующий 
раз событие onBlur всплывает от календаря, и по сути событие срабатывает дважды. 

При выборе даты в календаре срабатывает функция для изменения стейта. Можно функционал привязать к этому 
изменению. Но как определить когда элемент был загружен первый раз, а когда повторный? 
Первый раз когда FormTableRepository.formInputsState[formTableId] === undefined. В последующие 
разы это свойство принимает значение 0. Однако в первый раз по какой то причине не происходит 
обновления компонента и при значении undefined может стоять значение в поле.  

Опять проблема в порядке выполнения кода. Код появляется в переменной позже, чем он запрашивается. 
Но почему? Это же асинхронный код. Потому что сначала onBlur срабатывает на компоненте, а потом уже 
на календаре. 
Как запросить данные когда они уже будут в переменной? 

1. срабатывает onBlur в компоненте из за клика по календарю.
2. происходит 
2. срабатывает Blur из за всплытия с календаря.

Надо попробовать написать код в синхронном коде. Получилось. 

Как вообще стоит это сделать? может окрасить все поля в красный или зеленый цвет по нажатию 
на кнопку Ekle? 
При нажатии на кнопку Ekle в случае неполных данных, можно перереедерить компонент и добавить 
во все дочерние компоненты специальный флаг. 
Потом можно будет вывести сообщение с просьбой заполнить все обязательные поля. 

Флаг добавил, но непонятно по каким критериям помечать незаполненые поля. Так в поле с датой 
почти всегда будет 0 вместо undefined.
*/

/*

Задача: в формах для изменения таблиц есть поля, которые должны влиять на другие поля.
Главное поле всегда является полем с заранее установлеными вариантами ответов. 
В таком главном поле может быть какое то значение, а в зависимых от него полях указано при каких 
значениях как себя вести. Обычно это указание такое: если в гланом поле значение ..., то в 
данном поле происходит действие ..., а если в главном поле значение не ..., то в данном поле 
происходит другое действие ...
Такой функционал у главного поля есть только во открытия форм с помощью кнопок "изменить" или "смотреть".

Решение: нужно установить state в компоненте preformtable, чтобы при изменении значения в гланом поле
повторно выполнять код и изменять зависимые компоненты. Также нужно добавить в записываемые данные 
те свойства, в которых есть информация о таком функционале. Также нужно дописать код, чтобы при наличии 
нужного состояния в главном поле, в зависимых полях происходили определенные в свойствах этих полей 
действия. Чтобы определить какая кнопка в компоненте button была нажата и нужно ли производить все эти 
действия, можно использовать свойство ID в хранилище ChangeRepository.add. Если это свойство равно "0", 
то нажата кнопка "добавить", и ничего делать не нужно. Если это свойство не равно "0", то нажата 
кнопка "изменить" или "смотреть", и нужно включать данный функционал.



Как реализовать эту схему? 
Сначала надо найти главное поле. В компоненте formtable ищем поле со значением типа ACILAN_KUTU, 
проверяем поле BAGLI_KOLON_MASTER. Если там 1, то это главное поле. 
Как всем остальным полям указать на данные из этой таблицы?
Нужно найти главное поле раньше.
Нужно во время перебора всех элементов в функции inputsWrapping узнать значение BAGLI_KOLON_MASTER, 
и если оно равно 1, то сохранить id поля. 
Нужно как то получить доступ к данному значению до перебора. 
Нужен какой то индекс. Причем этот индекс нужно сделать до того, как будет сделана запись в 
indexedDB. 
Нужно во время записи данных с сервера в хранилище ChangeRepository.add записать данные 
главного поля и зависимых полей. 
Нужно сделать пустой объект, а потом в него по данным главного объекта добавлять данные зависимых 
объектов. По каким данным впоследствии можно найти все эти элементы?
Это:
1. название группы
2. индекс массива

Желательно также установить элемент объекта count, чтобы проще было отслеживать 
наличие значений в объекте.

Объект сделать удалось, также удалось сделать подходящие ключи для зависимых полей, однако 
как найти главное поле?
нужно после цикла в fetch создать новый элемент с данными главного поля, скопировать туда 
элемент с id главного поля, а потом удалить ключ в виде id. 

Второй раз перебор элементов хранилища ChangeRepository.add происходит в функции inputsWrapping в 
компоненте preFormTable. Можно искать замену там при первом вызове. 
Стоит ли? Может просто пройти по ChangeRepository.add во второй раз, зато делать это только раз 
за всю сессию?
Почему нельзя найти нужный id за первое прохождение?
Потому что главное поле может быть найдено раньше, чем его id будет записан в качестве ключа в индекс.
может сделать еще один объект с ключами в виде нормальных id и значениями в виде id с сервера? 
потом просто подставляем нужный id и ничего удалять не придется. 

А как связаться с зависимыми элементами?

Быть может в случае изменения главного поля в стейт записываем его id с сервера и значение?
Тогда при перерисовке форм можно будет сделать сравнение и в случае совпадения некоторых значений, 
можно передать в компонент специальный параметр, или же не выводить компонент вовсе. 

Сделаем функцию, которая будет обрабатывать данные и вызывать компоненты formtable
1. проводится проверка на то, главное это поле, или же нет. Если поле главное, то
возвращаем компонент, а в компонент кладем функцию для перерисовки preformtable.
2. если компонент зависимый, то проводим рассчет какое состояние дожен принять данный компонент 
при текущем состоянии главного поля. Потом результат кладем в вызываемый компонент formtable.
3. если поле не главное и не зависимое, то возвращаем как обычно. 
- также надо добавить проверку на то, какой кнопкой было вызвано открытие форм для изменения таблицы. 


Сделаные инпуты с readOnly: 
1. строки и числа
2. выпадающий список
3. выбор true или false
4. картинки
5. цвета
6. адрес
7. дата
Протестированы: строки и числа, выпадающий список, выбор true или false. 

для очистки полей пришлось использовать неочевидный способ, так как из за оптимизаций React значение 
в input оставалось неизменным даже после перерисовки. Теперь когда нужно очистить input в компонент 
просто добавляется ключ, и input обновляется.  
Как данные из ChangeRepository.add попадают в formtablerepository?
*/
