
import { useMemo, useState, useRef} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import Bufer_AltForms_Table from './classBufer_AltForms_Table';

//import StoreRequest from '../formComponent/classStoreRequestForm';



function DinamikRaporAltTable ({id, indexOfBuferStore}) {
 

  let table; 
      
  /*
  let tableDataVariable = Object.keys(Bufer_AltForms_Table.store[refIndexOfBuferStore.current].FORM_DATA[0]).map(function (item) {
    return {
      accessorKey: item, //access nested data with dot notation
      header: item,
    }
  });

  
  const columns = useMemo(
    () => tableDataVariable,
    [tableDataVariable],
  );
  */
  
  //console.log('DinamikRapor_AltForms1 table_output columns', columns);

  let data = Bufer_AltForms_Table.store[indexOfBuferStore].FORM_DATA;
  const table1 = useMaterialReactTable({
    columns: Bufer_AltForms_Table.tableDataVariable[indexOfBuferStore],
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      onDoubleClick: (event) => {
        console.log('row', row);
        console.log('staticRowIndex', staticRowIndex);
        console.log('table', table);
        console.log('event', event.target.parentElement);

        /*
        if (refRow.current == 0) {
          event.target.parentElement.setAttribute('style', `background-color: #c8c7cc !important;`);
          refRow.current = event.target.parentElement;
        } else if (refRow.current != event.target.parentElement) {
          refRow.current.setAttribute('style', `background-color: #fff;`);
          event.target.parentElement.setAttribute('style', `background-color: #c8c7cc !important;`);
          refRow.current = event.target.parentElement;
        } else if (refRow.current == event.target.parentElement) {
          refRow.current.setAttribute('style', `background-color: #fff;`);
          refRow.current = 0;
        }
        */
        
        /*
        if (refRow.current == 0) {
          event.target.parentElement.classList?.add('highlightedLine');
          refRow.current = event.target.parentElement;
        } else if (refRow.current != event.target.parentElement) {
          refRow.current.classList?.remove('highlightedLine');
          event.target.parentElement.classList?.add('highlightedLine');
          refRow.current = event.target.parentElement;
        } else if (refRow.current == event.target.parentElement) {
          refRow.current.classList?.remove('highlightedLine');
          refRow.current = 0;
        }
        */

      },
    }),
    localization: MRT_Localization_TR,
    enableDensityToggle: false,
    paginateExpandedRows: false,
    initialState: { pagination : { pageSize : 100 }, density: 'comfortable' },
  });

  table = <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}`} >
    <MaterialReactTable table={table1} />
  </Box>;

  return table;
};

export default DinamikRaporAltTable;


