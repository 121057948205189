class StoreRequestSortComponent {
  static pageType = {
    
  };
}

export default StoreRequestSortComponent;

/*
- sortComponent
- menu1
- headerDropDownList
...
*/
