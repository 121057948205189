import { useRef, useState } from 'react';
import './Form.css';
import './inputRange2.css';
import { useSelector } from 'react-redux';
import Calendar from './calendar';
//import Calendar from '../../Calendar/calendarForForms';
import RangeInputComponent from './RangeInput';

//import StoreCalendar from './classStoreCalendar';

const nameOfClass = 'divWorkOrder';

const magnifyingGlass = <svg viewBox="0 0 32 32">
<path d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"></path>
</svg>;

function FormPage({data, id}) {

const token = useSelector((state) => state.tokenOfSession.token);
//let variableForArr = [];
//variableForArr.length = data.length + 2;

const [dataOfTemplate, setdataOfTemplate] = useState(new Array(data.length + 2).fill(''));
//const [dataOfTemplate, setdataOfTemplate] = useState(variableForArr.fill(''));
const [stateFormId, setstateFormId] = useState(0);
/*
stateFormId нужен для очистки dataOfTemplate при переходе между страницами
*/
if (Number(id) != stateFormId) {
  setstateFormId(id);
  setdataOfTemplate(['']);
  console.log('Number(id) != stateFormId');
}

//if (dataOfTemplate.length != data.length + 1) {
if (dataOfTemplate.length < data.length + 1) { 
  let arrVariable = [''];
  for (let i = 0; i < data.length; i++) {
    arrVariable.push('');
  }
  setdataOfTemplate(arrVariable);
}

const [dataOfinputRange, setdataOfinputRange] = useState(0);


const regexpArr = data.map( function (item, index) {
  if (item.VIEW_DATA === null) {
    return 0;
  } else {
    let str = (dataOfTemplate[index + 1] ?? '').toLowerCase();
    return new RegExp(`${str}`);
  }
});
console.log(regexpArr);



console.log('forms dataOfTemplate', dataOfTemplate);
function templateDataRecording (event, fild) {
  setdataOfTemplate([...dataOfTemplate.slice(0, fild), event.target.value, ...dataOfTemplate.slice(fild + 1)]);
}
function filterTemplateDataRecording (event, fild) {
  setdataOfTemplate([...dataOfTemplate.slice(0, fild),event.target.innerText, ...dataOfTemplate.slice(fild + 1)]);
}


const form = <div className={`${nameOfClass}`}>
  <div className={`topBlock_${nameOfClass}`}>
    <div className={`templateName_topBlock_${nameOfClass}`}>
      <p className={`${dataOfTemplate[0] == '' ? 'activeparagraph' : 'inactiveparagraph'}`}>ŞABLON İSMİ</p>
      <input placeholder='ŞABLON İSMİ' className={`${dataOfTemplate[0] == '' ? 'inactiveinput' : 'activeinput'}`} onChange={(event) => templateDataRecording (event, 0)}/>
    </div>
    
    <button className={`delete_topBlock_${nameOfClass}`} value={'SİL'}> <p>SİL</p> </button>
    <button className={`add_topBlock_${nameOfClass}`} value={'EKLE'}> <p>EKLE</p> </button>
  </div>
  <div className={`centralBlock_${nameOfClass}`} style={{height: '25vh'}} >

  {data.map(function (item, index) {
    if (item.KOLON_DEGER_TIPI == 'SAYISAL_COMBO') {
      return <div className={`block${index + 1}_centralBlock_${nameOfClass}`}>
      <p className={`${dataOfTemplate[index + 1] == '' ? 'activeparagraph' : 'inactiveparagraph'}`}>{item.GORUNEN_ADI}</p>
      <input placeholder={`${item.GORUNEN_ADI}`} className={`${dataOfTemplate[index + 1] == '' ? 'inactiveinput' : 'activeinput'}`} value={dataOfTemplate[index + 1]} onChange={(event) => templateDataRecording (event, index + 1)}/>
      <div className={`popUpWindow_${nameOfClass}`}>
        {(item.VIEW_DATA).filter(item => (regexpArr[index]).test((item.VIEW_GORUNEN_ADI).toLowerCase())).map(item => <div onMouseDown={(event) => filterTemplateDataRecording (event, index + 1) }>{item.VIEW_GORUNEN_ADI}</div>)}
      </div>
    </div>
    } else if (item.KOLON_DEGER_TIPI == 'TARIH_SAAT' || item.KOLON_DEGER_TIPI == 'TARIH') {
      
      return <div className={`block${index + 1}_centralBlock_${nameOfClass}`}>
      <p className={`${dataOfTemplate[index + 1] == '' ? 'activeparagraph' : 'inactiveparagraph'}`}>{item.GORUNEN_ADI}</p>
      < Calendar setdataOfTemplate={setdataOfTemplate} index={index} name={item.GORUNEN_ADI} type={item.KOLON_DEGER_TIPI} rangeData={false} dataOfTemplate={dataOfTemplate} selectedDateTime={false}/>
    </div>
        /*
          return <div className={`block${index + 1}_centralBlock_${nameOfClass}`}>
      <p className={`${dataOfTemplate[index + 1] == '' ? 'activeparagraph' : 'inactiveparagraph'}`}>{item.GORUNEN_ADI}</p>
      <Calendar state={0} setState={setdataOfTemplate} index={0} name={'Form'} repository={'Form'} placeholderData={false} defaultValue={0} propTimepicker={true} statusOpen={true} />
      
    </div>*/
    } else {
      
      return <div className={`block${index + 1}_centralBlock_${nameOfClass}`}>
      <p className={`${dataOfTemplate[index + 1] == '' ? 'activeparagraph' : 'inactiveparagraph'}`}>{item.GORUNEN_ADI}</p>
      <input placeholder={`${item.GORUNEN_ADI}`} className={`${dataOfTemplate[index + 1] == '' ? 'inactiveinput' : 'activeinput'}`} value={dataOfTemplate[index + 1]} onChange={(event) => templateDataRecording (event, index + 1)}/>
    </div>
    } 

  } )}
    

  </div>
  <div className={`bottomBlock_${nameOfClass}`}>
    <RangeInputComponent dataOfTemplate={dataOfTemplate} setdataOfTemplate={setdataOfTemplate}  index={data.length} />
    <div className={`buttonAra_bottomBlock_${nameOfClass}`}><button> {magnifyingGlass} ARA </button></div>
  </div>
</div>
  console.log('dataOfTemplate', dataOfTemplate);
  return (
    form

  );
}

export default FormPage;


//      < Calendar setdataOfTemplate={setdataOfTemplate} index={index} name={item.GORUNEN_ADI} />

/*
Нужно чтобы календарь обновлялся после введения даты в input, а также чтобы input обновлялся после 
установки даты в календаре.
Хранилище используется только в компоненте calendar чтобы компонент возвращал в state верные значения. 
Может тогда переделать все input, чтобы они получали данные из хранилища, а state сделать только 
для обновления? 
Нужно проверить и использовать event.target.value. Это значение нужно присвоить и календарю, и 
положить в state.


      < Calendar dataOfTemplate={dataOfTemplate[index]} setdataOfTemplate={setdataOfTemplate} index={index} name={item.GORUNEN_ADI} type={item.KOLON_DEGER_TIPI} rangeData={false} />

*/
