
import { useMemo, useState, useRef, useInsertionEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_TableBodyRow
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box, MenuItem, Typography } from '@mui/material';
import TableStack from './classTableStack';
import StoreRequest from './classStoreRequest';
import RerenderingTableFunction from './RerenderingTableFunction';



let eventTable = new CustomEvent("changedLineOfTable", {
  detail: { id: "0" }
});

function arrayСheck (arr, str) {
  for (let elem of arr) {
    if (elem[0] == str) {
      return false;
    }
  }
  return true;
}

function arrayIndexСheck (arr, str) {
  console.log('arr.length', arr.length);
  if (arr.length == 0) {
    return 0;
  }
  for (let i = 0; i < arr.length; i++) {
    if (str == arr[i][1]) {
      return i;
    }
  }
}

function arrayIndexCheck0 (arr, str, str1) {
  console.log('arr.length', arr.length);
  if (arr.length == 0) {
    return 0;
  } else if (arr[0][1] == str) {
    return 0;
  }
  for (let i = 0; i < arr.length; i++) {
    console.log(str1, arr[i]);
    console.log('arr.length', arr.length);
    if (str1 == arr[i][0]) {
      return i + 1;
    }
  }
}
/*
function setBackgroundColors (thisTable) {
  let lineArray = thisTable.current?.[0]?.getElementsByTagName('tr');
  console.log('table queueMicrotask thisTable', thisTable.current);
  console.log('table queueMicrotask lineArray', lineArray);
  for (let i = 1; i < lineArray?.length; i++) {
    console.log('table queueMicrotask lineArray[i].lastChild.textContent', lineArray[i].lastChild.textContent);
    lineArray[i].setAttribute('style', `background-color: ${lineArray[i].lastChild.textContent} !important;`)
  }
}
*/

function setBackgroundColors (thisTable, id) {
  console.log('table setBackgroundColors id', id, StoreRequest.colorArray[id]);

  let lineArray = thisTable.current?.[0]?.getElementsByTagName('tr');
  console.log('table setBackgroundColors thisTable', thisTable.current);
  console.log('table queueMicrotask lineArray', lineArray);
  for (let i = 1; i < lineArray?.length; i++) {
    console.log('table queueMicrotask lineArray[i].lastChild.textContent', StoreRequest.colorArray[id][lineArray[i].children[1].textContent], id);//StoreRequest.colorArray[id][lineArray[i].children[1].textContent]
    if (StoreRequest.colorArray[id][lineArray[i].children[1].textContent] !== '') {
      lineArray[i].setAttribute('style', `background-color: ${StoreRequest.colorArray[id][lineArray[i].children[1].textContent]} !important;`);
    }
    //lineArray[i].setAttribute('style', `background-color: ${lineArray[i].lastChild.textContent} !important;`)
  }
}

function componentTable ({data, columnsTable, altForm, id, page, setflagTable, setChangeStateOfPreTablePageWrapping}) {
//eventTable.detail.id = id;
/*
page содержит id не конкретной дочерней таблицы, а 
целой группы дочерних таблиц. Именно к этому id добавляется слово 
childId
*/
/*
const [pagination, setPagination] = useState({
  pageIndex: 0,
  pageSize: 15, //customize the default page size
});
console.log('table pagination', pagination);
*/
let firstPage = {pageIndex: 0, pageSize: 15,}
/*
useEffect(() => {
  if(TableStack.lastLocalUpdate )
}, []);
*/

if(TableStack.lastLocalUpdate != 0 && TableStack.lastLocalUpdate != undefined) {
  firstPage.pageIndex = TableStack.lastLocalUpdate.pageIndex;
  firstPage.pageSize = TableStack.lastLocalUpdate.pageSize;
}
if (page === 'PreTablePage' && 
   TableStack.lastCompletelyUpdate != 0 && 
   TableStack.lastCompletelyUpdate != undefined) {
  firstPage.pageIndex = TableStack.lastCompletelyUpdate.pageIndex;
  firstPage.pageSize = TableStack.lastCompletelyUpdate.pageSize;
}


let thisTableId = page === 'PreTablePage' ? id : page;
eventTable.detail.id = thisTableId;

let previousIndexRange = useRef([0, 0]);
let windowWithLineNumbersIndicators = useRef(0);
let thisTable = useRef(0);
let previousId = useRef(0);
let previousIdForTrackingIdChange = useRef(0);
if (previousIdForTrackingIdChange.current == 0) {
  previousIdForTrackingIdChange.current = thisTableId;
}



if (StoreRequest.colorArray[id] !== undefined) {

  console.log('table windowWithLineNumbersIndicators.current', windowWithLineNumbersIndicators.current);

if (windowWithLineNumbersIndicators.current == 0 || windowWithLineNumbersIndicators.current === undefined) {
  //thisTable.current = document.getElementsByClassName(`${thisTableId} MuiBox-root`);
  thisTable.current = document.getElementsByClassName(`${id} MuiBox-root`);

  console.log('table thisTableId', thisTableId);
  console.log('table thisTable', thisTable.current);

  windowWithLineNumbersIndicators.current = thisTable.current?.[0]?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`);
}
console.log('table windowWithLineNumbersIndicators.current', windowWithLineNumbersIndicators.current?.[0]?.textContent);
console.log('table changePage');

/*
setTimeout(() => {
  console.log('table timer windowWithLineNumbersIndicators.current', windowWithLineNumbersIndicators.current?.[0]?.textContent);
  console.log('table timer windowWithLineNumbersIndicators.current', windowWithLineNumbersIndicators.current?.[0]?.textContent.split(' ')[0].split('-'));
  console.log('table timer thisTable', thisTable.current);

  let lineRange = windowWithLineNumbersIndicators.current?.[0]?.textContent.split(' ')[0].split('-');
  if(previousIndexRange[0] == 0 || lineRange[0] != previousIndexRange[0]) {
    previousIndexRange[0] = lineRange[0];
    previousIndexRange[1] = lineRange[1];
    let lineArray = thisTable.current?.[0]?.getElementsByTagName('tr');
    console.log('table timer thisTable', thisTable.current);
    console.log('table timer lineArray', lineArray);
    for (let i = 1; i < lineArray?.length; i++) {
      console.log('table timer lineArray[i].lastChild.textContent', lineArray[i].lastChild.textContent);
      //lineArray[i].style.backgroundColor = lineArray[i].lastChild.textContent;
      lineArray[i].style.backgroundColor = 'red';
    }
  }
}, 50)
*/


queueMicrotask(() => {
  console.log('table queueMicrotask windowWithLineNumbersIndicators.current', windowWithLineNumbersIndicators.current?.[0]?.textContent);
  console.log('table queueMicrotask thisTable', thisTable.current);
  console.log('table queueMicrotask previousIndexRange[0]', previousIndexRange[0]);


  let lineRange = windowWithLineNumbersIndicators.current?.[0]?.textContent.split(' ')[0].split('-');


  if(previousIndexRange[0] == 0 || lineRange?.[0] != previousIndexRange[0] || previousId.current == 0) {

    /*
    let lineArray = thisTable.current?.[0]?.getElementsByTagName('tr');
    console.log('table queueMicrotask thisTable', thisTable.current);
    console.log('table queueMicrotask lineArray', lineArray);
    for (let i = 1; i < lineArray?.length; i++) {
      console.log('table queueMicrotask lineArray[i].lastChild.textContent', lineArray[i].lastChild.textContent);
      //lineArray[i].style.backgroundColor = lineArray[i].lastChild.textContent;
      //lineArray[i].style.backgroundColor = 'red !important';
      lineArray[i].setAttribute('style', `background-color: ${lineArray[i].lastChild.textContent} !important;`)
    }
    */
    setBackgroundColors (thisTable, id);
    if (previousId.current == 0) {
      console.log('table previousId was equal 0');
      previousId = id;
    } else {
      previousIndexRange[0] = lineRange[0];
      previousIndexRange[1] = lineRange[1];
    }
  }
})
}

console.log('table queueMicrotask previousId != id', previousId);
console.log('table queueMicrotask StoreRequest.colorArray[id] !== undefined', StoreRequest.colorArray[id] !== undefined);
console.log('table queueMicrotask StoreRequest.colorArray', StoreRequest.colorArray);

/*
if (previousId != id && StoreRequest.colorArray[id] !== undefined) {
  console.log('table queueMicrotask previousId != id && StoreRequest.colorArray[id] !== undefined');
  queueMicrotask(() => {
    setBackgroundColors (thisTable);
  })
}
*/

queueMicrotask(() => {

  console.log('table queueMicrotask lineRange', document.getElementsByClassName(`${thisTableId} MuiBox-root`)?.[0]?.getElementsByClassName(`MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root`)?.[0]?.innerText);
})

/*
Из за того, что нет одного унифицированного идентификатора для каждой таблицы,
создается путаница в данных. в id содержится уникальный номер, который
присылается с сервера. Этот номер у главной таблицы совпадает с номером в кэше.
У дочерней таблицы идентификационных номеров 2:
1. номер используемый в кеше хранится в переменной page. 
  У главной таблицы там хранится строка 'PreTablePage'.
2. номер присылаемый с сервера хранится в переменной id.
  У главной таблицы там хранится единственный идентификационный номер.
*/

let currentLineComponentTableRef = useRef(0);
if (typeof TableStack.lastLines[thisTableId] == 'string' && TableStack.lastLines[thisTableId].includes('_SelectedRow')) {
  console.log('table TableStack.lastLines[thisTableId]', TableStack.lastLines[thisTableId]);
  TableStack.lastLines[thisTableId] = TableStack.lastLines[thisTableId].slice(0, TableStack.lastLines[thisTableId].length - 12);
  currentLineComponentTableRef.current = TableStack.lastLines[thisTableId];
  console.log('table TableStack.lastLines[thisTableId]', TableStack.lastLines[thisTableId]);
} else {
  TableStack.lastLines[thisTableId] = 0;
}

//if (currentLineComponentTableRef.current != 0 && TableStack.lastLines[thisTableId] != currentLineComponentTableRef.current) {
if (currentLineComponentTableRef.current != 0 && TableStack.lastLines[thisTableId] != currentLineComponentTableRef.current) {  
  TableStack.lastLines[thisTableId] = currentLineComponentTableRef.current;
}

if (previousIdForTrackingIdChange.current != thisTableId) {
  currentLineComponentTableRef.current = 0;
  previousIdForTrackingIdChange.current = thisTableId;
}

document.dispatchEvent(eventTable);
console.log('dataTable', data);
console.log('columnsTable', columnsTable);



let index = arrayIndexCheck0 (TableStack.childListArr, id, page);
console.log('TableStack.childListArr', TableStack.childListArr);
console.log('index', index);

console.log('table firstPage', firstPage, typeof firstPage.pageIndex, typeof firstPage.pageSize, firstPage.pageIndex, firstPage.pageSize);

let tableDataVariable = columnsTable.map(function (item) {
  return {
    accessorKey: item, //access nested data with dot notation
    header: item,
    //size: 1.402/columnsTable.length,
  }
});

const columns = useMemo(
  () => tableDataVariable,
  [tableDataVariable],
);

/* здесь будет код для создания ошибки в react*/
//let data1 = {c:{b:{a:123}}};


  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    
    
    muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      onClick: (event) => {
        eventTable.detail.id = thisTableId;
        console.log(eventTable.detail.id);
        console.log("table row", row);


        event.target.classList.add(`${row.original.ID}`);
        console.log('muiTableBodyRowProps event.target', event.target);
        console.log('muiTableBodyRowProps', row.original);
        console.log('muiTableBodyRowProps', event.target.className);

        /*
        if (currentLineComponentTableRef.current != row.original.ID) {
          currentLineComponentTableRef.current = row.original.ID;
        }
        */

        if (currentLineComponentTableRef.current != row.original.ID) {
          console.log('table currentLineComponentTableRef.current', currentLineComponentTableRef.current, row.original.ID);
          if (TableStack.childListArr.length <= index) {
            currentLineComponentTableRef.current = row.original.ID;
          }
  
          if (TableStack.childListArr.length > index) { 
            TableStack.childListArr.length = index;
            currentLineComponentTableRef.current = row.original.ID;
            setChangeStateOfPreTablePageWrapping([0]);
          }
          /*
          этот код удаляет все нижние таблицы и позволяет сохранить выделение строки 
          даже при обновлении таблицы. Побочный эффект в том, что строка останется выделенной 
          даже если сменить alt таблицу.
          */
        }


        if(page === 'PreTablePage') {
          TableStack.lastLines[id] = row.original.ID;
          document.dispatchEvent(eventTable);
          console.log(row.original.ID);
          console.log(page);
        } else {
          TableStack.lastLines[page] = row.original.ID;
          document.dispatchEvent(eventTable);
          console.log(row.original.ID);
          console.log(page);
          
          /*
          В значении page содержится childId данной таблицы.
          childId ниже - это id следующей дочерней таблицы
          */
        
        }
        console.log('table id0', thisTableId);
        console.log('table onClick', event);
        
      },
      onDoubleClick: (event) => {
        console.info(event.target.innerText, String(row.original.ID));
        if (currentLineComponentTableRef.current != row.original.ID) {
          currentLineComponentTableRef.current = row.original.ID;
        }
        if (altForm) {
          let childId = String(id) + '_' + String(row.original.ID) + '_' + "child";
          console.log(arrayСheck (TableStack.childListArr, childId));
          console.log(TableStack.childListArr.length < (index + 1));

          if (arrayСheck (TableStack.childListArr, childId) && (TableStack.childListArr.length < (index + 1))) {
            TableStack.childListArr.push([childId, id, row.original.ID]);
            setChangeStateOfPreTablePageWrapping([0]);
          } else if (arrayСheck (TableStack.childListArr, childId) && (TableStack.childListArr.length >= (index + 1))) {
            TableStack.childListArr.length = index;
            TableStack.childListArr.push([childId, id, row.original.ID]);
            //addTable == 0 ? setaddTable(1) : setaddTable(0);
            setChangeStateOfPreTablePageWrapping([0]);
          }
        }
      },


      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      }
    }),
    enableRowPinning:true,
    rowPinningDisplayMode: 'sticky',
    //enableMultiRowSelection: false, //shows radio buttons instead of checkboxes
    //enableRowSelection: true,
    localization: MRT_Localization_TR,
    //initialState: { density: 'compact' },
    //initialState: { pagination: { pageIndex: 2 }, density: 'compact' }, //customize the default page size and page index
    
    initialState: { pagination: firstPage, density: 'compact' }, //customize the default page size and page index


    /*
    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    state: { pagination }, //pass the pagination state to the table
    */
    
    /*
    onPaginationChange: (event) => {
      console.log('onPaginationChange', event(1, 1));
    },*/
    
  });

  if (StoreRequest.colorArray[id] !== undefined) {
    console.log('table StoreRequest.colorArray[id]', StoreRequest.colorArray[id]);
  }

  TableStack.lastLocalUpdate = 0;
  TableStack.lastCompletelyUpdate = 0;

  return <>
  <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}`} >
    <MaterialReactTable table={table} />
  </Box>
  <RerenderingTableFunction id={thisTableId} />
  </>
};

export default componentTable;

/*
  <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}`} >
    <MaterialReactTable table={table} />
  </Box>
  <RerenderingTableFunction id={thisTableId} />
  </>
*/

/*
Задача: получить доступ к элементам таблицы.
Возможное решение: использовать queryselectorall.
Это желательно сделать в компоненте с небольшим количеством перерисовок,
потому что браузер будет искать элементы при каждом рендеринге.

Задача: нужно чтобы при нажатии строка меняла цвет, а при нажатии другой строки 
выделение снималось и выделялась другая строка.
Возможное решение: 
Конечно можно при нажатии добавлять и снимать класс, но вот как убрать цвет 
с других элементов, чтобы выделенным всегда оставался только один элемент?
Получается нужно просмотреть все остальные элементы и убрать класс у всех них,
кроме последнего.
Если бы можно было заставить css давать выделение только последнему выделенному элементу.

Можно сначала вести поиск по всему документу для нахождения таблицы.
Потом в этом элементе искать другие элементы, у которых есть класс равный номеру последней 
нажатой строки. Класс можно добавлять при первом нажатии на строку.
Потом при каждой перерисовке добавлять класс выделения для строки с классом равным номеру последней 
нажатой строки, а у других строк этот класс снимать.

Как строке присвоить нужный класс?
*/

//css-131dqfq-MuiTableRow-root
//.css-131dqfq-MuiTableRow-root.MuiTableRow-hover:hover{background-color:rgba(0, 0, 0, 0.04);}
//.css-131dqfq-MuiTableRow-root.Mui-selected{background-color:rgba(25, 118, 210, 0.08);}

/*
Нужно чтобы строки имели цвет который присылается с сервера.
Как это реализовать?
Искать строки можно по id.
Пройти по всем строкам в каждой таблице можно при монтировании компонента. Но тогда не будет 
изменений при обновлении компонента.
Проходить по всем строкам при каждой перерисовке компонента слишком затратно.
Чтобы присвоить стиль строке хватит и одного раза. Потом можно просто проверить и изменить. 
можно ли всем элементам присвоить стиль сразу? 
Было бы легко сделать стиль через атрибуты тегов. Но доступа к тегам таблицы нет. 

    сх : { 
      // разделяем строки, делаем нечетные строки более темным цветом
      '& td:nth-of-type(нечетный)' : { 
        Цвет фона : '#f5f5f5' , 
      } ,
    } ,

Можно сделать массив с объектами, а sx вынести в отдельный объект.
Потом просто добавить в него дополнительные свойства.

:nth-child(2): Выберет второй дочерний элемент.

Так можно сделать. Однако если в таблице сотни строк, а на экран выводтя 10?
Как узнать по какому индексу брать значение? 

в muiTableBodyRowProps в row содержится информация об индексе нажатой строки, независимо от страницы.

Но добраться до этого свойства можно не везде. 
Такая же проблема будет и при переборе строк.
как вообще можно узнать какая строка сейчас отображается? Все строки имеют одинаковые классы.

Дано:
массив с данными
псевдомассив со строками
Известно что два этих массива скорее всего не совпадают по количестыу элементов
и в псевдомассиве почти всегда будет меньше элементов

Вопрос: Как совместить данные из этих двух массивов также, как это будет 
происходить в DOM? 

Может взять отрезок, номера первого и последнего элемента псевдомассива?
Может сработать. Но как получить эти номера? 

const observer = new MutationObserver(callback);
Можно использовать наблюдателя. Однако этот наблюдатель должен наблюдать за всеми изменениями DOM, 
а не только за изменениями текста внутри строк или ячеек таблицы. 
В этом нет необходимости, этот компонент будет обновляться каждый раз при изменении страницы таблицы.

<span class="MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-1f2oslk-MuiTypography-root">1-10 of 13</span>

В тексте внутри этого элемента содержится диапазон номеров строк, которые отражаются в таблице. 

Нужно сделать хук useEffect, который будет находить нужный элемент при монтировании.
Потом при каждом обновлении страницы просматривать данные и в цикле добавлять данные в объект sx, 
который передается в качестве пропса. 

Возникла проблема, данные получаемые из элемента уже устаревшие, так как компонент таблицы выводится 
позже чем выполняется код. 
При этом попытка перенести код позже не поможет, так как положить объект со стилями в этот 
компонент нужно перед перерисовкой и выведением компонента. 
Как получить актуальные данные? 

Просто добавить какое то количество страниц для выравнивания результата не поможет. Страница 
может перелистываться вперед, назад, может оставаться на месте во время перерисовки. 

А почему так? Разве изменение страницы в таблице не асинхронное действие? По идее сначала 
надо нажать кнопку, потом выполнится код в компоненте, а потом страница изменится. 

Нужно как то перехватить данные с кнопки. 
Нет, похоже нужно искать другой способ передачи данных.
Однако и в таком случае передать стили нужно до того как вызовется очередное обновление компонента.
А может специально еще раз обновить компонент? Тогда должны быть получены актуальные данные. 
Только не слишком ли затратно выводить на экран таблицу 2 раза вместо одного? 
И как определить нужно ли это делать? 



Нужно просто взять данные из ячейки RENGI и применить цвет к каждой строке.
В какой момент это нужно сделать? 
Может при каждом обновлении таблицы?
Но в таком случае все равно будут устаревшие данные. 

Нужно учитывать что порядок строк может быть легко изменен. Строки могут быть "приколоты", строки могут 
быть отфильтрованы. 

Можно сделать компонент под таблицами. Этот компонент не будет ничего выводить, 
однако из за того, что он будет располагаться ниже компонента с таблицами, то он должен 
выполнять свой код позже, а значит уже должен иметь актуальные данные. 
Нет, последний вариант тоже не получился. Может использовать асинхронность? Или же таймер?
Таймер работает. Сейчас нужно добиться выполнения функции, а потом перейти на микротаск. 

<tr class="MuiTableRow-root css-131dqfq-MuiTableRow-root"><td class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-qaw64n-MuiTableCell-root"><button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-1knlbu3-MuiButtonBase-root-MuiIconButton-root" tabindex="0" type="button" aria-label="Sabitle" data-mui-internal-clone-element="true"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PushPinIcon" style="transform: rotate(135deg);"><path fill-rule="evenodd" d="M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3z"></path></svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button></td><td class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-p2ph38-MuiTableCell-root">665</td><td class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-y4w0s0-MuiTableCell-root">TURKCE</td><td class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-5jk9v8-MuiTableCell-root">boylam</td><td class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-16b6rqc-MuiTableCell-root">Boylam</td><td class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-amb89o-MuiTableCell-root">0</td><td class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-17107t0-MuiTableCell-root">Createch (ORTAK MODULLER)</td></tr>

Возможно стили не применяются из за того, что DOM не обновляется. 



Итак, удалось нормально установить стили для строк в таблице. 
Однако есть проблемы:
1. как это все заставить работать вместе с выделением активных строк? 
2. как быть с нормальными таблицами и с таблицами где нужно выделение цветом?

С помощью установки стилей примо в DOM удалось установить цвета для нажатых строк. Но теперь 
возникает другая проблема - не получается установить цвет обратно. 
Наивным решением выглядит перерисовка всех компонентов или же добавления state с массивом внутри.
Добавить простую переменную проблематично, так как это нужно сделать сразу для несколько методов, 
а также в переменной должны быть записаны данные из всех строк. Может использовать хранилище? 
А как узнать какой именно элемент нужен? 

По хорошему нужно точечно изменить стиль, потому что обновление всей таблицы слишком дорогая операция.
Можно в самом элементе найти последний элемент и установить стиль из его содержимого. 

Нужно сделать разделение на нормальный компонент и компонент с цветами. 
Для нормального компонента оставить все как было, а вот для цветного 
компонента использовать другую логику.

Все получилось, перестала вылетать ошибка при переходе между таблицами. Однако еще есть проблемы:
1. при переходе между вкладками стили css сохраняются от предыдущей таблицы. 
2. Проблемы с выделением строк в разных таблицах. 

Почему сохраняются стили? Но они же должны сохраняться до тех пор, пока не будут изменены. 
при перелистывании таблицы DOM меняется, и строки рассматриваются уже как другие объекты. 
Как перенести такое поведение на изменение таблиц? 


В цветную таблицу можно вернуть цвета просто обновив таблицу. Но как это сделать из другого компонента?
Может такой код написать в компоненте талиц? В случае неравенства id с предыдущим id еще раз обновить 
таблицы.
Но почему не срабатывает микротаск? Он же должен срабатывать при каждом обновлении компонента, 
а компонент должен обновиться чтобы отобразить новые данные. Не выполняется из за условия. 

возможно устанавливать цвет по номеру id строки, достаточно просто добавить этот id в массив или 
объект( в объекте можно использовать в качестве ключа). 
*/

/*
Возникает проблема при раскрашивании строк в дочерних таблицах. Из за того, что дочерних 
таблиц может быть сколько угодно на одном уровне, для записи в качестве ключа дополнительно 
использовался номер уровня таблицы. А теперь, чтобы обратиться к записям в хранилище для 
нужной таблицы, нужно найти номер таблицы. А его в компоненте table нет, и через пропс добавлять 
этот номер тоже нежелательно, так как этот компонент используется и для родительской таблицы. 

Может в родительскую таблицу добавить false, а в дочернюю добавить номер таблицы?
Не слишком ли радикальны изменения для такой мелкой детали?

Решил добавить numberTable в код. Однако есть проблема: по какой то причине в функцию 
setBackgroundColors всегда попадает только значение false, что соответствует родительскому компоненту. 
примечательно то, что снаружи функции в микротаске тоже всегда false, а вот в начале компонента проблем 
нет, значение правильное. 
Непосредственно перед микротаском значение также false.
Причина проблемы найдена. При проверке на наличие id компонента в коллекции colorArray всегда 
возвращается undefined. 
Возникает следующая проблема: id всей группы таблиц не совпадает с id отдельной таблицы из этой группы.
Это из за того, что в хранилище записывается целая группа таблиц, и именно ей присваивается id со словом 
childId на конце. Однако в компонент table этот id не передается, вместо него передается id отдельной 
таблицы. 

Теперь с id все совпадает, но не удается найти таблицу в dom дереве. 
Проблема решена, когда был использован id конкретной таблицы, а не thisTableId.

Проблема с выделением последней нажатой строки в дочерней цветной таблице.
Причина проблемы в том, что в компонент buttons отправляется не id конкретной таблицы, а 
id целой группы дочерних таблиц. Компонент даже не видит, что таблицы цветная, и обрабатывает ее 
как и обычную, то есть просто с присвоением класса.

*/
