import ChangeRepository from './classChangeRepository';

//let dataBase = 0;
/*
В ChangeRepository.dataBase хранится объект для взаимодействия с indexedDB, 
не трогать и не изменять эту переменную. 
*/

let IndexedDB = {
  openIndexedDB: function (fetchFunction) {
    console.error("IndexedDB openIndexedDB метод запускается");

    let request = indexedDB.open('my_database', 1);
    request.onupgradeneeded = function(event) {
      ChangeRepository.dataBase = event.target.result;
      
      let objectStore = ChangeRepository.dataBase.createObjectStore('people', { keyPath: 'id' });
      objectStore.createIndex('id', 'id', { unique: false });
    };
    request.onsuccess = function(event) {
      ChangeRepository.dataBase = event.target.result;
      console.log('IndexedDB openIndexedDB', ChangeRepository.dataBase);
      fetchFunction();
    } 
    request.onerror = function(event) {
      console.error("IndexedDB openIndexedDB", event.target.error);
    };
    
  },
  putValue: function (tableId, lineId, id_for_indexedDB) {
    //let id_for_indexedDB = `${lineId}_${tableId}`
    let transaction = ChangeRepository.dataBase.transaction(['people'], 'readwrite');
    transaction.objectStore('people').put({id: id_for_indexedDB, table: ChangeRepository.add})
    .onsuccess = function (event) {
      if (ChangeRepository.checkingDatabaseValue[tableId] == undefined) {
        ChangeRepository.checkingDatabaseValue[tableId] = {};
      }
      ChangeRepository.checkingDatabaseValue[tableId][id_for_indexedDB] = true;


      console.log('IndexedDB putValue выполнилась запись');
      

    };
  },
  getValue: function (tableId, lineId, justWatch, id_for_indexedDB, setChangeStateOfPreTablePageWrapping) {
    console.log('IndexedDB getValue ChangeRepository.dataBase', ChangeRepository.dataBase);
    //let id_for_indexedDB = `${lineId}_${tableId}`

    let transaction = ChangeRepository.dataBase.transaction(['people'], 'readonly');

    transaction.objectStore('people')
    .get(id_for_indexedDB)
    .onsuccess = function (event) {
      console.log('IndexedDB getValue event.target.result', event.target.result);

      ChangeRepository.add = event.target.result?.table;
      console.log('IndexedDB getValue justWatch', justWatch);
      console.log('IndexedDB getValue ChangeRepository.add', ChangeRepository.add);


      ChangeRepository.add.justWatch = justWatch;
      console.log('IndexedDB getValue ChangeRepository.add', ChangeRepository.add);
      setChangeStateOfPreTablePageWrapping([0]);
    }

    transaction.objectStore('people')
    .get(tableId)
    .onerror = function (event) {
      console.log('IndexedDB getValue onerror');
    }
    
  },
  deleteValue: function () {
  },
  deleteIndexedDB: function () {
    
    ChangeRepository.dataBase.close();
    ChangeRepository.dataBase = 0;
    ChangeRepository.checkingDatabaseValue = {};
    let deleteRequest = indexedDB.deleteDatabase("my_database");
    
    deleteRequest.onsuccess = function() {
      console.log("IndexedDB deleteIndexedDB База данных удалена успешно");
      ChangeRepository.dataBase = 0;
      ChangeRepository.checkingDatabaseValue = {};
    };

    deleteRequest.onerror = function(event) {
      console.error("IndexedDB deleteIndexedDB Ошибка при удалении базы данных:", event.target.error);
    };
    console.log('IndexedDB deleteIndexedDB deleteRequest', deleteRequest);
    
    /*
    let closePromise = new Promise(function(resolve, reject) {
      ChangeRepository.dataBase.close();
      db.onclose = resolve;
      db.onerror = reject;
    }).then(function() {
      // Удаляем базу данных
      let deleteRequest = indexedDB.deleteDatabase('myDatabase');

      deleteRequest.onsuccess = function() {
        console.log("IndexedDB deleteIndexedDB База данных удалена успешно");
        ChangeRepository.dataBase = 0;
        ChangeRepository.checkingDatabaseValue = {};
      };

      deleteRequest.onerror = function() {
          console.error("Ошибка при удалении базы данных");
      };
    });
    */

  }
}

 
export default IndexedDB;

/*
Описание ошибки:
IndexedDB.js:50 
Uncaught DOMException: Failed to execute 'transaction' on 'IDBDatabase': 
The database connection is closing.

Место ошибки:
Object.getValue

Ошибка возникла после изменения в коде и повторной перерисовки с новым кодом.
Возникает вопрос, почему вызывается метод getValue?

*/

/*
Проблема с открытием indexedDB. Даже при использовании цепочки промисов открытие database не удается 
хоть как нибудь проконтролировать. Ложить callback в onsuccess не сильно здесь поможет, потому что 
срабатывать будет только один раз. Можно обновить компонент и тогда база даных уже откроется, 
но как тогда запустить функцию? Может положить функцию в callback? Тогда нужно выделить fetch в отдельную 
функцию и положить ее в onsuccess. 


Проблема с записью в dataBase. Повторная запись просто добавит новую коллекцию, или не добавит в 
зависимости от настроек. Однако нужно чтобы под одним id хранился один объект с данными обо всех 
строках, а для этого нужно последовательно добавлять данные при запросах для каждой строки таблицы.

Как это реализовать? Может прочитывать запись, записывать в хранилище, удалять запись из database 
и записывать обновленную запись? 
Это может сработать, но для этого нужно проделать очень, очень много работы.
Нужно учитывать, что транзакции нельзя смешивать, то есть нужно вызывать по очереди несколько 
транзакций. 
Нужно не забыть очищать ChangeRepository.dataBase

А если данных слишком много? Тогда запись в хранилище всех данных не лучшая идея. 

Может тогда сделать несколько хранилищ в dataBase? 
Это сделать нельзя, так как создать хранилище можно только при открытии dataBase. 
Это можно сделать если использовать onupgradeneeded и изменить версию dataBase, 
однако и в таком случае нужно открывать все dataBase.

Гораздо проще хранить в виде ключа id строки и id таблицы. Проблема в том, что похожее id есть и 
у дочерних таблиц. В таком случае можно просто добавлять к id слово indexedDB_ID. 

А как удалить все данные у одной конкретной таблицы, например при обновлении? 
Можно сделать флаг, при котором данные будут удаляться и записываться заново, однако как отследить, 
что перезаписаны были все данные и поставить флаг в первоначальное положение? 

openKeyCursor() позволяет перебирать индексы без прочтения их значений. Можно перебрать все ключи 
и удалить ненужные записи. 
Однако как узнать о том, что запись была удалена и нужно опять делать запрос на сервер и опять 
записывать данные в database? Может делать аналогичную запись в хранилище ChangeRepository? 
Сделать объект, в нем другие объекты с id таблицы, в каждом таком объекте ключи с id строк, а в строки 
записать true если значение есть в indexedDB, и false если значения нет. 
Тогда можно просто записывать если значение в объекте равно undefined или false. А для стирания 
значений из таблицы достаточно просто взять объект с id таблицы и в цикле присвоить всем значениям 
false. Нужно использовать не метод add, а метод put.
Но как сопоставить ключи из объекта и ключ из indexedDB? 
Для каждого вызова использовать метод split?
Размер id строки и размер id таблицы заранее неизвестен. 
Допустим id страницы будет известен в самом компоненте, но как быть с id строк? 
Может записывать не номера строк, а сразу их id, то же самое что и в indexedDB? А id таблицы 
брать из компонента. 


result
: 
[Exception: DOMException: Failed to read the 'result' property from 'IDBRequest': The request has not finished. at IDBOpenDBRequest.invokeGetter (<anonymous>:3:28)]
source
: 
null
*/